import React from 'react';
import { translate } from '@/utils';

const CheckoutReviewBeforeAfter = () => {
  const appLang = localStorage.getItem('lang');
  const commentsList = {
    default: ['review1.png', 'review2.jpg', 'review3.jpg'],
    ko: ['review1ko.jpg', 'review2ko.jpg', 'review3ko.jpg'],
    ru: ['review1ru.jpg', 'review2ru.jpg', 'review3rus.jpg'],
    es: ['review1.png', 'review2es.jpg', 'review3es.jpg'],
    la: ['review1.png', 'review2es.jpg', 'review3es.jpg'],
    it: ['review1.png', 'review2it.jpg', 'review3es.jpg'],
    ro: ['review1ro.png', 'review2it.jpg', 'review3es.jpg'],
    cs: ['review1cs.png', 'review2.jpg', 'review3cs.png'],
    fr: ['review1fr.png', 'review2fr.jpg', 'review3fr.jpg'],
    ja: ['review1ja.png', 'review2ja.jpg', 'review3ja.jpg'],
    bg: ['review1.png', 'review2bg.png', 'review3bg.png'],
    pb: ['review2es.jpg', 'review2pb.jpg', 'review1ro.png'],
    de: ['review1.png', 'review2.jpg', 'review3.jpg'],
    gr: ['review1gr1.jpg', 'review2gr1.png', 'review3gr1.png'],
    hr: ['review1.png', 'review2.jpg', 'review3.jpg'],
    hu: ['review1.png', 'review2.jpg', 'review3.jpg'],
    pt: ['review2es.jpg', 'review2pt.jpg', 'review1ro.png'],
    vi: ['review1vi.jpg', 'review2vi.jpg', 'review3vi.jpg'],
    id: ['review3id.jpg', 'review2id.jpg', 'review1id.jpg'],
    ar: ['review1ar.png', 'review2ar.jpg', 'review3ar.jpg'],
  };
  const languageCurrentComments = commentsList[appLang]?.length
    ? commentsList[appLang]
    : commentsList.default;

  return (
    <div className="checkout-before-after">
      {languageCurrentComments.map((item, i) => {
        return (
          <div key={i} className="checkout-before-after__item">
            <div className="checkout-before-after__name">
              {translate(`CHECKOUT_COMMENT${i + 1}_NAME`)}
            </div>
            <div className="checkout-before-after__progress">
              {translate(`CHECKOUT_COMMENT${i + 1}_PROGRESS`)}
            </div>
            <div className="checkout-before-after__inner">
              <div className="checkout-before-after__day checkout-before-after__day_left">
                {translate('DAY_COUNT').replace('{{count}}', '1')}
              </div>
              <img src={`/images/reviews/${item}`} alt="" />
              <div className="checkout-before-after__day">
                {translate('DAY_COUNT').replace('{{count}}', '80')}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CheckoutReviewBeforeAfter;
