export const priceAdapter = (currency, price, currencyCode) => {
  const countryOrderOfPrices = {
    default: '{{price}}{{currency}}',
    USD: '{{currency}}{{price}}',
    COP: '{{price}}&nbsp;{{currency}}',
    BRL: '{{currency}}{{price}}',
  };
  const currentCountryOrder = countryOrderOfPrices[currencyCode] || countryOrderOfPrices.default;

  if (currency && price) {
    return currentCountryOrder.replace('{{currency}}', currency).replace('{{price}}', price);
  }
};
