export default {
  homeLang: '/:lang',
  verify: '/:lang/verify',
  outerVerify: '/:lang/outer-verify',
  privacy: '/:lang/privacy',
  terms: '/:lang/terms',
  contacts: '/:lang/contacts',
  hipaaNotice: '/:lang/hipaa-notice',
  ccpa: '/:lang/ccpa',
  researchPolicy: '/:lang/research-policy',
  cof: '/:lang/cof',
  quiz: '/:lang/quiz',
  quizId: '/:lang/quiz/:id',
  landingQuizId: '/:lang/landing_quiz/:id',
  courses: '/:lang/courses',
  profile: '/:lang/profile',
  blog: '/:lang/blog',
  blogDetails: '/:lang/blog/details',
  blogDetailsNewsId: '/:lang/blog/details/:newsId',
  checkout: '/:lang/checkout',
  fakeReg: '/:lang/fake-reg',
  cancelSubscription: '/:lang/cancel-subscription',
  cancelSuccess: '/:lang/cancel-success',
  refund: '/:lang/refund',
  notFound: '/404',
};
