import { api } from '../utils';

export const getUser = async () => {
  try {
    return await api('/users', {}, 'GET');
  } catch (e) {
    throw e;
  }
};

export const updateUser = async (password, data, silent) => {
  try {
    return await api(`/users${password ? '/password' : ''}`, data, 'POST', silent);
  } catch (e) {
    throw e;
  }
};

export const updatePassword = (old_password, new_password, id) =>
  api('/users/password', { old_password, new_password, id }, 'POST');

export const updatePersonalData = (user) => api('/users', user, 'PATCH');

export const getPaymentMethods = () => api('/users/payment_methods', {}, 'GET');

export const createNewPaymentCheckout = (success_url, cancel_url) =>
  api('/checkouts/payment_method', { success_url, cancel_url }, 'POST');

export const deletePaymentMethod = (id) => api(`/users/payment_methods/${id}`, {}, 'DELETE');

export const getSubscriptions = () => api('/subscriptions', {}, 'GET');

export const deleteSubscription = (token, lang, id) =>
  api(`/subscriptions/${id}/cancel`, { token, lang, id }, 'POST');

export const deleteSubscriptionByEmil = (cancelKey, email) =>
  api(`/subscriptions/send-cancel-url`, { cancel_key: cancelKey, email }, 'POST');

export const deleteSubscriptionByToken = (cancelToken) =>
  api(`/subscriptions/cancel-by-token`, { cancel_token: cancelToken }, 'POST');

export const getUserCountry = async () =>
  api('/countries/determine-by-ip', {}, 'GET').catch(() => {
    return false;
  });
