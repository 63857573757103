import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useStores } from '../../../store';
import { observer } from 'mobx-react';
import { translate } from '@utils';

const LinearWithValueLabel = observer(({ classes }) => {
  const { changeQuizData } = useStores().quizStore;
  const { currentTariff } = useStores().paymentStore;
  const { ln } = useStores().appStore;
  const [progress1, setProgress1] = useState(0);
  const [progress2, setProgress2] = useState(0);
  const [progress3, setProgress3] = useState(0);
  const [progress4, setProgress4] = useState(0);

  const setProgress = useCallback(
    (prevProgress) => Math.min(prevProgress + Math.random() * 20, 100),
    [],
  );

  useEffect(() => {
    const timer = setInterval(() => {
      progress1 !== 100 && setProgress1(setProgress);
      progress1 === 100 && progress2 !== 100 && setProgress2(setProgress);
      progress2 === 100 && progress3 !== 100 && setProgress3(setProgress);
      progress3 === 100 && progress4 !== 100 && setProgress4(setProgress);

      if (progress4 === 100) {
        clearInterval(timer);
        setTimeout(() => {
          changeQuizData('pageName', currentTariff.changeTariffs?.length ? 'chart' : 'payment');
        }, 300);
      }
    }, 350);

    return () => {
      clearInterval(timer);
    };
  }, [progress1, progress2, progress3, progress4]);

  const renderPercent = useCallback(
    (value) =>
      Math.round(value) === 100
        ? translate('COMPLETED')
        : `${ln === 'ar' ? `%${Math.round(value)}` : `${Math.round(value)}%`}`,
    [],
  );

  const activeName = useCallback(
    (value) => `${value > 0 && value < 100 ? 'lp_name__active' : ''}`,
    [],
  );

  const activePercent = useCallback(
    (value) => `${value > 0 && value < 100 ? 'lp_percent__active' : ''}`,
    [],
  );

  return (
    <div className="lp_container">
      <div>
        <h1 className="lp_title">{translate('MATCHING_BEHAVIOR_TRENDS')}</h1>
        <p className="lp_desc">{translate('MAKING_PERFECT_PLAN')}</p>

        <div className="lp_box">
          <div className="lp_item">
            <LinearProgress
              className="lp_progress"
              classes={{
                colorPrimary: classes.colorPrimaryAll,
                barColorPrimary: classes.barColorPrimary1,
              }}
              variant="determinate"
              value={progress1}
            />
          </div>
          <div className="lp_info__item">
            <span className={`lp_name ${progress1 < 100 ? 'lp_name__active' : ''}`}>
              {translate('GENERAL_YOU')}
            </span>
            <span className={`lp_percent ${progress1 < 100 ? 'lp_percent__active' : ''}`}>
              {renderPercent(progress1)}
            </span>
          </div>
        </div>

        <div className="lp_box">
          <div className="lp_item">
            <LinearProgress
              className="lp_progress"
              classes={{
                colorPrimary: classes.colorPrimaryAll,
                barColorPrimary: classes.barColorPrimary2,
              }}
              variant="determinate"
              value={progress2}
            />
          </div>
          <div className="lp_info__item">
            <span className={`lp_name ${activeName(progress2)}`}>{translate('ENVIRONMENT')}</span>
            <span className={`lp_percent ${activePercent(progress2)}`}>
              {renderPercent(progress2)}
            </span>
          </div>
        </div>

        <div className="lp_box">
          <div className="lp_item">
            <LinearProgress
              className="lp_progress"
              classes={{
                colorPrimary: classes.colorPrimaryAll,
                barColorPrimary: classes.barColorPrimary3,
              }}
              variant="determinate"
              value={progress3}
            />
          </div>
          <div className="lp_info__item">
            <span className={`lp_name ${activeName(progress3)}`}>
              {translate('FOOD_RELATIONSHIP')}
            </span>
            <span className={`lp_percent ${activePercent(progress3)}`}>
              {renderPercent(progress3)}
            </span>
          </div>
        </div>

        <div className="lp_box">
          <div className="lp_item">
            <LinearProgress
              className="lp_progress"
              classes={{
                colorPrimary: classes.colorPrimaryAll,
                barColorPrimary: classes.barColorPrimary4,
              }}
              variant="determinate"
              value={progress4}
            />
          </div>
          <div className="lp_info__item">
            <span className={`lp_name ${activeName(progress4)}`}>
              {translate('YOUR_BACKGROUND')}
            </span>
            <span className={`lp_percent ${activePercent(progress4)}`}>
              {renderPercent(progress4)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

const styles = () => ({
  colorPrimaryAll: {
    transform: useStores().appStore.ln === 'ar' ? 'scaleX(-1)' : 'initial',
    backgroundColor: '#ffffff',
  },
  barColorPrimary1: {
    backgroundColor: '#F8C476',
  },
  barColorPrimary2: {
    backgroundColor: '#F4955F',
  },
  barColorPrimary3: {
    backgroundColor: '#F34E4E',
  },
  barColorPrimary4: {
    backgroundColor: '#bf0d0d',
  },
});

export default withStyles(styles)(LinearWithValueLabel);
