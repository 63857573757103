import React, { useMemo, Fragment, useEffect, useState } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import Layout from '../Layout';
import { LANDING_QUIZ_JSON, LANDING_QUIZ_DURATION_JSON } from '../LandingQuizPage/LandingQuizJSON';
import LinearWithValueLabel from './LinearWithValueLabel';
import { Checkout } from '@components/Checkout';
import { useStores } from '@/store';
import { observer } from 'mobx-react';
import Spinner from '@components/Spinner';
import { getIosVersion, getPlatform, translate } from '@/utils';
import { SurveyComponent } from './Survey';
import '../../assets/styles/quiz.less';
import { gaMirror, ymMirror } from '@utils/metrics';
import colorsVars from '@utils/colorsVars';
import angleDown from '@icons/angle_down.svg';
import RenderSvg from '@components/RenderSvg';
import isWebview from 'is-ua-webview';
import WebviewToSafari from '@components/QuizPage/WebviewToSafari';
import SelectGift from '@components/QuizPage/selectGift';
import { Helmet } from 'react-helmet';

import PlanSection from '@components/Checkout/PlanSection';
import SelectPlan from '@components/Checkout/SelectPlan';

const Questions = observer(() => {
  const quizStore = useStores().quizStore;

  const [showedAnimation, setShowedAnimation] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(quizStore.state.survey.currentPageNo !== 0);
  const [showProceed, setShowProceed] = useState(false);

  const history = useHistory();
  const paramId = useParams().id || null;
  const appStore = useStores().appStore;

  useEffect(() => {
    if (paramId && !quizStore.state.showSurvey) {
      quizStore.changeQuizData('loader', true);
      quizStore.getQuizAnswers(paramId, history).then((item) => {
        if (item && item.survey) {
          history.push({ pathname: '/quiz', search: window.location.search });
        }
        quizStore.changeQuizData('loader', false);
      });
    }
  }, []);

  useEffect(() => {
    setShowProceed(quizStore.state.survey.currentPageNo + 1 === quizStore.currentQuizPages.length);
  }, [quizStore.state.survey.currentPageNo, quizStore.currentQuizPages.length]);

  const sendEmailLead = (questionNumber) => {
    const quizValidateErrors = document.querySelectorAll('.has-error');
    if (quizStore.currentQuizPages[questionNumber]?.sendEmail && !quizValidateErrors.length) {
      ymMirror('emailLead');
      gaMirror({
        event: 'emailLead',
        category: quizStore.state.quizId,
        action: 'sent',
        label: 'emailLead',
      });
    }
  };

  const sendQuizNext = () => {
    const survey = quizStore.state.survey;
    const currentQuestionNumber = survey.currentPageNo;
    const quizValidateErrors = document.querySelectorAll('.has-error');
    const additionalFieldsList = {
      page30: survey.data.question1,
      page2: survey.data.gender,
      page1: survey.data.age,
    };
    const questionName = quizStore.currentQuizPages[currentQuestionNumber - 1]?.name;
    const additionalField = additionalFieldsList[questionName];

    sendEmailLead(currentQuestionNumber - 1);
    if (!quizValidateErrors.length) {
      gaMirror({
        event: `question_${currentQuestionNumber}`,
        category: quizStore.state.quizId,
        action: 'click',
        label: `question_${currentQuestionNumber}`,
        ...(additionalField ? { button_text: additionalField } : {}),
      });
    }
  };

  const nextButtonHandleClick = () => {
    window.survey.nextPage();
    setTimeout(() => {
      sendQuizNext();
    }, 0);
  };

  const proceedButtonHandleClick = () => {
    const currentQuestionNumber = quizStore.state.survey.currentPageNo;

    window.survey.completeLastPage();
    setTimeout(() => {
      const quizValidateErrors = document.querySelectorAll('.has-error');
      if (!quizValidateErrors.length) {
        gaMirror({
          event: `question_${currentQuestionNumber + 1}`,
          category: quizStore.state.quizId,
          action: 'click',
          label: `question_${currentQuestionNumber + 1}_quiz_done`,
        });
      }
      sendEmailLead(currentQuestionNumber);
    }, 0);
  };

  const inputTypeNumbers = document.querySelectorAll('input[type=number]');

  for (let i = 0; i < inputTypeNumbers.length; i++) {
    inputTypeNumbers[i].onwheel = (event) => {
      event.target.blur();
    };
  }

  const NextPage = useMemo(() => {
    const landingQuizKeys = LANDING_QUIZ_JSON.map((quiz) => Object.keys(quiz)[0]);
    const landingQuizDurationKeys = LANDING_QUIZ_DURATION_JSON.map((quiz) => Object.keys(quiz)[0]);

    switch (quizStore.state.pageName) {
      case 'payment': {
        if (
          landingQuizKeys.includes(quizStore.state.quizId) ||
          (landingQuizDurationKeys.includes(quizStore.state.quizId) &&
            quizStore.state.paymentVersion === 'version1')
        ) {
          setShowedAnimation(true);
        }
        return Checkout;
      }
      case 'linear': {
        return LinearWithValueLabel;
      }
      case 'chart': {
        return PlanSection;
      }
      case 'select-plan': {
        return SelectPlan;
      }

      default: {
        return showedAnimation ? Checkout : SelectGift;
      }
    }
  }, [quizStore.state]);

  const iosVersion = getIosVersion();

  return (
    <Layout
      showBurger={false}
      showSignButtons={false}
      footer={false}
      user_is_authorized={false}
      layoutClassName="quiz-page"
    >
      <Helmet>
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      {!(
        ((getPlatform() === 'apple' && iosVersion?.length && Number(iosVersion[0]) < 15) ||
          getPlatform() === 'Android') &&
        isWebview(window.navigator.userAgent)
      ) ? (
        <div className="quiz-page__container">
          {quizStore.state.loader ? (
            <Spinner className="home-login__spinner" color="#f4955f" />
          ) : (
            <Fragment>
              {quizStore.state.showSurvey ? (
                <div className="quiz-page__test-container">
                  <SurveyComponent key={appStore.ln} quizStore={quizStore} appStore={appStore} />

                  {quizStore.state?.survey?.currentPageNo === 0 && (
                    <div className="payment-form__agree">
                      <div
                        onClick={() => {
                          setTermsAccepted(!termsAccepted);
                        }}
                        className="payment-form__checkbox"
                      >
                        {termsAccepted && (
                          <RenderSvg color={colorsVars.colorLight} path={angleDown} size={14} />
                        )}
                      </div>
                      <div className="payment-form__agree-text">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: translate('POLICY_AGREE_TEXT')
                              .replace(
                                '{{privacy}}',
                                `<a href="/terms" target="_blank" className="py_privacy_link">
                              ${translate('TERMS')}</a>`,
                              )
                              .replace(
                                '{{terms}}',
                                `<a href="/privacy" target="_blank" className="py_privacy_link">
                              ${translate('PRIVACY')}</a>`,
                              ),
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="survey-footer__navigation">
                    {quizStore.state.survey.currentPageNo === 0 && <div id="surveyEmptyDiv" />}
                    <button
                      id="surveyPrevButton"
                      className={`${
                        quizStore.state.survey.currentPageNo === 0 ? 'display__none' : ''
                      } grey-button`}
                      onClick={() => window.survey.prevPage()}
                    >
                      {translate('PREV')}
                    </button>
                    <button
                      id="surveyNextButton"
                      disabled={!termsAccepted && quizStore.state.survey.currentPageNo === 0}
                      className={`${
                        showProceed ? 'display__none' : ''
                      } orange-button button-with-arrow`}
                      onClick={nextButtonHandleClick}
                    >
                      {translate('NEXT')}
                    </button>
                    <button
                      id="surveyProceedButton"
                      className={`${
                        !showProceed ? 'display__none' : ''
                      } orange-button button-with-arrow`}
                      onClick={proceedButtonHandleClick}
                    >
                      {translate('PROCEED')}
                    </button>
                  </div>
                </div>
              ) : (
                <NextPage />
              )}
            </Fragment>
          )}
        </div>
      ) : (
        <WebviewToSafari />
      )}
    </Layout>
  );
});

export const QuestionsPage = withRouter(Questions);
