import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import RenderSvg from '../RenderSvg';
import colorsVars from '../../utils/colorsVars';

import dropIcon from '../../assets/icons/drop_icon.svg';

@inject('appStore', 'commonStore')
@observer
class LangSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLanguages: false,
    };
  }

  toggleLanguagesList() {
    this.setState({ showLanguages: !this.state.showLanguages });
    this.props.commonStore.setStopHomePageWheel(!this.state.showLanguages);
  }

  async changeLanguages(lang) {
    this.setState({ showLanguages: false });
    if (lang === this.props.appStore.ln) {
      return;
    }
    const replaceUrlLang = this.props.history.location.pathname.replace(/[^/]*\/([^/]*)/, lang);
    this.props.commonStore.setStopHomePageWheel(false);
    this.props.history.push(`/${replaceUrlLang}`);
    await this.props.appStore.setLang(lang);
  }

  render() {
    const { appStore } = this.props;
    const languagesArr = [];
    // eslint-disable-next-line guard-for-in,no-restricted-syntax,no-unused-vars
    for (const key in appStore.languages) {
      languagesArr.push({ ...appStore.languages[key], code: key });
    }
    return (
      <>
        <div className="lang-switcher">
          <div
            onClick={() => {
              this.toggleLanguagesList();
            }}
            className="lang-switcher__container"
          >
            <span>{appStore.ln}</span>{' '}
            <RenderSvg path={dropIcon} size={8} color={colorsVars.colorAccent} />
          </div>
          {languagesArr?.length > 0 && (
            <>
              <div
                onClick={() => {
                  this.toggleLanguagesList();
                }}
                className="lang-switcher__overlay"
                open={this.state.showLanguages}
              />
              <div className="lang-switcher__drop-list" open={this.state.showLanguages}>
                {languagesArr.map((lang, i) => {
                  return (
                    <div
                      onClick={() => {
                        this.changeLanguages(lang.code);
                      }}
                      key={i}
                      className="lang-switcher__drop-item"
                    >
                      {lang.native}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(LangSwitcher);
