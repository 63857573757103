import React from 'react';
import SVG from 'react-inlinesvg';

class RenderSvg extends React.Component {
  state = {
    loading: false,
    hide: false,
  };

  componentWillUnmount() {
    clearTimeout(this.timeOut1);
    clearTimeout(this.timeOut2);
    clearTimeout(this.timeOut3);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.color !== this.props.color) {
      this.changeColor();
    }
  }

  changeColor = () => {
    const { hide, loading } = this.state;
    if (hide || loading) {
      return null;
    }
    this.setState({ hide: true });
    this.timeOut1 = setTimeout(() => this.setState({ loading: true }), 0);
    this.timeOut2 = setTimeout(() => this.setState({ loading: false }), 0);
    this.timeOut3 = setTimeout(() => this.setState({ hide: false }), 0);
  };

  render() {
    const {
      color,
      size = 20,
      heightSize = 0,
      widthSize = 0,
      path,
      rotate = 0,
      style = {},
      className = '',
    } = this.props;
    const { loading, hide } = this.state;

    if (!path || loading) {
      return null;
    }
    return (
      <div className={`svg-wrap ${hide ? 'svg-wrap_hidden' : ''} ${className}`} style={style}>
        {color ? (
          <SVG
            src={path}
            preProcessor={(code) => code.replace(/fill=".*?"/g, `fill="${color}"`)}
            style={{
              width: widthSize || size,
              height: heightSize || size,
              transform: `rotate(${rotate}deg)`,
            }}
          />
        ) : (
          <SVG
            src={path}
            style={{
              width: widthSize || size,
              height: heightSize || size,
              transform: `rotate(${rotate}deg)`,
            }}
          />
        )}
      </div>
    );
  }
}

export default RenderSvg;
