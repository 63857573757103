import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/store';
import { sampleSize } from 'lodash';
import { translate } from '@/utils';
import { gaMirror } from '@utils/metrics';

const SelectGift = () => {
  const { changeQuizData, sendChangeAnswers, state } = useStores().quizStore;
  const [giftSelected, setGiftSelected] = useState(false);
  const [giftIndex, setGiftIndex] = useState(null);
  const [randomElements, setRandomElements] = useState([]);
  const giftListDev = [
    {
      id: 5260,
      name: 'Test course',
      img: '/images/food.jpg',
    },
    {
      id: 5264,
      name: 'Fake course1',
      img: '/images/clock.jpg',
    },
    {
      id: 5267,
      name: 'Fake 2',
      img: '/images/flower.jpg',
    },
  ];

  const giftListProd = [
    {
      id: 5696,
      nameKey: 'COURSE_NAME1',
      img: '/images/courses/1.jpg',
    },
    {
      id: 5698,
      nameKey: 'COURSE_NAME2',
      img: '/images/courses/2.jpg',
    },
    {
      id: 5700,
      nameKey: 'COURSE_NAME3',
      img: '/images/courses/3.jpg',
    },
    {
      id: 5702,
      nameKey: 'COURSE_NAME4',
      img: '/images/courses/4.jpg',
    },
    {
      id: 5704,
      nameKey: 'COURSE_NAME5',
      img: '/images/courses/5.jpg',
    },
    {
      id: 5706,
      nameKey: 'COURSE_NAME6',
      img: '/images/courses/6.jpg',
    },
    {
      id: 5708,
      nameKey: 'COURSE_NAME7',
      img: '/images/courses/7.jpg',
    },
    {
      id: 5710,
      nameKey: 'COURSE_NAME8',
      img: '/images/courses/8.jpg',
    },
    {
      id: 5712,
      nameKey: 'COURSE_NAME9',
      img: '/images/courses/9.jpeg',
    },
    {
      id: 5714,
      nameKey: 'COURSE_NAME10',
      img: '/images/courses/10.jpg',
    },
    {
      id: 5716,
      nameKey: 'COURSE_NAME11',
      img: '/images/courses/11.jpg',
    },
  ];

  const giftList = process.env.REACT_APP_IS_DEV ? giftListDev : giftListProd;

  useEffect(() => {
    if (!giftList?.length || localStorage.getItem('abGift') !== 'true') {
      sendChangeAnswers({}, true);
      changeQuizData('pageName', 'linear');
    }
    setRandomElements(sampleSize(giftList, 2));
  }, [giftList.length]);

  const sendChoice = () => {
    const id = randomElements[giftIndex].id;
    gaMirror({
      event: `question_gift_COURSE_NAME${id}`,
      category: state.quizId,
      action: 'click',
      label: `question_gift_COURSE_NAME${id}`,
    });
    sendChangeAnswers({ selected_course_id: id }, true);
    changeQuizData('pageName', 'linear');
  };

  const selectGift = (i) => {
    setGiftSelected(true);
    setGiftIndex(i);
  };

  return (
    <div className="select-gift">
      <div className="select-gift__title">{translate('CHOOSE_YOUR_GIFT')}</div>
      <div className="select-gift__container">
        {randomElements.map((item, i) => {
          return (
            <div
              onClick={() => {
                selectGift(i);
              }}
              key={item.id}
              className={`select-gift__item ${giftIndex === i ? 'select-gift__item_active' : ''}`}
            >
              <div className="select-gift__item-name">
                {item.name ? item.name : translate(item.nameKey)}
              </div>
              <img className="select-gift__item-img" src={item.img} alt="" />
            </div>
          );
        })}
      </div>
      <div className="select-gift__btn-container">
        <button
          disabled={!giftSelected}
          className="orange-button button-with-arrow"
          onClick={sendChoice}
        >
          {translate('NEXT')}
        </button>
      </div>
    </div>
  );
};

export default observer(SelectGift);
