import React from 'react';
import PropTypes from 'prop-types';

/**
 * @example
 * // with custom title
 * <Input> custom <p>title</p> </Input> // не передавайте prop title
 *
 * @example
 * // focus input
 * const inputRef = useRef();
 * inputRef.current.focus(); // используй когда надо
 *
 * <Input inputRef={inputRef} />
 */
function Input({
  title,
  className,
  value,
  onChange,
  onFocus,
  showError,
  autoComplete,
  type,
  placeholder,
  style,
  textarea,
  disabled,
  inputRef,
  children,
}) {
  const baseElementClass = 'custom-input';
  let elementClass = baseElementClass;
  className && (elementClass += ` ${className}`);
  style.forEach((classPostfix) => (elementClass += ` ${baseElementClass}_${classPostfix}`));

  const props = {
    autoComplete,
    placeholder,
    value,
    onChange: (e) => onChange(e.target.value),
    onFocus: (e) => onFocus(e),
    disabled,
  };

  return (
    <div className={elementClass}>
      {(title || children) && <p className="custom-input__title">{title || children}</p>}
      {textarea ? (
        <textarea
          className={`custom-input__textarea ${showError ? 'custom-input__textarea_error' : ''}`}
          {...props}
        />
      ) : (
        <input
          className={`custom-input__input ${showError ? 'custom-input__input_error' : ''}`}
          type={type}
          ref={inputRef}
          {...props}
        />
      )}
    </div>
  );
}

Input.defaultProps = {
  title: '',
  // eslint-disable-next-line no-empty-function
  onFocus: () => {},
  className: '',
  showError: false,
  autoComplete: 'off',
  type: 'text',
  placeholder: '',
  style: [],
  textarea: false,
  disabled: false,
};

Input.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  showError: PropTypes.bool,
  autoComplete: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.arrayOf(PropTypes.string),
  textarea: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Input;
