import React, { Component } from 'react';
import NotAuthHeader from './NotAuthHeader';
import Footer from './Footer';
import { CookieModal } from '@components/CookieModal';
import Cookies from 'js-cookie';

class Layout extends Component {
  render() {
    const {
      showSignButtons,
      showBurger,
      header = true,
      footer = true,
      layoutClassName = '',
      withCookieModal,
    } = this.props;

    this.state = {
      showCookieModal: Cookies.get('showCookieModal'),
    };

    return (
      <div className={`layout-container ${layoutClassName}`}>
        <>
          {header && <NotAuthHeader {...{ showSignButtons, showBurger }} />}
          {document.body.classList.remove('authorized-body')}
          {document.body.classList.add('not-authorized-body')}
          {this.props.children}
          {footer && <Footer />}
          {!this.state.showCookieModal && withCookieModal && (
            <CookieModal
              onClick={() => {
                Cookies.set('showCookieModal', true, { expires: 365 });
                this.setState({ ...this.state, showCookieModal: true });
              }}
            />
          )}
        </>
      </div>
    );
  }
}

Layout.defaultProps = {
  showSignButtons: true,
  showBurger: true,
  withCookieModal: false,
};

export default Layout;
