import React, { useState } from 'react';

import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import RecurlyCardForm from '@components/Checkout/Recurly/RecurlyCardForm';
import { observer } from 'mobx-react';
import { gaMirror, ymMirror } from '@utils/metrics';
import { sendAmplitudeFirstLaunch } from '@utils/amplitude';
import useRouter from '@/hooks/useRouter';
import { useStores } from '@/store';
import { toast, translate } from '@/utils';
import PaymentBtns from '@components/Checkout/PaymentBtns';

const RecurlyPayment = ({ isCheckoutPage }) => {
  const [showForm, setShowForm] = useState(false);
  const [actionTokenId, setActionTokenId] = useState('');
  const [showSecureModal, setShowSecureModal] = useState(false);
  const { currentTariff } = useStores().paymentStore;

  const recurlyPublicKey = process.env.REACT_APP_RECURLY_PUBLIC_KEY;
  const { state } = useStores().quizStore;
  const { recurlyPaymentAction } = useStores().paymentStore;
  const gaEventCategory = isCheckoutPage ? 'checkout' : `quiz_checkout_${state.quizId}`;
  const quizHasEmailQuestion = localStorage.getItem('quizEmail');
  const router = useRouter();

  const recurlyPayment = async (token, email) => {
    try {
      let response;
      if (isCheckoutPage) {
        response = await recurlyPaymentAction('checkout', { email, billingToken: token });
      } else {
        response = await recurlyPaymentAction('quiz', {
          billingToken: token,
          ...(!quizHasEmailQuestion ? { email } : {}),
        });
      }
      successPayment(response.link, email);
    } catch (err) {
      if (err.code === 422) {
        toast(translate(err.messages[0]?.value));
      } else if (err.code === 424) {
        // 3ds secure error
        setActionTokenId(err.three_ds_action_token_id);
        setShowSecureModal(true);
      } else {
        toast(translate('ERROR_OCCURRED'));
      }
      failedPayment();
    }
  };

  const successPayment = (link, email) => {
    const userEmail = email || state.oldEmail;
    gaMirror({
      event: 'start_trial',
      category: gaEventCategory,
      action: 'click',
      label: 'start_trial',
    });
    ymMirror('successPayment');
    sendAmplitudeFirstLaunch(state.survey.data, userEmail);
    router.push(link);
  };

  const failedPayment = () => {
    ymMirror('failedPayment');
    gaMirror({
      event: 'failed_payment',
      category: gaEventCategory,
      action: 'payment',
      label: 'failed_payment',
    });
  };

  return (
    <>
      <RecurlyProvider publicKey={recurlyPublicKey}>
        <Elements>
          {showForm ? (
            <div className="recurly-payment">
              <RecurlyCardForm
                isCheckoutPage={isCheckoutPage}
                recurlyPayment={recurlyPayment}
                successPayment={successPayment}
                failedPayment={failedPayment}
                actionTokenId={actionTokenId}
                showSecureModal={showSecureModal}
                gaEventCategory={gaEventCategory}
                closeSecureModal={() => {
                  setShowSecureModal(false);
                }}
                quizHasEmailQuestion={quizHasEmailQuestion}
              />
            </div>
          ) : (
            <PaymentBtns
              openForm={() => {
                setShowForm(true);
              }}
              currentTariff={currentTariff}
              allFields={isCheckoutPage}
              gaEventCategory={gaEventCategory}
            />
          )}
        </Elements>
      </RecurlyProvider>
    </>
  );
};

export default observer(RecurlyPayment);
