import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Layout from './Layout';
import UserStore from '../store/UserStore';
import { translate } from '../utils';

const ErrorIndicator = ({ history, error }) => {
  useEffect(() => {
    history.replace({ pathname: '/error', state: {} });
    if (error?.code === 401) {
      UserStore.logOut();
    }
  }, []);

  return (
    <Layout header={false} footer={false}>
      <div className="page-404">
        <div className="page-404__inner">
          <div className="page-404__error">{error?.code || 404}</div>
          <div className="page-404__error-shadow">{error?.code || 404}</div>
        </div>
        <div className="page-404__title">
          {error
            ? `${translate('Error')}: ${JSON.stringify(error.message)}`
            : translate('ERROR_OCCURRED')}
        </div>
        <div className="page-404__note">{translate('TRY_RESET')}:</div>
        <button type="button" className="page-404__btn" onClick={() => history.push('/')}>
          {translate('BACK_TO_HOME')}
        </button>
      </div>
    </Layout>
  );
};

export default withRouter(ErrorIndicator);
