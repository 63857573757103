import React, { useEffect, useState } from 'react';
import PaymentForm from '@components/Checkout/PaymentForm';
import { gaMirror, ymMirror } from '@utils/metrics';
import PaymentBtns from '@components/Checkout/PaymentBtns';

const CheckoutPayment = ({ currentTariff, gaEventCategory, isCheckoutPage }) => {
  const [showForm, setShowForm] = useState(false);
  const [oneWeekTest, setOneWeekTest] = useState(false);

  useEffect(() => {
    (Boolean(localStorage.getItem('oneWeekTrial')) ||
      Boolean(localStorage.getItem('paymentTariff') === 'oneWeekTrial')) &&
      setOneWeekTest(true);
  }, []);

  const openForm = () => {
    ymMirror('paymentView');
    gaMirror({
      event: 'get_your_trial',
      category: gaEventCategory,
      action: 'click',
      label: 'get_your_trial',
    });
    setShowForm(true);
  };

  return (
    <>
      {!showForm && (
        <PaymentBtns
          openForm={openForm}
          currentTariff={currentTariff}
          allFields={isCheckoutPage}
          gaEventCategory
        />
      )}
      <div className="checkout__form" open={showForm}>
        <div className="checkout__form-column">
          <PaymentForm
            allFields={isCheckoutPage}
            oneWeekTest={oneWeekTest}
            currentTariff={currentTariff}
            open
          />
        </div>
      </div>
    </>
  );
};

export default CheckoutPayment;
