import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { getPublicNewsList } from '@actions/news';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive/src/Component';
import breakpoints from '@utils/breakpoints';
import { translate } from '@/utils';

import girlImage from '@images/main-girl.png';
import cup from '@images/cup.png';
import bubbles from '@images/bubbles.png';
import dna from '@images/dna.png';
import cake from '@images/cake.png';
import dumb from '@images/dumb.png';
import ava1 from '@images/ava1.png';
import ava2 from '@images/ava2.png';
import ava3 from '@images/ava3.png';
import ava4 from '@images/ava4.png';
import ava5 from '@images/ava5.png';
import ava6 from '@images/ava6.png';
import ava7 from '@images/ava7.png';
import ava8 from '@images/ava8.png';
import ava9 from '@images/ava9.png';
import ava10 from '@images/ava10.png';
import ava11 from '@images/ava11.png';
import ava12 from '@images/ava12.png';
import ava13 from '@images/ava13.png';
import ava1vi from '@images/ava1vi_main.png';
import ava2vi from '@images/ava_2vi_main.png';
import ava3vi from '@images/ava_3vi_main.png';
import ava4vi from '@images/ava4.png';
import ava5vi from '@images/ava5.png';
import ava6vi from '@images/ava6.png';
import ava7vi from '@images/ava7.png';
import ava8vi from '@images/ava8.png';
import ava9vi from '@images/ava9.png';
import ava10vi from '@images/ava10.png';
import ava11vi from '@images/ava11.png';
import ava12vi from '@images/ava12.png';
import ava13vi from '@images/ava13.png';
import Layout from '@components/Layout';
import ErrorIndicator from '@components/ErrorIndicator';
import MainSection from '@components/HomePage/MainSection';
import CommonSection from '@components/HomePage/CommonSection';
import FixedLinks from '@components/HomePage/FixedLinks';
import AboutItem from '@components/HomePage/AboutItem';
// import AppSection from '@components/HomePage/AppSection';
import ReviewItem from '@components/HomePage/ReviewItem';
import NewsItem from '@components/News/NewsItem';
import TariffSection from '@components/HomePage/TariffSection';

@inject('commonStore', 'appStore')
@observer
class HomeContainer extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      firstSectionIsActive: null,
      lastSectionIsActive: null,
      currentSlide: null,
      sliderLength: null,
      pageLang: this.props.appStore.ln,
      aboutData: [
        {
          image: cup,
          labelKey: 'IT_KNOWS_PSYCHOLOGY',
          textKey: 'WORKS_WITH_YOUR_BELIEVES',
        },
        {
          image: bubbles,
          labelKey: 'ITS_PERSONAL',
          textKey: 'HE_COURSE_IS_CUSTOMIZABLE',
        },
        {
          image: dna,
          labelKey: 'IT_TRACKS',
          textKey: 'TRACKING_YOUR_DAILY_ACTIVITY',
        },
        {
          image: cake,
          labelKey: 'REAL_CHEF',
          textKey: 'IT_HELPS_YOU_GROUP',
        },
        {
          image: dumb,
          labelKey: 'IT_TRAINS_YOU',
          textKey: 'BASED_ON_YOUR_GOAL',
        },
      ],
      reviewsData: [
        {
          authorKey: 'AUTHOR_4',
          progressKey: 'LOST_4',
          timeKey: 'IN_1_MONTHS',
          textKey: 'REVIEW_TEXT_4',
          image: {
            default: ava4,
            en: ava4,
            vi: ava4vi,
          },
        },
        {
          authorKey: 'AUTHOR_5',
          progressKey: 'LOST_5',
          timeKey: 'IN_1_MONTHS',
          textKey: 'REVIEW_TEXT_5',
          image: {
            default: ava5,
            en: ava5,
            vi: ava5vi,
          },
        },
        {
          authorKey: 'AUTHOR_6',
          progressKey: 'LOST_6',
          timeKey: 'IN_3_MONTHS',
          textKey: 'REVIEW_TEXT_6',
          image: {
            default: ava6,
            en: ava6,
            vi: ava6vi,
          },
        },
        {
          authorKey: 'AUTHOR_7',
          progressKey: 'LOST_3',
          timeKey: 'IN_2_MONTHS',
          textKey: 'REVIEW_TEXT_7',
          image: {
            default: ava7,
            en: ava7,
            vi: ava7vi,
          },
        },
        {
          authorKey: 'AUTHOR_8',
          progressKey: 'LOST_8',
          timeKey: 'IN_3_MONTHS',
          textKey: 'REVIEW_TEXT_8',
          image: {
            default: ava8,
            en: ava8,
            vi: ava8vi,
          },
        },
        {
          authorKey: 'AUTHOR_9',
          progressKey: 'LOST_9',
          timeKey: 'IN_2_MONTHS',
          textKey: 'REVIEW_TEXT_9',
          image: {
            default: ava9,
            en: ava9,
            vi: ava9vi,
          },
        },
        {
          authorKey: 'AUTHOR_10',
          progressKey: 'LOST_10',
          timeKey: 'IN_4_MONTHS',
          textKey: 'REVIEW_TEXT_10',
          image: {
            default: ava10,
            en: ava10,
            vi: ava10vi,
          },
        },
        {
          authorKey: 'AUTHOR_11',
          progressKey: 'LOST_11',
          timeKey: 'IN_1_MONTHS',
          textKey: 'REVIEW_TEXT_11',
          image: {
            default: ava11,
            en: ava11,
            vi: ava11vi,
          },
        },
        {
          authorKey: 'AUTHOR_12',
          progressKey: 'LOST_4',
          timeKey: 'IN_1_MONTHS',
          textKey: 'REVIEW_TEXT_12',
          image: {
            default: ava12,
            en: ava12,
            vi: ava12vi,
          },
        },
        {
          authorKey: 'AUTHOR_13',
          progressKey: 'LOST_13',
          timeKey: 'IN_4_MONTHS',
          textKey: 'REVIEW_TEXT_13',
          image: {
            default: ava13,
            en: ava13,
            vi: ava13vi,
          },
        },
        {
          authorKey: 'AUTHOR_1',
          progressKey: 'LOST_1',
          timeKey: 'IN_3_MONTHS',
          textKey: 'VEAN_HAS_BEEN_AWESOME',
          image: {
            default: ava1,
            en: ava1,
            vi: ava1vi,
          },
        },
        {
          authorKey: 'AUTHOR_2',
          progressKey: 'LOST_2',
          timeKey: 'IN_2_MONTHS',
          textKey: 'VERY_HELPFUL_AND_INSIGHTFUL',
          image: {
            default: ava2,
            en: ava2,
            vi: ava2vi,
          },
        },
        {
          authorKey: 'AUTHOR_3',
          progressKey: 'LOST_3',
          timeKey: 'IN_3_MONTHS',
          textKey: 'REALLY_LOVING_THIS_PROGRAM',
          image: {
            default: ava3,
            en: ava3,
            vi: ava3vi,
          },
        },
      ],
      blogData: [],
    };
  }

  async componentDidMount() {
    const { commonStore } = this.props;
    document.addEventListener('wheel', this.swipeOnWheel);
    document.body.classList.add('main-page');
    commonStore.setFullPageSliderWheelStatus(true);
    commonStore.setFullPageSlideIndex(0);
    this.setState({
      firstSectionIsActive: true,
      lastSectionIsActive: false,
      sliderLength: this.slider.props.children.length,
      currentSlide: 0,
    });
    this.setPublicNews();
  }

  async componentDidUpdate() {
    const { appStore } = this.props;
    if (this.state.pageLang !== appStore.ln) {
      this.setPublicNews();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('main-page');
    document.removeEventListener('wheel', this.swipeOnWheel);
    this.setState({
      firstSectionIsActive: null,
      lastSectionIsActive: null,
    });
    this._isMounted = false;
  }

  setPublicNews = async () => {
    this._isMounted = true;
    const { appStore } = this.props;
    try {
      const res = await getPublicNewsList();
      if (this._isMounted) {
        this.setState({ pageLang: appStore.ln, blogData: res.data });
      }
    } catch (e) {
      throw e;
    }
  };

  swipeOnWheel = (e) => {
    const { commonStore } = this.props;
    if (
      window.innerWidth >= parseInt(breakpoints['sm-plus'], 10) &&
      commonStore.fullPageSliderWheelEnabled &&
      !commonStore.stopHomePageWheel
    ) {
      if (e.deltaY < 0 && this.state.currentSlide !== 0) {
        this.slider.slickPrev();
      } else if (e.deltaY > 0 && this.state.currentSlide + 1 !== this.state.sliderLength) {
        this.slider.slickNext();
      }
    }
  };

  render() {
    const { commonStore } = this.props;
    const { appStore } = this.props;

    if (this.error) {
      return <ErrorIndicator error={this.error} />;
    }

    if (
      (commonStore.fullPageSlideIndex || commonStore.fullPageSlideIndex === 0) &&
      window.innerWidth >= parseInt(breakpoints['sm-plus'], 10) &&
      this.slider
    ) {
      this.slider.slickGoTo(commonStore.fullPageSlideIndex);
    }

    const settings = {
      dots: true,
      infinite: false,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      cssEase: 'linear',
      speed: 600,
      dotsClass: 'full-page__dots',
      draggable: true,
      onInit: () => {
        document.addEventListener('wheel', this.swipeOnWheel);
        this.setState({ firstSectionIsActive: true });
        this.setState({ lastSectionIsActive: false });
      },
      beforeChange: (currentSlide, nextSlide) => {
        if (currentSlide === 0 && nextSlide !== 0) {
          this.setState({ firstSectionIsActive: false });
        }
        this.setState({
          lastSectionIsActive: nextSlide + 1 === this.state.sliderLength,
        });
      },
      afterChange: (currentSlide) => {
        if (currentSlide === 0) {
          this.setState({ firstSectionIsActive: true });
        }
        this.setState({ currentSlide });
      },
      responsive: [
        {
          breakpoint: parseInt(breakpoints.xs, 10) + 1,
          settings: 'unslick',
        },
      ],
    };

    return (
      <>
        <Layout
          user_is_authorized={false}
          layoutClassName={`home-page ${
            this.state.lastSectionIsActive ? 'home-page_footer-show' : ''
          }`}
          withCookieModal
        >
          <div className="full-page">
            <MediaQuery minWidth={breakpoints['sm-plus']}>
              <div className="full-page__column">
                {this.state.lastSectionIsActive ? (
                  <div
                    onClick={() => {
                      this.slider.slickGoTo(0);
                    }}
                    className="full-page__open-first slick-next"
                  />
                ) : null}
              </div>
            </MediaQuery>
            <div className="full-page__column full-page__column_right">
              <MediaQuery minWidth={breakpoints['sm-plus']}>
                <img
                  open={this.state.firstSectionIsActive}
                  className="full-page__girl"
                  src={girlImage}
                  alt=""
                />

                <div open={this.state.firstSectionIsActive} className="full-page__decoration">
                  <span />
                  <span />
                  <span />
                </div>
                <FixedLinks
                  firstSectionIsActive={this.state.firstSectionIsActive}
                  lastSectionIsActive={this.state.lastSectionIsActive}
                />
              </MediaQuery>
              <Slider
                {...settings}
                className="full-page__slider"
                ref={(slider) => (this.slider = slider)}
              >
                <div className="full-page__slide full-page__slide_main">
                  <MainSection />
                </div>
                <div className="full-page__slide full-page__slide_about">
                  <CommonSection
                    mobileItemLimit={3}
                    classNameFromSlider="about"
                    classModifications="common-section_about"
                    label={translate('WHY_DOES_VEAN').replace(
                      /{{project_name}}/g,
                      appStore.app_info?.project_name || 'Vean',
                    )}
                    text={translate('EVERYTHING_TO_HELP')}
                  >
                    {this.state.aboutData.map((item, i) => {
                      return <AboutItem key={i} data={item} />;
                    })}
                  </CommonSection>
                </div>
                {/* <div className="full-page__slide full-page__slide_app">
                  <AppSection />
                  </div> */}
                <div className="full-page__slide full-page__slide_reviews">
                  <CommonSection
                    mobileItemLimit={2}
                    classNameFromSlider="reviews"
                    classModifications="common-section_reviews"
                    label={translate('SUCCESS_STORIES')}
                  >
                    {this.state.reviewsData.map((item, i) => {
                      return <ReviewItem ln={this.state.pageLang} key={i} data={item} />;
                    })}
                  </CommonSection>
                </div>
                <div className="full-page__slide full-page__slide_blog">
                  <CommonSection
                    mobileItemLimit={2}
                    classNameFromSlider="blog"
                    classModifications="common-section_blog"
                    label={translate('OUR_BLOG')}
                  >
                    {this.state.blogData.map((news) => {
                      return (
                        <NewsItem custom news={news} newsList={this.state.blogData} key={news.id} />
                      );
                    })}
                  </CommonSection>
                </div>
                <div className="full-page__slide  full-page__slide_last full-page__slide_tariff">
                  <TariffSection />
                </div>
              </Slider>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default HomeContainer;
