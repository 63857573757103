import { message } from 'antd';
import UserStore from '../store/UserStore';
import AppStore from '../store/AppStore';

export const bundle_id = process.env.REACT_APP_BUNDLE_ID;
export const endpoint = `${process.env.REACT_APP_API_END_POINT}/web`;
export const HOST = endpoint.replace('/web', '/');

export function api(
  method,
  args = {},
  type = 'POST',
  silent = false,
  lastPartUrl = '',
  setLang = '',
) {
  return new Promise((resolve, reject) => {
    function processReject(error) {
      if (error?.code === 401) {
        // window.location.href('/');
      }
      if (!silent) {
        reject(error);
      } else {
        resolve(false);
      }
    }

    const token = localStorage.token || null;
    const lang = setLang || localStorage.lang || 'en';
    let params = '';
    const startQuery = token
      ? `?token=${token}&bundle_id=${bundle_id}&lang=${lang}${lastPartUrl}`
      : `?bundle_id=${bundle_id}&lang=${lang}${lastPartUrl}`;
    if (type === 'GET') {
      Object.keys(args).map((key) => {
        if (Array.isArray(args[key])) {
          args[key] = args[key].join(',');
        }
        params += `&${key}=${args[key]}`;
        return null;
      });
      method += startQuery + params;
    } else {
      method += startQuery;
    }
    fetch(endpoint + method, {
      method: type,
      body: type !== 'GET' ? JSON.stringify(args) : null,
      ...(type !== 'GET'
        ? {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        : {}),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty('error')) {
              processReject(data.error);
            } else if (data) {
              resolve(data);
            } else {
              processReject({
                error: { message: 'Invalid Server Response', code: 2 },
              });
            }
          })
          .catch(() => {
            processReject('Network Error');
          });
      })
      .catch((error) => {
        processReject(error);
      });
  });
}

export function toast(text = 'Something went wrong', type = 'error') {
  if (type === 'error') {
    message.error(text);
  } else if (type === 'success') {
    message.success(text);
  } else if (type === 'info') {
    message.info(text);
  } else if (type === 'warning') {
    message.info(text);
  } else if (type === 'loading') {
    message.info(text);
  }
}

export const uploadImage = async (data, name) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const res = await api('/upload', { file: { data, name } });
    return res.result;
  } catch (e) {
    throw e;
  }
};

export const addHostToPath = (image) => {
  if (typeof image === 'string') {
    return HOST + image;
  }
  if (image && image.path) {
    return HOST + image.path;
  }
  return null;
};

export const acl = () => {
  const { role_id } = UserStore.user;
  return role_id < 2;
};

export const getBrowser = () => {
  const ua = window.navigator.userAgent;
  if (ua.search(/Chrome/) > 0) {
    return 'Google Chrome';
  }
  if (ua.search(/Firefox/) > 0) {
    return 'Firefox';
  }
  if (ua.search(/Opera/) > 0) {
    return 'Opera';
  }
  if (ua.search(/Safari/) > 0) {
    return 'Safari';
  }
  if (ua.search(/MSIE/) > 0) {
    return 'Internet Explorer';
  }
  if (ua.search(/Edg/) > 0) {
    return 'Edge';
  }
};

const userDeviceArray = [
  { device: 'Android', platform: /Android/ },
  { device: 'apple', platform: /iPhone/ },
  { device: 'apple', platform: /iPad/ },
  { device: 'Symbian', platform: /Symbian/ },
  { device: 'Windows Phone', platform: /Windows Phone/ },
  { device: 'Tablet OS', platform: /Tablet OS/ },
  { device: 'Linux', platform: /Linux/ },
  { device: 'Windows', platform: /Windows NT/ },
  { device: 'apple', platform: /Macintosh/ },
];

const platform = navigator.userAgent;

export function getPlatform() {
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const i in userDeviceArray) {
    if (userDeviceArray[i].platform.test(platform)) {
      return userDeviceArray[i].device;
    }
  }
  return `Неизвестная платформа!${platform}`;
}

export function translate(word = '', key, data = null) {
  const { translations, defaultTranslations } = AppStore;
  if (!translations) {
    return word;
  }
  const check_word = word.toUpperCase();
  let str = '';
  if (key && translations[check_word]) {
    if (key === 'change') {
      str = translations[check_word].replace('$change$', data);
    } else if (key === 'purchase') {
      str = translations[check_word].replace('$key1$', data.key1);
      str = str.replace('$key2$', data.key2);
    }
  } else {
    str = translations[check_word] || defaultTranslations[check_word] || word;
  }
  return `${str}`;
}

export const renderSubTitle = (sub_title, type) => {
  if (!sub_title) {
    return '';
  }
  let str = sub_title;
  if (
    (type === 'material' && sub_title.toLowerCase() === 'материал') ||
    (type === 'checklist' && sub_title.toLowerCase() === 'чек-лист') ||
    (type === 'poll' && sub_title.toLowerCase() === 'опросник') ||
    (type === 'test' && sub_title.toLowerCase() === 'тест')
  ) {
    str = translate(type);
  }
  return str;
};

export const getIosVersion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
};
