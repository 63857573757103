import React, { useState } from 'react';
import { useStores } from '@/store';

import {
  CardNumberElement,
  CardMonthElement,
  CardYearElement,
  CardCvvElement,
  useRecurly,
  ThreeDSecureAction,
} from '@recurly/react-recurly';
import { toast, translate } from '@/utils';
import isEmail from 'validator/lib/isEmail';
import colorsVars from '@utils/colorsVars';

import Spinner from '@components/Spinner';
import RenderSvg from '@components/RenderSvg';
import angleDown from '@icons/angle_down.svg';
import { observer } from 'mobx-react';
import Modal from '@elements/Modal';
import RecurlyPayPalButton from '@components/Checkout/Recurly/RecurlyPayPalBtn';
import visa from '@icons/visa.svg';
import mastercard from '@icons/mastercard.svg';
import PaymentBtns from '@components/Checkout/PaymentBtns';

const RecurlyCardForm = ({
  isCheckoutPage,
  successPayment,
  failedPayment,
  recurlyPayment,
  actionTokenId,
  showSecureModal,
  closeSecureModal,
  gaEventCategory,
  quizHasEmailQuestion,
}) => {
  const { recurlyPaymentAction, currentTariff } = useStores().paymentStore;
  const recurly = useRecurly();
  const formRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [secureLoading, setSecureLoading] = useState(false);
  const [payBtnEnabled, setPayBtnEnabled] = useState(true);
  const emailIsValid = Boolean(email.trim() && isEmail(email, [{ require_display_name: true }]));
  const noErrors = {
    number: false,
    month: false,
    year: false,
    first_name: false,
    last_name: false,
    email: false,
    cvv: false,
  };
  const [inputErrors, setInputErrors] = useState(noErrors);
  const onlyCardAbTest = localStorage.getItem('card');
  const onlyCardAndStripeButtonAbTest = localStorage.getItem('cardgpayapay');

  const handleFocus = (field) => {
    setInputErrors((prev) => ({ ...prev, [field]: false }));
  };

  const handleEmail = (value) => {
    setEmail(value);
    setInputErrors((prev) => ({ ...prev, email: false }));
  };

  const handleSubmit = async (event) => {
    if (event.preventDefault) {
      event.preventDefault();
    }
    if (isCheckoutPage && !emailIsValid) {
      setInputErrors((prev) => ({ ...prev, email: true }));
      return false;
    }

    setLoading(true);
    setInputErrors(noErrors);

    recurly.token(formRef.current, async (err, token) => {
      if (err) {
        if (err.code === 'validation' || err.code === 'invalid-parameter') {
          err.fields.forEach((item) => {
            setInputErrors((prev) => ({ ...prev, [item]: true }));
          });
          setLoading(false);
        }
      } else {
        await recurlyPayment(token.id, email);
        setLoading(false);
      }
    });
  };

  const styles = {
    fontSize: '14px',
  };

  const handleThreeDSecureToken = async (token) => {
    setSecureLoading(true);
    const response = await recurlyPaymentAction('secure', { secureTokenId: token.id });
    setSecureLoading(false);
    closeSecureModal(false);
    successPayment(response.link, email);
  };

  const handleThreeDSecureError = () => {
    toast(translate('ERROR_OCCURRED'));
    failedPayment();
    closeSecureModal(false);
  };

  return (
    <form className="recurly-form" onSubmit={handleSubmit} ref={formRef}>
      {(isCheckoutPage || !quizHasEmailQuestion) && (
        <div className="recurly-form__line">
          <div className="recurly-form__label">{translate(translate('EMAIL'))}</div>
          <input
            data-recurly="email"
            className={`recurly-form__input ${
              inputErrors.email ? 'recurly-form__input_error' : ''
            }`}
            onFocus={() => {
              handleFocus('email');
            }}
            placeholder="example@gmail.com"
            value={email}
            onChange={(e) => {
              handleEmail(e.target.value);
            }}
          />
        </div>
      )}

      {!onlyCardAbTest && (
        <div className="recurly-form__buttons-container">
          <div className="payment-form__pay-btn payment-form__pay-btn_card">
            <RenderSvg path={visa} widthSize={32} heightSize={22} />
            <RenderSvg path={mastercard} widthSize={32} heightSize={22} />
          </div>
          {payBtnEnabled && !localStorage.getItem('abGpay') && (
            <PaymentBtns
              allFields={isCheckoutPage}
              setPayBtnEnabled={setPayBtnEnabled}
              currentTariff={currentTariff}
              gaEventCategory={gaEventCategory}
              btnWithForm
            />
          )}
          {currentTariff.payPalId && !onlyCardAndStripeButtonAbTest && (
            <RecurlyPayPalButton
              successPayment={successPayment}
              failedPayment={failedPayment}
              recurlyPayment={recurlyPayment}
              isCheckoutPage={isCheckoutPage}
              email={email}
              emailIsValid={emailIsValid}
              quizHasEmailQuestion={quizHasEmailQuestion}
              setEmailError={() => {
                setInputErrors((prev) => ({ ...prev, email: true }));
              }}
            />
          )}
        </div>
      )}

      <div className="recurly-form__line recurly-form__line_half">
        <div className="recurly-form__line recurly-form__line_no-pad">
          <div className="recurly-form__label">{translate('FIRST_NAME')}</div>
          <input
            data-recurly="first_name"
            className={`recurly-form__input ${
              inputErrors.first_name ? 'recurly-form__input_error' : ''
            }`}
            onFocus={() => {
              handleFocus('first_name');
            }}
            placeholder="John"
            defaultValue=""
          />
        </div>
        <div className="recurly-form__line recurly-form__line_no-pad">
          <div className="recurly-form__label">{translate('LAST_NAME')}</div>
          <input
            className={`recurly-form__input ${
              inputErrors.last_name ? 'recurly-form__input_error' : ''
            }`}
            onFocus={() => {
              handleFocus('last_name');
            }}
            data-recurly="last_name"
            placeholder="Smith"
            defaultValue=""
          />
        </div>
      </div>
      <div className="recurly-form__line">
        <div className="recurly-form__label">{translate('CARD_DATA')}</div>
        <CardNumberElement
          className={`recurly-form__input-container ${
            inputErrors.number ? 'recurly-form__input-container_error' : ''
          }`}
          onFocus={() => {
            handleFocus('number');
          }}
          onSubmit={handleSubmit}
          style={{ ...styles, placeholder: { content: '1234 1234 1234 1234' } }}
        />
      </div>
      <div className="recurly-form__line recurly-form__line_half">
        <div className="recurly-form__line recurly-form__line_no-pad">
          <div className="recurly-form__label">{translate('EXPIRATION_DATE')}</div>
          <div className="recurly-form__line recurly-form__line_half  recurly-form__line_no-pad">
            <CardMonthElement
              className={`recurly-form__input-container ${
                inputErrors.month ? 'recurly-form__input-container_error' : ''
              }`}
              onSubmit={handleSubmit}
              onFocus={() => {
                handleFocus('month');
              }}
              style={{ ...styles, placeholder: { content: 'MM' } }}
            />
            <CardYearElement
              className={`recurly-form__input-container ${
                inputErrors.year ? 'recurly-form__input-container_error' : ''
              }`}
              onSubmit={handleSubmit}
              onFocus={() => {
                handleFocus('year');
              }}
              style={{ ...styles, placeholder: { content: 'YY' } }}
            />
          </div>
        </div>
        <div className="recurly-form__line recurly-form__line_no-pad">
          <div className="recurly-form__label">CVV / CVC</div>
          <CardCvvElement
            className={`recurly-form__input-container ${
              inputErrors.cvv ? 'recurly-form__input-container_error' : ''
            }`}
            onFocus={() => {
              handleFocus('cvv');
            }}
            onSubmit={handleSubmit}
            style={{ ...styles, placeholder: { content: 'CVV' } }}
          />
        </div>
      </div>
      {showSecureModal && (
        <Modal
          close={() => {
            closeSecureModal(false);
          }}
        >
          <div
            className={`recurly-form__secure-container ${
              secureLoading ? 'recurly-form__secure-container_loading' : ''
            }`}
          >
            <ThreeDSecureAction
              actionTokenId={actionTokenId}
              onToken={handleThreeDSecureToken}
              onError={handleThreeDSecureError}
              className="recurly-form__secure"
            />
            <Spinner className="recurly-form__secure-loading" color={colorsVars.colorBright} />
          </div>
        </Modal>
      )}
      <div className="recurly-form__agree">
        <div
          onClick={() => {
            setTermsAccepted(!termsAccepted);
          }}
          className="recurly-form__checkbox"
        >
          {termsAccepted && <RenderSvg color={colorsVars.colorLight} path={angleDown} size={14} />}
        </div>
        <div className="recurly-form__agree-text">
          <span
            dangerouslySetInnerHTML={{
              __html: translate('POLICY_AGREE_TEXT')
                .replace(
                  '{{privacy}}',
                  `<a href="/terms" target="_blank" className="py_privacy_link">
                              ${translate('TERMS')}</a>`,
                )
                .replace(
                  '{{terms}}',
                  `<a href="/privacy" target="_blank" className="py_privacy_link">
                              ${translate('PRIVACY')}</a>`,
                ),
            }}
          />
        </div>
      </div>
      <div>
        <button className="recurly-form__button orange-button" disabled={loading || !termsAccepted}>
          {loading ? <Spinner color="#ffffff" /> : translate('START_TRIAL')}
        </button>
      </div>
    </form>
  );
};

export default observer(RecurlyCardForm);
