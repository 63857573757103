export default {
  colorMain: '#32384D',
  colorAccent: '#8991AE',
  colorMinor: '#CED4E4',
  colorLight: '#FFFFFF',
  colorMute: '#E6E9F1',
  colorAttention: '#F34E4E',
  colorBright: '#F4955F',
  colorSuccess: '#25AB62',
};
