import { translate } from '@/utils';
import moment from 'moment';
import AppStore from '@store/AppStore';

const dateFormat = AppStore?.ln === 'gr' || AppStore?.ln === 'pb' ? 'DD.MM.YYYY' : 'LL';
export const tariffAdapter = (tariff) => {
  const intervalData = {
    day: {
      subPeriodText: translate('SUB_PERIOD_DAY'),
      noteText: translate('CHECKOUT_PAY_NOTE'),
      weekPrice: ((tariff.price * 7) / tariff.price_interval_value).toFixed(2),
      monthsCount: '',
    },
    week: {
      subPeriodText: translate('SUB_PERIOD_WEEK'),
      noteText: translate('CHECKOUT_PAY_NOTE_WEEK'),
      weekPrice: (tariff.price / tariff.price_interval_value).toFixed(2),
      monthsCount: '',
    },
    month: {
      subPeriodText: translate('SUB_PERIOD_MONTH'),
      noteText: translate('CHECKOUT_PAY_NOTE'),
      weekPrice: (tariff.price / (tariff.price_interval_value * 4)).toFixed(2),
      monthsCount: tariff.price_interval_value,
    },
  };

  return {
    currency: tariff.currency_sign || tariff.currency_iso_code,
    currencyCode: tariff.currency_iso_code,
    stripeId: tariff.id,
    payPalId: tariff.paypal_price_id,
    recurlyId: tariff.recurly_price_id,
    priceAfterTrial: tariff.price,
    oldPriceAfterTrial: ((tariff.price / 39) * 100).toFixed(2),
    weekPrice: intervalData[tariff.price_interval_type]?.weekPrice,
    intervalType: tariff.price_interval_type,
    intervalCount: tariff.price_interval_value,
    trialPrice: tariff.trial_price || 0,
    oldTrialPrice: tariff.trial_price
      ? (tariff.trial_price * 10).toFixed(2)
      : (tariff.price / 4).toFixed(2),
    trialCount: tariff.trial_days,
    trialText: translate('14_TRIAL').replace('14', tariff.trial_days),
    planText: translate('2_MONTH_PLAN'),
    firstPayDate: moment().add(tariff.trial_days, 'days').format(dateFormat),
    subPeriod: `${tariff.price_interval_value} ${
      intervalData[tariff.price_interval_type]?.subPeriodText
    }`,
    noteText: intervalData[tariff.price_interval_type]?.noteText,
    monthsCount: intervalData[tariff.price_interval_type]?.monthsCount,
    changeTariffs: tariff.changeTariffs,
    bestOffer: tariff.bestOffer,
  };
};
