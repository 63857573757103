import React from 'react';
import PropTypes from 'prop-types';

import RenderSvg from '@/components/RenderSvg';
import info from '@icons/info1.svg';

import { translate } from '@/utils';

const SuccessMessage = ({ closeModal }) => {
  return (
    <div className="home-login__success">
      <div className="home-login__success-title">
        <RenderSvg path={info} size={28} />
        <div>{translate('RESTORE_SUCCESS_TITLE')}</div>
      </div>
      <p className="home-login__success-tip">{translate('RESTORE_SUCCESS_TIP')}</p>
      <button className="orange-button" onClick={closeModal}>
        {translate('OK')}
      </button>
    </div>
  );
};

SuccessMessage.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default SuccessMessage;
