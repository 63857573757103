import { api, bundle_id } from '../utils';

export const loginUser = async (user) => {
  try {
    const res = await api('/users/login', user, 'POST');
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const socialRegister = async (data) => {
  try {
    const res = await api('/users/social', data, 'POST');
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const logOut = async () => {
  try {
    await api('/users/logout', {}, 'POST');
  } catch (e) {
    throw e;
  }
};

export const recover = async (email) => {
  try {
    await api('/users/recover', { email, bundle_id }, 'POST');
  } catch (e) {
    throw e;
  }
};
