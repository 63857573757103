import React, { useEffect, useRef, useState } from 'react';
import RenderSvg from '@components/RenderSvg';
import playIcon from '@icons/play_icon.svg';
import { getQuizVideo } from '@actions/checkout';
import { useStores } from '@/store';
import Spinner from '@components/Spinner';

const ResultVideo = () => {
  const [currentVideo, setCurrentVideo] = useState(0);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [fistPlayStarted, setFistPlayStarted] = useState(false);
  const [mediasList, setMediasList] = useState([]);
  const { state } = useStores().quizStore;
  const [loading, setLoading] = useState(true);
  const videoContainer = useRef();
  useEffect(() => {
    if (!mediasList.length) {
      getMedias();
    }
  }, []);

  const getMedias = async () => {
    try {
      const medias = await getQuizVideo(state.uniqueIds.code);
      setMediasList(medias);
    } finally {
      setLoading(false);
    }
  };
  const pauseVideo = () => {
    const video = videoContainer.current.children;
    for (let i = 0; i < video.length; i++) {
      if (!video[i].classList.contains('video-result__hidden')) {
        video[i].pause();
        setShowPlayButton(true);
      }
    }
  };
  const startPlayVideo = () => {
    const video = videoContainer.current.children;
    const firstVideo = video[0];
    const lastVideo = video[mediasList.length - 1];

    if (!fistPlayStarted) {
      setFistPlayStarted(true);
      firstVideo.play();
      setCurrentVideo(0);
      setShowPlayButton(false);

      lastVideo.addEventListener('ended', () => {
        setShowPlayButton(true);
        firstVideo.classList.remove('video-result__hidden');
        lastVideo.classList.add('video-result__hidden');
      });

      let flag = true;
      for (let i = 0; i < video.length; i++) {
        // eslint-disable-next-line no-loop-func
        video[i].addEventListener('timeupdate', () => {
          if (video[i].duration - 10 <= video[i].currentTime && flag) {
            video[i + 1].load();
            flag = false;
          }
          if (video[i].duration - 0.4 <= video[i].currentTime) {
            if (video[i] !== lastVideo) {
              video[i + 1].play();
            }
          }
          if (video[i].duration === video[i].currentTime) {
            if (video[i] !== lastVideo) {
              video[i].classList.add('video-result__hidden');
              video[i + 1].classList.remove('video-result__hidden');
            }
          }
        });
      }
    } else {
      for (let i = 0; i < video.length; i++) {
        if (!video[i].classList.contains('video-result__hidden')) {
          video[i].play();
          setShowPlayButton(false);
        }
      }
    }
  };

  return (
    <div className="video-result">
      {mediasList.length ? (
        <>
          {showPlayButton && (
            <div onClick={startPlayVideo} className="video-result__play">
              <span className="video-result__play-button">
                <RenderSvg path={playIcon} size={14} />
              </span>
            </div>
          )}
          {!showPlayButton && <div onClick={pauseVideo} className="video-result__pause" />}
          <div ref={videoContainer} className="video-result__container">
            {mediasList.map((video, i) => {
              return (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video
                  className={currentVideo !== i ? 'video-result__hidden' : ''}
                  width="100%"
                  height="auto"
                  controls={false}
                  key={i}
                >
                  {video.mp4 && <source src={video.mp4} />}
                  {video.ogg && <source src={video.ogg} />}
                  {video.webm && <source src={video.webm} />}
                </video>
              );
            })}
          </div>
        </>
      ) : (
        <>{loading ? <Spinner /> : null}</>
      )}
    </div>
  );
};

export default ResultVideo;
