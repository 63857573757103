import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/store';
import { translate } from '@/utils';
import { priceAdapter } from '@utils/adapters/priceAdapter';
import { gaMirror } from '@utils/metrics';
import { tariffAdapter } from '@utils/adapters/tariffAdapter';

const SelectPlan = () => {
  const { changeQuizData, state } = useStores().quizStore;
  const { currentTariff, changePlans, changePaymentTariff } = useStores().paymentStore;

  const handleClick = () => {
    gaMirror({
      event: 'trialChosen',
      category: state.quizId,
      action: 'click',
      label: `choosed_trial_${currentTariff.priceAfterTrial}`,
    });
    changeQuizData('pageName', 'payment');
  };

  return (
    <div className="select-plan">
      <div className="select-plan__title">{translate('CHOOSE_YOUR_TRIAL')}</div>
      <div className="select-plan__text">{translate('CHOOSE_YOUR_TRIAL_TEXT1')}</div>
      <div className="select-plan__text">
        <b>{translate('CHOOSE_YOUR_TRIAL_TEXT2')}</b>
      </div>
      <div className="select-plan__text select-plan__text_accent">
        <b>
          {translate('CHOOSE_YOUR_TRIAL_TEXT3')
            .replace('{{currency}}', currentTariff.currency)
            .replace('{{price}}', Number(currentTariff.trialPrice).toFixed(2))}
        </b>
      </div>
      <div className="select-plan__select">
        <b>{translate('Please_select')}*</b>
      </div>
      <div className="select-plan__list">
        {currentTariff.changeTariffs?.map((item) => {
          const itemData = tariffAdapter(changePlans[item]);
          return (
            <div key={item} className="select-plan__item">
              <div
                className={`select-plan__item-inner ${
                  itemData?.stripeId === currentTariff?.stripeId
                    ? 'select-plan__item-inner_active'
                    : ''
                }`}
                onClick={() => {
                  changePaymentTariff(item);
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: priceAdapter(
                      itemData.currency,
                      Number(itemData.trialPrice)?.toFixed(2),
                      itemData.currencyCode,
                    ),
                  }}
                />
              </div>
              {itemData.bestOffer && (
                <div className="select-plan__best">{translate('MOST_POPULAR_CHOICE')}</div>
              )}
            </div>
          );
        })}
      </div>
      <div className="select-plan__btn-container">
        <button onClick={handleClick} className="orange-button button-with-arrow">
          {translate('SEE_MY_PLAN')}
        </button>
      </div>
    </div>
  );
};

export default observer(SelectPlan);
