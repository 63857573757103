import React from 'react';
import MainSection from '@components/HomePage/MainSection';
import MediaQuery from 'react-responsive/src/Component';
import breakpoints from '@utils/breakpoints';
import girlImage from '@images/main-girl.png';
import FixedLinks from '@components/HomePage/FixedLinks';

const WebviewToSafari = () => {
  return (
    <div className="full-page full-page_webview">
      <div className="full-page__column" />
      <div className="full-page__column full-page__column_right full-page__column_webview">
        <MediaQuery minWidth={breakpoints['sm-plus']}>
          <img open className="full-page__girl" src={girlImage} alt="" />

          <div open className="full-page__decoration">
            <span />
            <span />
            <span />
          </div>
          <FixedLinks firstSectionIsActive webview />
        </MediaQuery>
        <MainSection webview />
      </div>
    </div>
  );
};

export default WebviewToSafari;
