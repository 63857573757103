import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider, observer } from 'mobx-react';
import { ConfigProvider } from 'antd';
import { create } from 'mobx-persist';
import ruRU from 'antd/es/locale/ru_RU';
import './assets/styles/index.less';
import './assets/styles/main.less';
import './assets/css/templates.css';

import AppStore from './store/AppStore';
import UserStore from './store/UserStore';
import CommonStore from './store/CommonStore';
import NotificationStore from './store/NotificationStore';
import QuizStore from './store/QuizStore';
import PaymentStore from '@store/PaymentStore';

import ErrorBoundary from './components/hoc/ErrorBoundary';
import NotFound from './containers/NotFound';
import HomeContainer from './containers/HomeContainer';
import ContentPagesContainer from './containers/ContentPagesContainer';
import { QuestionsPage } from '@components/QuizPage';
import { VerifyPage } from '@components/VerifyPage';
import { OuterVerifyPage } from '@components/OuterVerifyPage';
import { LandingQuizPage } from '@components/LandingQuizPage';
import ScrollToTop from './components/ScrollToTop';
import NewsDetailsContainer from '@/containers/NewsDetailsContainer';
import CheckoutContainer from '@/containers/CheckoutContainer';
import FakeReg from '@components/FakeReg';
import CancelPageContainer from '@/containers/CancelPageContainer';
import { translate } from '@/utils';
import RedirectRouteToLang from '@components/RedirectRouteToLang';
import RoutesLang from '@utils/RoutesLang';
import Routes from '@utils/Routes';
import { Helmet } from 'react-helmet';

const hydrate = create({});
hydrate('QuizStore', QuizStore);

const stores = {
  appStore: AppStore,
  userStore: UserStore,
  notificationStore: NotificationStore,
  commonStore: CommonStore,
  quizStore: QuizStore,
  paymentStore: PaymentStore,
};

@observer
class App extends Component {
  componentWillUnmount() {
    AppStore.clearPing();
  }

  async componentDidMount() {
    await AppStore.init();
  }

  componentDidUpdate() {
    if (!AppStore.is_auth && document.querySelector('#jivo-iframe-container')) {
      document.querySelectorAll('jdiv')[0].style.display = 'none';
    } else if (document.querySelectorAll('jdiv')[0]) {
      document.querySelectorAll('jdiv')[0].style.display = '';
    }
  }

  render() {
    return (
      <>
        {window.location.hostname === 'weightglow.com' && (
          <div className="bg_plug">We are coming soon</div>
        )}
        <Helmet>
          <title>
            {translate('META_TITLE').replace(
              /{{project_name}}/g,
              AppStore.app_info?.project_name || 'Vean',
            )}
          </title>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={AppStore.app_info?.project_name || 'Vean'} />
          <meta
            property="og:description"
            content="Ready to stop dieting? Learn to eat mindfully. Psychology is the key to lasting change."
          />
          <meta property="og:url" content={window.location.origin} />
          <meta property="og:image" content={`${window.location.origin}/images/why.jpg`} />
          <meta property="og:site_name" content={window.location.hostname} />
          <meta property="og:locale" content={AppStore.ln === 'pb' ? 'pt' : AppStore.ln} />
          <meta
            name="description"
            content={translate('META_DESCRIPTION').replace(
              /{{project_name}}/g,
              AppStore.app_info?.project_name || 'Vean',
            )}
          />
          <link
            rel="alternate"
            hrefLang="x-default"
            href={window.location.href.replace(`/${AppStore.ln}/`, '/en/')}
          />
          {AppStore.languagesCodes.map((countryCode) => {
            return (
              <link
                key={countryCode}
                rel="alternate"
                hrefLang={countryCode === 'gr' ? 'el' : countryCode === 'pb' ? 'pt' : countryCode}
                href={window.location.href.replace(`/${AppStore.ln}/`, `/${countryCode}/`)}
              />
            );
          })}
        </Helmet>

        <Provider {...stores}>
          <>
            {AppStore.ready ? (
              <Router>
                <ScrollToTop />
                <ConfigProvider locale={ruRU}>
                  <ErrorBoundary>
                    {AppStore.is_auth ? (
                      <>
                        <Switch>
                          <Route exact path="/verify" render={() => <VerifyPage />} />
                          <Redirect exact to={`/verify?token=${localStorage.getItem('token')}`} />
                          <Route
                            exact
                            path="/cancel-subscription"
                            render={() => <CancelPageContainer />}
                          />
                          <Route
                            exact
                            path="/cancel-success"
                            render={() => <CancelPageContainer success />}
                          />
                        </Switch>
                      </>
                    ) : (
                      <Switch>
                        <RedirectRouteToLang
                          exact
                          path={[RoutesLang.homeLang, Routes.home]}
                          component={HomeContainer}
                        />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.verify}
                          component={VerifyPage}
                        />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.outerVerify}
                          component={OuterVerifyPage}
                        />
                        <RedirectRouteToLang
                          exact
                          path={[
                            RoutesLang.privacy,
                            RoutesLang.terms,
                            RoutesLang.contacts,
                            RoutesLang.hipaaNotice,
                            RoutesLang.ccpa,
                            RoutesLang.researchPolicy,
                            RoutesLang.cof,
                            RoutesLang.refund,
                          ]}
                          component={() => <ContentPagesContainer authorized={false} />}
                        />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.quiz}
                          component={QuestionsPage}
                        />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.quizId}
                          component={QuestionsPage}
                        />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.landingQuizId}
                          component={LandingQuizPage}
                        />
                        <Redirect exact from={RoutesLang.courses} to={RoutesLang.homeLang} />
                        <Redirect exact from={RoutesLang.profile} to={RoutesLang.homeLang} />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.blogDetailsNewsId}
                          component={() => <NewsDetailsContainer authorized={false} />}
                        />
                        <Redirect exact from={RoutesLang.blogDetails} to={RoutesLang.homeLang} />
                        <Redirect exact from={RoutesLang.blog} to={RoutesLang.homeLang} />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.checkout}
                          component={CheckoutContainer}
                        />
                        <RedirectRouteToLang exact path={RoutesLang.fakeReg} component={FakeReg} />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.cancelSubscription}
                          component={CancelPageContainer}
                        />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.cancelSuccess}
                          component={CancelPageContainer}
                        />
                        <RedirectRouteToLang
                          exact
                          path={RoutesLang.notFound}
                          component={() => (
                            <NotFound user_is_authorized={false} header={false} footer={false} />
                          )}
                        />
                        <RedirectRouteToLang
                          component={() => (
                            <NotFound user_is_authorized={false} header={false} footer={false} />
                          )}
                        />
                      </Switch>
                    )}
                  </ErrorBoundary>
                </ConfigProvider>
              </Router>
            ) : null}
          </>
        </Provider>
      </>
    );
  }
}

export default App;
