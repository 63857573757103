import React from 'react';
import Layout from '@components/Layout';
import CancelPage from '@components/CancelPage';

const CancelPageContainer = ({ success }) => {
  return (
    <Layout layoutClassName="content-page" footer={false} with_container={false}>
      <CancelPage success={success} />
    </Layout>
  );
};

export default CancelPageContainer;
