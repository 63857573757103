import React, { Component } from 'react';
import MediaQuery from 'react-responsive/src/Component';
import breakpoints from '@utils/breakpoints';
import { debounce } from 'lodash';
import girlImage from '@images/main-girl-mobile.png';
import FixedLinks from './FixedLinks';
import { translate } from '@/utils';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { LangDynamicLink } from '@utils/LinkLangRoutes';

@inject('appStore')
@observer
class MainSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstSectionIsActive: null,
    };
  }
  componentDidMount() {
    if (window.innerWidth <= parseInt(breakpoints.xs)) {
      document.addEventListener('scroll', this.setScrollDependentVarsStatus);
      this.setState({ firstSectionIsActive: true });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.setScrollDependentVarsStatus);
    this.setState({ firstSectionIsActive: null });
  }

  setScrollDependentVarsStatus = debounce(() => {
    const windowHeight = window.innerHeight;

    if (this.state.firstSectionIsActive && window.scrollY > windowHeight) {
      this.setState({ firstSectionIsActive: false });
    }
    if (window.scrollY < windowHeight + 100) {
      this.setState({ firstSectionIsActive: true });
    }
  }, 50);

  render() {
    return (
      <>
        <div className="main-section">
          <h1
            className="main-section__label"
            dangerouslySetInnerHTML={{
              __html: translate('MAIN_SECTION_TITLE'),
            }}
          />
          <div className="main-section__note">
            {translate('PICK_AN_OPTION')}
            <button className="main-section__move-to-payement orange-button-main">
              <NavLink to={LangDynamicLink('quiz')} className="fixed-links__item-link" />
              {translate('GET_STARTED')}
            </button>
          </div>
          <MediaQuery maxWidth={breakpoints.xs}>
            <div className="main-section__decoration">
              <img src={girlImage} alt="" />
              <span />
            </div>
            <FixedLinks
              webview={this.props.webview}
              firstSectionIsActive={this.state.firstSectionIsActive}
            />
          </MediaQuery>
        </div>
      </>
    );
  }
}

export default MainSection;
