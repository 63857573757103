import { api } from '@/utils';

export const checkoutPagePayment = async (data) => api(`/subscriptions`, data, 'POST');

export const applicationPayment = async (code, data) =>
  api(`/open_test/${code}/subscriptions/mobile`, data, 'POST');

export const paypalPayment = async (code, data) =>
  api(`/open_test/${code}/subscriptions/paypal`, data, 'POST');

export const withoutQuizPaypalPayment = async (data) => api(`/subscriptions/paypal`, data, 'POST');

export const getQuizVideo = async (code) => {
  try {
    const res = await api(`/open_test/${code}/video-parts`, {}, 'GET');
    return res.data;
  } catch (e) {
    throw e;
  }
};
