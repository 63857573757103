import React, { useState, useCallback } from 'react';

import { translate } from '@utils';
import colorsVars from '@utils/colorsVars';

import RenderSvg from '@components/RenderSvg';
import lock from '@icons/lock.svg';
import signIn from '@icons/sign-in_icon.svg';

import Input from '@elements/Input';
import { toast } from '@/utils';

import contentPropTypes from './contentPropTypes';
import { recover } from '@/actions/auth';
import isEmail from 'validator/lib/isEmail';
import { gaMirror } from '@utils/metrics';

const RestorePasswordForm = ({ setLoading, contentKeys, setContentName }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const sendRestore = async () => {
    setLoading(true);

    try {
      await recover(email);
      setContentName(contentKeys.success);
    } catch (e) {
      setEmailError(true);
      toast(translate('ERROR_OCCURRED'));
    }

    setLoading(false);
  };

  const handleChangeEmail = useCallback(
    (val) => {
      setEmailError(false);
      setEmail(val);
    },
    [email],
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!email.trim() || !isEmail(email, [{ require_display_name: true }])) {
        toast(translate('APP_WRONG_EMAIL'));
        return setEmailError(true);
      }

      gaMirror({
        event: 'forgot_password_recovery_mail_sent',
        category: 'forgot_password_popup',
        action: 'click',
        label: 'forgot_password_recovery_mail_sent',
      });
      sendRestore();
    },
    [email],
  );

  return (
    <>
      <div className="home-login__title">
        <RenderSvg path={lock} size={28} color={colorsVars.colorMinor} />
        <span>{translate('FORGOT_PASSWORD')}</span>
      </div>
      <p className="home-login__tip">{translate('RESTORE_PASSWORD_TIP')}</p>
      <form className="home-login__restore-form" onSubmit={handleSubmit}>
        <Input
          title={translate('RESTORE_ENTER_EMAIL')}
          value={email}
          onChange={(e) => handleChangeEmail(e)}
          showError={emailError}
        />
        <button type="submit" className="orange-button button-with-arrow">
          {translate('RESTORE_SUBMIT')}
        </button>
      </form>
      <div className="home-login__divider" />
      <button
        type="button"
        className="grey-button button-with-left-icon home-login__back-button"
        onClick={() => setContentName(contentKeys.login)}
      >
        <RenderSvg path={signIn} size={14} color={colorsVars.colorMinor} />
        {translate('BACK_TO_SIGN_IN')}
      </button>
    </>
  );
};

RestorePasswordForm.propTypes = contentPropTypes;

export default RestorePasswordForm;
