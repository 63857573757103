import amplitude from 'amplitude-js';
import moment from 'moment';
import Cookies from 'js-cookie';

const metricsOn = Number(process.env.REACT_APP_METRICS_ENABLED);
const amplitudeKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
const amplitudeInstance = (metricsOn && amplitudeKey && amplitude.getInstance()) || null;
amplitudeInstance && amplitudeInstance.init(amplitudeKey);

const methodCheck = (method) => {
  if (metricsOn && amplitudeKey && amplitudeInstance) {
    return method;
  }
  // eslint-disable-next-line no-empty-function
  return () => {};
};

const bodyMetricsAdapter = (metricsData) => ({
  weightFirst: metricsData.weight_points[0]?.weight || '',
  waistFirst: metricsData.waist_circumference.first,
  hipsFirst: metricsData.hips_circumference.first,
  wristFirst: metricsData.wrist_circumference.first,
  neckFirst: metricsData.neck_circumference.first,
  weightLast: metricsData?.weight_points[metricsData?.weight_points?.length - 1]?.weight || '',
  waistLast: metricsData?.waist_circumference.last,
  hipsLast: metricsData?.hips_circumference.last,
  wristLast: metricsData?.wrist_circumference.last,
  neckLast: metricsData?.neck_circumference.last,
});

const firstLaunchDataAdapter = (quizData, email) => ({
  weightQuiz: quizData.weight || '',
  heightQuiz: quizData.height || '',
  idealWeightQuiz: quizData.idealWeight || '',
  ageQuiz: quizData.age || '',
  cohortDay: moment().dayOfYear(),
  cohortWeek: moment().week(),
  cohortMonth: moment().month() + 1,
  email,
  gid: Cookies.get('_ga'),
});

const finishedTasksCountAdapter = (courses) => {
  const count = courses.reduce((acc, curr) => acc + curr.progress.done, 0);
  return { taskFinished: count };
};

/**
 * Обработка параметров перед посыланием лида
 *
 * @param {string} name  - Название события
 * @param {object} data  - Данные события (объект ключ: значение)
 */
const sendAmplitudeLead = methodCheck((name, data) => {
  amplitudeInstance.logEvent(name, data);
});

/**
 * Обработка параметров перед посыланием лида
 *
 * @param {object} userProperties - кастомные значения свойств пользователя, в админке амплитюд отображаются в профиле пользователя (объект ключ: значение)
 */
const setAmplitudeUserProperties = methodCheck((userProperties) => {
  amplitudeInstance.setUserProperties(userProperties);
});

/**
 * Обработка параметров перед посыланием лида
 *
 * @param {string} email - мыло пользователя ставится как id пользователя
 */
const setAmplitudeUserID = methodCheck((email) => {
  amplitudeInstance.setUserId(email);
});

/**
 * Обработка параметров перед посыланием лида
 *
 * @param {object} metricsData - данные запроса getBodyMetricsData.
 */
const setAmplitudeUserBodyMetrics = methodCheck((metricsData) => {
  if (metricsData) {
    setAmplitudeUserProperties(bodyMetricsAdapter(metricsData));
    sendAmplitudeLead('update_user_parameters');
  }
});

/**
 * Обработка параметров перед посыланием лида
 *
 * @param {object} quizData - объект с ответами из квиза
 * @param {string} email - почта пользователя
 */
const sendAmplitudeFirstLaunch = methodCheck((quizData, email) => {
  if (quizData && email) {
    setAmplitudeUserID(email);
    setAmplitudeUserProperties(firstLaunchDataAdapter(quizData));
    sendAmplitudeLead('launch_first_time');
  }
});

/**
 * Обработка параметров перед посыланием лида
 *
 * @param {string} email - почта пользователя
 */
const sendAmplitudeSessionStart = methodCheck((email) => {
  if (email) {
    setAmplitudeUserID(email);
    setAmplitudeUserProperties({ gid: Cookies.get('_ga') });
    sendAmplitudeLead('session_start');
  }
});

/**
 * Обработка параметров перед посыланием лида
 *
 * @param {array} courses - массив со всеми курсами
 */
const setAmplitudeTaskFinishedCount = methodCheck((courses) => {
  if (courses?.length) {
    setAmplitudeUserProperties(finishedTasksCountAdapter(courses));
    sendAmplitudeLead('update_user_parameters');
  }
});

export {
  sendAmplitudeLead,
  setAmplitudeUserID,
  setAmplitudeUserBodyMetrics,
  sendAmplitudeFirstLaunch,
  sendAmplitudeSessionStart,
  setAmplitudeTaskFinishedCount,
};
