import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../Layout';
import { observer } from 'mobx-react';
import Spinner from '@components/Spinner';
import '../../assets/styles/quiz.less';
import useRouter from '@/hooks/useRouter';
import queryString from 'query-string';
import { useStores } from '@/store';

const OuterVerify = observer(() => {
  const { query } = useRouter();
  const { app_info } = useStores().appStore;

  useEffect(() => {
    const token = query.token;
    const lang = query.lang || localStorage.getItem('lang');
    delete query.lang;
    if (query && token) {
      window.location.href = `${app_info.cabinet_link}outer-verify?${queryString.stringify(
        query,
      )}&lang=${lang}`;
    }
  }, []);

  return (
    <Layout
      showBurger={false}
      showSignButtons={false}
      footer={false}
      user_is_authorized={false}
      layoutClassName="content-page"
    >
      <Spinner className="home-login__spinner" color="#f4955f" />
    </Layout>
  );
});

export const OuterVerifyPage = withRouter(OuterVerify);
