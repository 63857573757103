import React from 'react';
import GiftBlue from '@components/Checkout/GiftSection/GiftBlue';
import GiftOrange from '@components/Checkout/GiftSection/GiftOrange';

const GiftSection = ({ presentMode }) => {
  if (presentMode === 'blue') {
    return <GiftBlue />;
  }
  if (presentMode === 'orange') {
    return <GiftOrange />;
  }
  return null;
};

export default GiftSection;
