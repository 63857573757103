import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { toast, translate } from '@/utils';
import { useStores } from '@/store';
import { gaMirror, ymMirror } from '@utils/metrics';
import { sendAmplitudeFirstLaunch } from '@utils/amplitude';
import useRouter from '@/hooks/useRouter';

const PaymentBtns = ({
  allFields,
  currentTariff,
  openForm,
  gaEventCategory,
  setPayBtnEnabled,
  btnWithForm,
  setQuizHasEmailQuestion,
}) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const { state } = useStores().quizStore;
  const { checkoutPagePayment, quizCheckoutPayment } = useStores().paymentStore;
  const router = useRouter();

  const byOneClick = localStorage.getItem('abGpay');

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: currentTariff.currencyCode?.toLowerCase(),
        total: {
          label: currentTariff.trialText,
          amount: Number((currentTariff.trialPrice * 100).toFixed(0)),
        },
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
          btnWithForm && setPayBtnEnabled(pr);
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    paymentRequest?.on('paymentmethod', async (ev) => {
      await subscribe(ev);
    });
  }, [paymentRequest]);

  const subscribe = async (ev) => {
    try {
      let response;
      if (!allFields) {
        const needEmail = Boolean(localStorage.getItem('quizEmail'));
        response = await quizCheckoutPayment(ev.paymentMethod.id, !needEmail && ev.payerEmail);
      } else {
        response = await checkoutPagePayment(ev.paymentMethod.id, ev.payerEmail);
      }

      if (response.status === 'requires_action') {
        const authResponse = await stripe.confirmCardPayment(response.client_secret, {
          payment_method: ev.paymentMethod.id,
        });

        if (authResponse.error) {
          throw new Error(translate('CARD_DECLINED'));
        }
      }

      ev.complete('success');
      successSubscription(response.link, ev.payerEmail);
    } catch (e) {
      await ev.complete('fail');

      failedSubscription(e);
    }
  };

  const successSubscription = (link, email) => {
    gaMirror({
      event: 'start_trial',
      category: gaEventCategory,
      action: 'click',
      label: 'start_trial',
    });
    ymMirror('successPayment');
    sendAmplitudeFirstLaunch(state.survey.data, allFields ? email : state.survey.data.email);
    router.push(link);
  };

  const failedSubscription = (e) => {
    if (e.code === 422) {
      localStorage.removeItem('quizEmail');
      setQuizHasEmailQuestion && setQuizHasEmailQuestion(false);
      toast(translate('APP_EMAIL_REGISTERED'));
    } else {
      toast(e);
    }
    ymMirror('failedPayment');
    gaMirror({
      event: 'failed_payment',
      category: gaEventCategory,
      action: 'payment',
      label: 'failed_payment',
    });
  };

  const handleButtonClick = () => {
    if (paymentRequest && byOneClick) {
      paymentRequest.show();
    } else {
      openForm();
    }
  };

  return (
    <>
      {paymentRequest && !byOneClick && btnWithForm ? (
        <div className="payment-form__pay-btn payment-form__pay-btn_stripe">
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      ) : null}

      {!btnWithForm && (
        <div className="checkout__next">
          <button
            onClick={handleButtonClick}
            className="orange-button button-with-arrow checkout__button"
          >
            {currentTariff?.trialText}
          </button>
        </div>
      )}
    </>
  );
};

export default PaymentBtns;
