import React, { Component } from 'react';
import { translate } from '@/utils';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { LangDynamicLink } from '@utils/LinkLangRoutes';

@inject('appStore')
@observer
class AboutItem extends Component {
  render() {
    const { data, appStore } = this.props;
    return (
      <>
        <div className="about-item">
          <NavLink to={LangDynamicLink('quiz')} className="fixed-links__item-link" />
          <div className="about-item__inner">
            <img className="about-item__image" src={data.image} alt="" />
            <div className="about-item__label">{translate(data.labelKey)}</div>
          </div>
          <div className="about-item__text">
            {translate(data.textKey).replace(
              /{{project_name}}/g,
              appStore.app_info?.project_name || 'Vean',
            )}
          </div>
        </div>
      </>
    );
  }
}

export default AboutItem;
