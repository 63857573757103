import { observable, action, makeObservable } from 'mobx';
import AppStore from './AppStore';
import { loginUser, logOut, socialRegister } from '../actions/auth';
import {
  getUser,
  updateUser,
  updatePersonalData,
  getPaymentMethods,
  deletePaymentMethod,
  createNewPaymentCheckout,
  getSubscriptions,
  deleteSubscription,
  updatePassword,
} from '../actions/user';
import { cardAdapter, courseAdapter } from '@utils/adapters';
import NotificationStore from './NotificationStore';
import Cookies from 'js-cookie';
import { toast } from '@/utils';

class UserStore {
  @observable user = null;
  @observable paymentMethods = [];
  @observable subscriptions = [];

  @action get = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error(false);
      }
      const user = await getUser();
      this.user = user;
      Cookies.set('_GAUSERID', user.id, { expires: 365 });
      return user;
    } catch (e) {
      localStorage.removeItem('token');
    }
  };

  // TODO: remove usage in AppStore.js, after that you can delete
  @action updateUser = async (obj, silent = false, password) => {
    try {
      const res = updateUser(password, obj, silent);
      this.user = res.data || res;
      return res.data || res;
    } catch (e) {
      throw e;
    }
  };

  @action updatePassword = (oldPassword, newPassword) =>
    updatePassword(oldPassword, newPassword, this.user.id);

  @action updatePersonalData = async (user) => {
    await updatePersonalData(user);
    this.user = { ...this.user, ...user };
  };

  @action logIn = async (user, isSocial = false) => {
    try {
      if (isSocial) {
        user = await socialRegister(user);
      } else {
        user = await loginUser(user);
      }
      if (!user) {
        return null;
      }
      this.user = user;
      localStorage.setItem('token', user.token);
      localStorage.setItem('ln', user.lang);
      await AppStore.getLangs(true);
      await NotificationStore.get();
      AppStore.is_auth = true;
      return user.id;
    } catch (e) {
      throw e;
      // toast(e.message, 'danger', true);
    }
  };

  @action logInWithToken = (token) => {
    localStorage.setItem('token', token);
    AppStore.is_auth = true;
    this.get();
    AppStore.getAppInfo();
  };

  @action logOut = async () => {
    this.user = null;
    AppStore.is_auth = false;
    await logOut();
    localStorage.clear();
  };

  @action getPaymentMethods = () => {
    if (!this.paymentMethods.length) {
      return getPaymentMethods()
        .then(({ data }) => data.map((card) => cardAdapter(card)))
        .then((cards) => {
          this.paymentMethods = cards;
        })
        .catch((e) => {
          toast(e.message);
        });
    }
  };

  @action createPaymentMethod = async () => {
    const url = `${window.location.origin}/profile`;
    const { data } = await createNewPaymentCheckout(url, url);
    if (data.status !== 'init_finished_success') {
      throw new Error('Not finished yet');
    }
    if (!window.Stripe) {
      throw new Error('Stripe is not initialized');
    }

    return data.ps_checkout_id;
  };

  @action deletePaymentMethod = async (id) => {
    await deletePaymentMethod(id);
    this.paymentMethods = this.paymentMethods.filter((method) => method.id !== id);
  };

  @action getSubscriptions = () => {
    if (!this.subscriptions.length) {
      return getSubscriptions()
        .then(({ data }) => data.map((el) => courseAdapter(el)))
        .then((courses) => {
          this.subscriptions = courses;
        });
    }
  };

  @action deleteSubscription = async (id) => {
    const token = localStorage.getItem('token');
    await deleteSubscription(token, this.user.lang, id);
    this.subscriptions = this.subscriptions.filter((sub) => sub.id !== id);
  };

  constructor() {
    makeObservable(this);
  }
}

export default new UserStore();
