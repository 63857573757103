import { observable, action, makeObservable } from 'mobx';
import { getPlatform, toast, translate } from '../utils';
import { LangDynamicLink } from '@utils/LinkLangRoutes';

class CommonStore {
  @observable fullPageSlideIndex = null;
  @observable fullPageSliderWheelEnabled = true;
  @observable stopHomePageWheel = false;
  @observable appRoutes = [
    {
      route: '/quiz',
      translateKey: 'QUIZ',
    },
  ];
  @observable menuAnchorsArr = [
    {
      slideIndex: 0,
      sectionName: 'full-page__slide_main',
      translateKey: 'HOME',
    },
    {
      slideIndex: 1,
      sectionName: 'full-page__slide_about',
      translateKey: 'ABOUT',
    },
    // {
    //   slideIndex: 2,
    //   sectionName: 'full-page__slide_app',
    //   translateKey: 'OUR_APP',
    // },
    {
      slideIndex: 2,
      sectionName: 'full-page__slide_reviews',
      translateKey: 'REVIEWS',
    },
    {
      slideIndex: 3,
      sectionName: 'full-page__slide_blog',
      translateKey: 'BLOG',
    },
    {
      slideIndex: 4,
      sectionName: 'full-page__slide_tariff',
      translateKey: 'TARIFF',
    },
  ];
  @observable menuRoutesArr = [
    {
      route: () => LangDynamicLink('contacts'),
      translateKey: 'CONTACT',
    },
    {
      route: () => LangDynamicLink('privacy'),
      translateKey: 'PRIVACY',
    },
    {
      route: () => LangDynamicLink('terms'),
      translateKey: 'TERMS',
    },
    {
      route: () => LangDynamicLink('hipaa-notice'),
      translateKey: 'HIPAA',
    },
    {
      route: () => LangDynamicLink('ccpa'),
      translateKey: 'CCPA',
    },
    {
      route: () => LangDynamicLink('research-policy'),
      translateKey: 'RESEARCH',
    },
    {
      route: () => LangDynamicLink('cof'),
      translateKey: 'COF_POLICY',
    },
    {
      route: () => LangDynamicLink('refund'),
      translateKey: 'REFUND_POLICY',
    },
  ];
  @observable androidAppLink = 'https://play.google.com/store/apps/details?id=perfect.body.app';
  @observable iosAppLink = '/';
  @observable showLoginPopUp = false;
  @observable verifyActiveCourse = null;

  @action setFullPageSlideIndex = (index) => {
    this.fullPageSlideIndex = index;
  };

  @action setFullPageSliderWheelStatus = (status) => {
    this.fullPageSliderWheelEnabled = status;
  };

  @action getCurrentDeviceAppLink = () => {
    switch (getPlatform()) {
      case 'apple':
        return '';
      // return this.iosAppLink;
      case 'Android':
        return this.androidAppLink;
      default:
        return '';
    }
  };

  @action closeLogInPopUp = () => {
    this.showLoginPopUp = false;
  };

  @action toggleLogInPopUp = () => {
    this.showLoginPopUp = !this.showLoginPopUp;
  };

  @action showAppMessage = (e) => {
    e.preventDefault();
    toast(translate('APP_COMING'));
  };

  @action setStopHomePageWheel = (status) => {
    this.stopHomePageWheel = status;
  };

  @action setVerifyActiveCourse = (value) => {
    this.verifyActiveCourse = value;
  };

  constructor() {
    makeObservable(this);
  }
}

export default new CommonStore();
