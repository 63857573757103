import React, { useEffect, useState } from 'react';
import RenderSvg from './RenderSvg';
import arrow_down from '../assets/icons/arrow_down2.svg';
import arrow_up from '../assets/icons/arrow_up.svg';

const ScrollArrow = () => {
  const [showScrollArrow, setShowScrollArrow] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollArrow(window.pageYOffset <= 800);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {showScrollArrow ? (
        <button className="scroll-arrow" onClick={scrollToBottom}>
          <RenderSvg size={32} path={arrow_down} />
        </button>
      ) : (
        <button className="scroll-arrow" onClick={scrollToTop}>
          <RenderSvg size={32} path={arrow_up} />
        </button>
      )}
    </>
  );
};

export default ScrollArrow;
