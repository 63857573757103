/* eslint-disable no-lonely-if */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import * as Survey from 'survey-react';
import { QUIZ_JSONS, METRIC_SYSTEM_QUESTIONS, EMAIL_PHONE_QUESTION } from '../QuizJSON';
import {
  LANDING_QUIZ_JSON,
  LANDING_QUIZ_DURATION_JSON,
} from '../../LandingQuizPage/LandingQuizJSON';
import { PRICE_DATA } from '../Payment/priceData';
import { gaMirror } from '@/utils/metrics';
import { translate } from '@utils';

Survey.StylesManager.applyTheme('bootstrap');
Survey.surveyLocalization.locales.ro.noneItemText = 'Niciuna';
Survey.surveyLocalization.locales.es.requiredError = 'Por favor, contesta la pregunta.';
Survey.surveyLocalization.locales.hr.requiredError = 'Molimo vas odgovorite na pitanje.';
Survey.surveyLocalization.locales.hr.noneItemText = 'Ništa';
Survey.surveyLocalization.locales.bg.noneItemText = 'Нито едно';
Survey.surveyLocalization.locales.pl.noneItemText = 'Nie';
Survey.surveyLocalization.locales.la = Survey.surveyLocalization.locales.es;
Survey.surveyLocalization.locales.pb = Survey.surveyLocalization.locales.pt;
Survey.surveyLocalization.locales.sr = { ...Survey.surveyLocalization.locales.hr };
Survey.surveyLocalization.locales.sr.requiredError = 'Odgovorite na pitanje.';
Survey.surveyLocalization.locales.sr.otherRequiredError = 'Unesite drugu vrijednost.';
Survey.surveyLocalization.locales.sr.invalidEmail = 'Molimo Vas da unesete validnu email adresu.';
Survey.defaultBootstrapCss.navigationButton = 'btn btn-green';
Survey.surveyLocalization.locales.vi = { ...Survey.surveyLocalization.locales.en };
Survey.surveyLocalization.locales.vi.noneItemText = 'Không';
Survey.surveyLocalization.locales.vi.requiredError = 'Trả lời câu hỏi.';
Survey.surveyLocalization.locales.vi.otherRequiredError = 'Nhập giá trị khác.';
Survey.surveyLocalization.locales.vi.invalidEmail = 'Vui lòng nhập một địa chỉ email hợp lệ.';
Survey.surveyLocalization.locales.vi.numericMinMax =
  'Giá trị ít nhất phải là {1} và nhiều nhất là {2}';
Survey.surveyLocalization.locales.sk = { ...Survey.surveyLocalization.locales.en };
Survey.surveyLocalization.locales.sk.noneItemText = 'Nič z toho';
Survey.surveyLocalization.locales.sk.requiredError = 'Odpovedzte prosím na otázku.';
Survey.surveyLocalization.locales.sk.otherRequiredError = 'Zadajte inú hodnotu.';
Survey.surveyLocalization.locales.sk.invalidEmail = 'Prosím zadajte platnú emailovú adresu.';
Survey.surveyLocalization.locales.sk.numericMinMax =
  '„Hodnota“ by mala byť aspoň {1} a maximálne {2}';
Survey.surveyLocalization.locales.sl = { ...Survey.surveyLocalization.locales.en };
Survey.surveyLocalization.locales.sl.noneItemText = 'Nobena';
Survey.surveyLocalization.locales.sl.requiredError = 'Prosim, odgovorite na vprašanje';
Survey.surveyLocalization.locales.sl.otherRequiredError = 'Vnesite drugačno vrednost.';
Survey.surveyLocalization.locales.sl.invalidEmail = 'Vnesite veljaven elektronski naslov.';
Survey.surveyLocalization.locales.sl.numericMinMax =
  '"Vrednost" mora biti najmanj {1} in največ {2}';

let oldEmail = '';

export const SurveyComponent = ({ quizStore, appStore }) => {
  const [surveyLocale, setSurveyLocale] = useState(appStore.ln || '');
  const history = useHistory();
  const paramId = useParams().id || null;
  const { state, changeQuizData, getQuizAnswers, generateUniqueId, sendChangeAnswers } = quizStore;
  const quizHasEmailQuestion = localStorage.getItem('quizEmail');

  useEffect(() => {
    setSurveyLocale(appStore.ln);
  }, [appStore.ln]);

  const survey = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * QUIZ_JSONS.length);
    const cachedSurveyIndex = localStorage.getItem('savedRandomIndex');
    let surveyIndex = cachedSurveyIndex || 0;
    const abQuiz = localStorage.getItem('ab');
    if (abQuiz && !cachedSurveyIndex) {
      if (abQuiz === 'big_small_quiz') {
        surveyIndex = randomIndex;
      }
      if (abQuiz === 'big_quiz') {
        surveyIndex = 1;
      }
    } else {
      surveyIndex = Number(cachedSurveyIndex) || 0;
    }
    if (!cachedSurveyIndex) {
      gaMirror({
        event: 'QUIZ_STARTED',
        category: `surveyData${surveyIndex + 1}`,
        action: 'viewContent',
        label: 'QUIZ_STARTED',
      });
    }

    localStorage.setItem('savedRandomIndex', surveyIndex);

    const randomSurveyObj = QUIZ_JSONS[surveyIndex];
    if (localStorage.getItem('ms') === 'metr') {
      randomSurveyObj[`surveyData${surveyIndex + 1}`].pages[1] =
        METRIC_SYSTEM_QUESTIONS.surveyDataMetric.pages[0];
      randomSurveyObj[`surveyData${surveyIndex + 1}`].pages[2] =
        METRIC_SYSTEM_QUESTIONS.surveyDataMetric.pages[1];
    }
    if (localStorage.getItem('phone') === 'true' && quizHasEmailQuestion) {
      if (surveyIndex === 0) {
        randomSurveyObj.surveyData1.pages[11] = EMAIL_PHONE_QUESTION.emailPhone.pages[0];
      }
      if (surveyIndex === 1) {
        randomSurveyObj.surveyData2.pages[20] = EMAIL_PHONE_QUESTION.emailPhone.pages[0];
      }
    }

    if (!quizHasEmailQuestion) {
      if (surveyIndex === 0) {
        randomSurveyObj.surveyData1.pages.forEach((item, i) => {
          if (item.name === 'page21') {
            randomSurveyObj.surveyData1.pages.splice(i, 1);
          }
        });
      }
      if (surveyIndex === 1) {
        randomSurveyObj.surveyData2.pages.forEach((item, i) => {
          if (item.name === 'page21') {
            randomSurveyObj.surveyData2.pages.splice(i, 1);
          }
        });
      }
    }

    let surveyKey = Object.keys(randomSurveyObj)[0];
    let surveyValue = Object.values(randomSurveyObj)[0];

    if (localStorage.getItem('b') !== 'y') {
      surveyValue.pages = surveyValue.pages.filter((question) => {
        return !question.abTestB;
      });
    }

    quizStore.setCurrentQuizPages(surveyValue.pages);

    let newSurvey = new Survey.Model(surveyValue);

    if (paramId) {
      // get
      changeQuizData('loader', true);
      getQuizAnswers(paramId, history).then((item) => {
        changeQuizData('loader', false); // the war is due to this action
        if (item && item.survey) {
          surveyKey = item.quizId;
          QUIZ_JSONS.forEach((item) => {
            if (surveyKey === Object.keys(item)[0]) {
              surveyValue = Object.values(item)[0];
            }
          });
          newSurvey = new Survey.Model(surveyValue);

          newSurvey.data = item.survey.data;
          newSurvey.currentPageNo = item.survey.currentPageNo;
          oldEmail = item.oldEmail;

          history.push({ pathname: '/quiz', search: window.location.search });
        }
      });
    } else {
      // create and refresh
      let userPrice = PRICE_DATA[Math.floor(Math.random() * PRICE_DATA.length)];

      if (state.quizId) {
        surveyKey = state.quizId;

        // quiz object
        QUIZ_JSONS.forEach((item) => {
          if (surveyKey === Object.keys(item)[0]) {
            surveyValue = Object.values(item)[0];
          }
        });
        // landing quiz object
        LANDING_QUIZ_JSON.forEach((item) => {
          if (surveyKey === Object.keys(item)[0]) {
            surveyValue = Object.values(item)[0];
            userPrice = '9.90';
          }
        });
        // landing quiz object
        LANDING_QUIZ_DURATION_JSON.forEach((item) => {
          if (surveyKey === Object.keys(item)[0]) {
            surveyValue = Object.values(item)[0];
            userPrice = '9.90';
          }
        });

        oldEmail = state.oldEmail;
      } else {
        changeQuizData('quizId', surveyKey);
      }

      !state.userPrice && changeQuizData('userPrice', userPrice);
      !state.uniqueIds.code && generateUniqueId();
      newSurvey = new Survey.Model(surveyValue);
    }

    window.survey = newSurvey;
    window.survey.locale = surveyLocale;

    return newSurvey;
  }, [surveyLocale]);

  return useMemo(() => {
    // onUpdateQuestionCssClasses
    survey.onUpdateQuestionCssClasses.add((survey, options) => {
      const classes = options.cssClasses;

      classes.mainRoot += ' survey-mainRoot';
      classes.title += ' survey-title';
      classes.label += ' survey-label';

      if (options.question.getType() === 'checkbox') {
        classes.item += ' survey-checkboxItem';
        classes.itemChecked += ' survey-itemCheckedCheckbox';
      }
      if (options.question.getType() === 'text') {
        classes.mainRoot += ' survey-textRoot';
      }
      if (options.question.getType() === 'radiogroup') {
        classes.item += ' survey-radioItem';
        classes.itemChecked += ' survey-itemCheckedRadio';
      }
      if (options.question.getType() === 'rating') {
        classes.item += ' survey-ratingItem';
        classes.selected += ' survey-ratingChecked';
      }
      if (options.question.getType() === 'dropdown') {
        classes.mainRoot += ' survey-textRoot';
      }
    });

    // onUpdatePageCssClasses
    survey.onUpdatePageCssClasses.add((survey, options) => {
      const classes = options.cssClasses;

      classes.page.title += ' survey-pageTitle';
      classes.page.description += ' survey-pageDescription';
      classes.page.root += ' survey-pageRoot';
    });

    survey.css.progressBar += ' survey-progressBar';
    survey.css.radiogroup.column += ' survey-radioColumn';
    survey.css.root += ' survey-container';
    survey.css.progress += ' survey-header';
    survey.css.footer += ' survey-footer';

    // onAfterRenderQuestion
    survey.onAfterRenderQuestion.add((survey) => {
      changeQuizData('survey', {
        currentPageNo: survey.currentPageNo,
        data: survey.data,
      });
      survey.data && sendChangeAnswers();

      const bodyMassIndex = document.getElementById('body_mass_index');
      const email = 'email' in survey.data ? survey.data.email : '';

      if (isEmail(email) && email !== oldEmail) {
        oldEmail = email;
        changeQuizData('oldEmail', email);
      }

      if (bodyMassIndex) {
        const age = parseInt(survey.data.age);
        const gender = survey.data.gender;
        const idealWeight = survey.data.idealWeight;

        bodyMassIndex.innerText = bodyMassIndex.innerText
          .replace('%gender%', translate(gender))
          .replace('%age%', age)
          .replace(
            ' %weightUnit%',
            ` ${translate(localStorage.getItem('ms') === 'metr' ? 'KG' : 'LB')}`,
          )
          .replace(/%idealWeight%/g, idealWeight);
      }
    });

    // onComplete
    survey.onComplete.add((survey) => {
      changeQuizData('survey', {
        currentPageNo: survey.currentPageNo,
        data: survey.data,
      });
      survey.data && sendChangeAnswers();

      const email = 'email' in survey.data ? survey.data.email : '';

      if (isEmail(email) && email !== oldEmail) {
        oldEmail = email;
        changeQuizData('oldEmail', email);
        sendChangeAnswers({});
      }

      changeQuizData('showSurvey', false);
    });

    if (state.survey.data) {
      survey.data = state.survey.data;
      survey.currentPageNo = state.survey.currentPageNo;
    }

    return <Survey.Survey model={survey} />;
  }, []);
};
