import { getPercent } from '@actions/courses';
import { addHostToPath } from '@utils';
import moment from 'moment';

const formatDate = (date) => moment.utc(date).local().format('DD.MM.YYYY');

export const courseAdapter = (item) => ({
  id: item.id,
  imageSrc: addHostToPath(item?.course?.bg_img) || '',
  name: item?.course?.json?.name || '',
  price: item?.price || '0',
  percent: getPercent(item?.course?.progress?.all || 0, item?.course?.progress?.done || 0),
  startedAt: formatDate(item?.course?.started_at || ''),
  lastPaymentAt: formatDate(item?.last_payment_at || ''),
  nextPaymentAt: formatDate(item?.next_payment_at || ''),
  status: item.status,
});
