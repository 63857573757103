import React, { useEffect } from 'react';
import Spinner from '@components/Spinner';
import colorsVars from '@utils/colorsVars';
import { useStores } from '@/store';
import useRouter from '@/hooks/useRouter';
import queryString from 'query-string';

const CancelPage = ({ success }) => {
  const { query } = useRouter();
  const { app_info } = useStores().appStore;

  useEffect(() => {
    if (success) {
      window.location.href = `${app_info.cabinet_link}cancel-success?${queryString.stringify(
        query,
      )}`;
    } else {
      window.location.href = `${app_info.cabinet_link}cancel-subscription?${queryString.stringify(
        query,
      )}`;
    }
  }, []);

  return (
    <div className="cancel-page">
      <Spinner color={colorsVars.colorBright} className="cancel-page__spinner" />
    </div>
  );
};

export default CancelPage;
