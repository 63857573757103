import { api, translate } from '../utils';
import moment from 'moment';

export const getNewsList = async (page = 1, filters) => {
  try {
    return await api('/news', { limit: 12, page, ...filters }, 'GET');
  } catch (e) {
    throw e;
  }
};

export const getNews = async (id) => {
  try {
    const res = await api('/news', { id }, 'GET');
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getPublicNewsList = async (page = 1, filters) => {
  try {
    return await api('/news/public', { limit: 15, page, ...filters }, 'GET');
  } catch (e) {
    throw e;
  }
};

export const getPublicNews = async (id) => {
  try {
    const res = await api(`/news/public/${id}`, {}, 'GET');
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getNewsDate = (date, count) => {
  date = moment(date).startOf('day');
  const startOfToday = moment().startOf('day');
  const diff = date.diff(startOfToday, 'days');
  if (count) {
    return diff;
  }
  if (diff === 0) {
    return translate('Today');
  }
  if (diff === -1) {
    return translate('Yesterday');
  }
  return moment(date).format('DD.MM.YYYY');
};
