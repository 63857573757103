import React from 'react';
import colorsVars from '@utils/colorsVars';
import RenderSvg from '@components/RenderSvg';
import { withRouter } from 'react-router';

import watch from '@icons/watch.svg';
import spreadIcon from '@icons/spread-icon.svg';
import { LangDynamicLink } from '@utils/LinkLangRoutes';

const NewsItem = ({ news, newsList, custom, notNameLimit, ...props }) => {
  const openNews = (currentNews) => {
    return props.history.push({
      pathname: LangDynamicLink(`blog/details/${news.id}`),
      state: {
        currentNews,
        newsList,
      },
    });
  };
  const DateBlog = {
    3: { date: '28.04.2021' },
    4: { date: '14.09.2021' },
    5: { date: '27.02.2022' },
    6: { date: '12.11.2022' },
    7: { date: '07.04.2023' },
    8: { date: '21.06.2023' },
    9: { date: '09.10.2023' },
    10: { date: '17.11.2023' },
  };

  return (
    <div className={`news-item ${custom ? 'news-item_custom' : ''}`}>
      <div className={`news-item__date ${custom ? 'news-item__date_custom' : ''}`}>
        <RenderSvg path={watch} size={16} color={colorsVars.colorAccent} />
        <span>{DateBlog[news.id].date || ''}</span>
      </div>
      <h2
        className={`news-item__name ${custom ? 'news-item__name_custom' : ''} ${
          notNameLimit ? 'news-item__name_no-limit' : ''
        }`}
      >
        {news.name}
      </h2>
      <h3 className={`news-item__description ${custom ? 'news-item__description_custom' : ''}`}>
        {news.description}
      </h3>
      <div className={`news-item__spread ${custom ? 'news-item__spread_custom' : ''}`}>
        <span
          onClick={() => {
            openNews(news);
          }}
          className="news-item__link"
        >
          <RenderSvg path={spreadIcon} widthSize={14} heightSize={2} />
        </span>
      </div>
    </div>
  );
};

export default withRouter(NewsItem);
