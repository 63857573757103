/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-vars,no-restricted-syntax */
const metricsOn = Number(process.env.REACT_APP_METRICS_ENABLED);
const ymId = process.env.REACT_APP_YM_ID;

const fbCache = [];
let fbTimer;
const fbWaiter = () => {
  if (metricsOn && !fbTimer) {
    fbTimer = setInterval(() => {
      if (window.fbq) {
        clearInterval(fbTimer);
        clearTimeout(timerDelay);
        for (const event of fbCache) {
          window.fbq('track', ...event);
        }
      }
    }, 500);
    const timerDelay = setTimeout(() => {
      clearInterval(fbTimer);
    }, 5000);
  }
};
// eslint-disable-next-line func-names
const fbMirror = function () {
  if (!window.fbq) {
    fbTimer || fbWaiter();
    fbCache.push([...arguments]);
  } else {
    window.fbq('track', ...arguments);
  }
};

const gaMirror = (event) => {
  window.dataLayer || (window.dataLayer = []);
  window.dataLayer.push(event);
};

const gtagCache = [];
let gtagTimer;
const gTagWaiter = () => {
  if (metricsOn && !gtagTimer) {
    gtagTimer = setInterval(() => {
      if (window.gtag) {
        clearInterval(gtagTimer);
        clearTimeout(timerDelay);
        for (const event of gtagCache) {
          window.gtag(...event);
        }
      }
    }, 500);
    const timerDelay = setTimeout(() => {
      clearInterval(gtagTimer);
    }, 5000);
  }
};

// eslint-disable-next-line func-names
const gTagMirror = function () {
  if (!window.gtag) {
    gtagTimer || gTagWaiter();
    gtagCache.push([...arguments]);
  } else {
    window.gtag(...arguments);
  }
};

const sendOrder = ({ subscriptionId, price, courseId, currency }) =>
  gaMirror({
    ecommerce: {
      currencyCode: currency,
      purchase: {
        actionField: {
          id: subscriptionId,
          affiliation: 'vean',
          revenue: price, // сумма заказа
        },
        products: [
          {
            name: 'Vean Health App',
            id: courseId,
            price,
            quantity: 1,
          },
        ],
      },
    },
    eventCategory: 'ecommerce',
    eventAction: 'purchase',
    eventLabel: subscriptionId,
    paymentMethod: 'online',
    orderType: 'checkout',
    event: 'gaecomm',
    сoursePrice: price,
  });

const ymCache = [];
let timer;
const ymWaiter = () => {
  if (ymId && metricsOn && !timer) {
    timer = setInterval(() => {
      if (window.ym) {
        clearInterval(timer);
        clearTimeout(timerDelay);
        for (const target of ymCache) {
          window.ym(ymId, 'reachGoal', target);
        }
      }
    }, 500);
    const timerDelay = setTimeout(() => {
      clearInterval(timer);
    }, 5000);
  }
};
const ymMirror = (target) => {
  if (!window.ym) {
    timer || ymWaiter();
    ymCache.push(target);
  } else {
    window.ym(ymId, 'reachGoal', target);
  }
};

/**
 * Обработка параметров перед посыланием лида
 *
 * @param {object} params - параметры
 * @param {string} params.totalPrice - стоимость заказа, строка в формате '150.55'
 * @param {string} params.currency - валюта
 */
const sendLead = ({ currency, totalPrice } = {}) => {
  const value = parseFloat(totalPrice);
  fbMirror('Subscribe', { value, currency });
};

export { fbMirror, sendLead, gaMirror, sendOrder, gTagMirror, ymMirror };
