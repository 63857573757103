import React, { useEffect } from 'react';
import useRouter from '@/hooks/useRouter';
import Layout from '@components/Layout';
import PaymentForm from '@components/Checkout/PaymentForm';

const FakeReg = () => {
  const router = useRouter();
  useEffect(() => {
    !localStorage.getItem('devMode') && router.push('/');
  });
  return (
    <Layout
      showBurger={false}
      showSignButtons={false}
      footer={false}
      user_is_authorized={false}
      layoutClassName="content-page"
    >
      <PaymentForm allFields fakeReg />
    </Layout>
  );
};

export default FakeReg;
