export default {
  home: '/',
  verify: '/verify',
  outerVerify: '/outer-verify',
  privacy: '/privacy',
  terms: '/terms',
  contacts: '/contacts',
  hipaaNotice: '/hipaa-notice',
  ccpa: '/ccpa',
  researchPolicy: '/research-policy',
  cof: '/cof',
  quiz: '/quiz',
  quizId: '/quiz/:id',
  landingQuizId: '/landing_quiz/:id',
  courses: '/courses',
  profile: '/profile',
  blog: '/blog',
  blogDetails: '/blog/details',
  blogDetailsNewsId: '/blog/details/:newsId',
  checkout: '/checkout',
  fakeReg: '/fake-reg',
  cancelSubscription: '/cancel-subscription',
  cancelSuccess: '/cancel-success',
  refund: '/refund',
  notFound: '/404',
};
