import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { translate } from '../../utils';
import { inject, observer } from 'mobx-react';
import MediaQuery from 'react-responsive/src/Component';
import breakpoints from '../../utils/breakpoints';
import { Link as ScrollLink } from 'react-scroll';

import SocialItem from '../HomePage/SocialItem';
import Routes from '@utils/Routes';

@inject('commonStore', 'appStore')
@observer
class Footer extends Component {
  render() {
    const { commonStore, appStore } = this.props;
    const isAuthorizedUser = this.props.authorized;
    const isMainPage = this.props.location.pathname === '/' && !isAuthorizedUser;
    const firstColumn = commonStore.menuRoutesArr.slice(0, 4);
    const secondColumn = commonStore.menuRoutesArr.slice(4, 8);
    const changedContacts = appStore.appContacts.map((contact) => {
      if (contact.filedName === 'EMAIL') {
        if (window.location.host === 'veanie.com') {
          contact.value = 'support@veanie.com';
        }
      }

      if (contact.filedName === 'PHONE_NUMBER') {
        if (window.location.host === 'veanie.com') {
          contact.value = '+16615254321';
        }
      }

      if (contact.filedName === 'Worldwide') {
        if (window.location.host === 'veanie.com') {
          contact.value = '340 South Lemon Ave 7997, Walnut, CA 91789, USA';
        }
      }

      return contact;
    });

    return (
      <>
        <div className={`footer ${isAuthorizedUser ? 'footer_light' : ''}`}>
          <div
            className={`footer__container ${isAuthorizedUser ? 'footer__container_limited' : ''}`}
          >
            {/* <div className="footer__socials">
              <MediaQuery maxWidth={breakpoints.xs}>
                <div className="footer__menu-item footer__menu-item_copyright">
                  <span>© 2022. {appStore.app_info?.project_name || 'Vean'}</span>
                </div>
              </MediaQuery>
    </div> */}

            <div className={`footer__menu ${isAuthorizedUser ? 'footer__menu_no-pad' : ''}`}>
              {isMainPage && (
                <MediaQuery maxWidth={breakpoints.xs}>
                  <div className="footer__menu-list">
                    {commonStore.menuAnchorsArr.map((anchor, i) => {
                      if (window.innerWidth >= parseInt(breakpoints['sm-plus'])) {
                        return (
                          <div key={`drop-anchor${i}`} className="footer__menu-item">
                            {translate(anchor.translateKey)}
                          </div>
                        );
                      }
                      return (
                        <ScrollLink
                          to={anchor.sectionName}
                          smooth
                          offset={-60}
                          key={`drop-anchor${i}`}
                          className="footer__menu-item"
                        >
                          {translate(anchor.translateKey).replace(
                            /{{project_name}}/g,
                            appStore.app_info?.project_name || 'Vean',
                          )}
                        </ScrollLink>
                      );
                    })}

                    {isMainPage && (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={commonStore.getCurrentDeviceAppLink()}
                        onClick={(e) => {
                          commonStore.showAppMessage(e);
                        }}
                        className="footer__menu-item"
                      />
                    )}
                  </div>
                </MediaQuery>
              )}
              <div className="footer__menu-list">
                {!isMainPage && (
                  <>
                    {/* <MediaQuery maxWidth={breakpoints.xs}>
                      <a
                        href={commonStore.getCurrentDeviceAppLink()}
                        onClick={(e) => {
                          commonStore.showAppMessage(e);
                        }}
                        className="footer__menu-item"
                      >
                        {translate('APP_DOWNLOAD')}
                      </a>
                    </MediaQuery> */}
                    {!isAuthorizedUser && (
                      <NavLink
                        to={Routes.home}
                        className="footer__menu-item footer__menu-item_route"
                      >
                        {translate('HOME')}
                      </NavLink>
                    )}
                  </>
                )}
                {firstColumn.map((link, i) => {
                  return (
                    <NavLink
                      key={`footer-route${i}`}
                      to={link.route}
                      className="footer__menu-item footer__menu-item_route"
                    >
                      {translate(link.translateKey)}
                    </NavLink>
                  );
                })}
              </div>
              <div className="footer__menu-list">
                {secondColumn.map((link, i) => {
                  return (
                    <NavLink
                      key={`footer-route${i}`}
                      to={link.route}
                      className="footer__menu-item footer__menu-item_route"
                    >
                      {translate(link.translateKey)}
                    </NavLink>
                  );
                })}
              </div>
              <div
                className={`footer__contacts ${
                  isAuthorizedUser ? 'footer__container_limited' : ''
                }`}
              >
                {changedContacts.map((contact, i) => {
                  return (
                    <div key={i}>
                      <div className="footer__contacts-sub-label">
                        {translate(contact.filedName)}
                      </div>
                      {contact.fieldName === 'EMAIL' ? (
                        <a href={`mailto:${translate(contact.value)}`}>
                          {translate(contact.value)}
                        </a>
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: translate(contact.value) }} />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="footer__menu-list">
                <div className="footer__socials">
                  <SocialItem dark={isAuthorizedUser} socialName="facebook" />
                  <SocialItem dark={isAuthorizedUser} socialName="instagram" />
                </div>
                <div className="footer__menu-item footer__menu-item_copyright">
                  © 2022. {appStore.app_info?.project_name || 'Vean'}
                </div>
              </div>
              {/* <MediaQuery minWidth={breakpoints['sm-plus']}>
                <div className="footer__menu-app">
                  <div className="footer__menu-app-name">Vean Mobile App:</div>
                  <SocialItem app dark socialName="ios" />
                  <SocialItem app dark socialName="android" />
                </div>
              </MediaQuery> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Footer);
