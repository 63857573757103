import React, { useEffect } from 'react';
import { withRouter, useParams, useHistory } from 'react-router-dom';
import Layout from '../Layout';
import { useStores } from '../../store';
import { observer } from 'mobx-react';
import Spinner from '@components/Spinner';
import '../../assets/styles/quiz.less';
import { LANDING_QUIZ_JSON } from './LandingQuizJSON';

const LandingQuiz = observer(() => {
  const history = useHistory();
  const paramId = useParams().id || null;
  const { resetQuiz, changeQuizData } = useStores().quizStore;

  useEffect(() => {
    if (paramId) {
      const activeLandingQuizKeys = LANDING_QUIZ_JSON.map((quiz) => Object.keys(quiz)[0]);

      if (activeLandingQuizKeys.includes(paramId)) {
        resetQuiz();
        changeQuizData('quizId', paramId);

        history.replace('/quiz');
      } else {
        history.replace('/404');
      }
    }
  }, []);

  return (
    <Layout
      showBurger={false}
      showSignButtons={false}
      footer={false}
      user_is_authorized={false}
      layoutClassName="content-page"
    >
      <div className="content-page__container quiz-page__container">
        <Spinner className="home-login__spinner" color="#f4955f" />
      </div>
    </Layout>
  );
});

export const LandingQuizPage = withRouter(LandingQuiz);
