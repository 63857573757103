import { makeObservable, observable, action } from 'mobx';
import { persist } from 'mobx-persist';
import { generateUniqueId, sendChangeAnswers, getQuizAnswers } from '../actions/quiz';
import { getQuizAnswersAdapter } from '../utils/adapters';
import AppStore from './AppStore';
import { LangDynamicLink } from '@utils/LinkLangRoutes';

class QuizStore {
  @persist('object') @observable state = {
    loader: false,
    quizId: null,
    userPrice: null,
    showSurvey: true,
    pageName: 'loader',
    oldEmail: '',
    paymentVersion: 'version1',
    survey: {
      data: null,
      currentPageNo: null,
    },
    uniqueIds: {
      code: null,
      id: null,
    },
  };

  @observable currentQuizPages = [];

  @action setCurrentQuizPages = (arr) => {
    this.currentQuizPages = arr;
  };

  @action changeQuizData = (key, value) => {
    this.state = {
      ...this.state,
      [key]: value,
    };
  };

  @action setQuizData = (payload) => {
    this.state = {
      ...this.state,
      ...payload,
    };
  };

  @action generateUniqueId = async () => {
    const data = await generateUniqueId({
      site: window.location.href.replace(window.location.search, ''),
      quiz_id: this.state.quizId,
      price: this.state.userPrice,
    });

    this.state = {
      ...this.state,
      uniqueIds: {
        code: data.code,
        id: data.id,
      },
    };
  };

  @action sendChangeAnswers = async (payload, testClosed = false) => {
    const sendObj = {
      ...(payload || {}),
      answers: {
        ...this.state.survey.data,
        pageName: this.state.pageName,
        oldEmail: this.state.oldEmail,
        showSurvey: this.state.showSurvey,
        currentPageNo: this.state.survey.currentPageNo,
        paymentVersion: this.state.paymentVersion,
      },
      ...(testClosed ? { closed: Number(testClosed) } : {}),
      lang: AppStore.ln || 'en',
      ...(AppStore.activeTariffName ? { tariff_id: AppStore.activeTariffName } : {}),
      ...(localStorage.getItem('utm_source')
        ? { utm_source: localStorage.getItem('utm_source') }
        : {}),
      ...(localStorage.getItem('utm_medium')
        ? { utm_medium: localStorage.getItem('utm_medium') }
        : {}),
      ...(localStorage.getItem('utm_campaign')
        ? { utm_campaign: localStorage.getItem('utm_campaign') }
        : {}),
      ...(localStorage.getItem('utm_term') ? { utm_term: localStorage.getItem('utm_term') } : {}),
      ...(localStorage.getItem('utm_content')
        ? { utm_content: localStorage.getItem('utm_content') }
        : {}),
      ...(localStorage.getItem('esub') ? { esub: localStorage.getItem('esub') } : {}),
      ...(localStorage.getItem('pub_id') ? { pub_id: localStorage.getItem('pub_id') } : {}),
    };

    if (this.state.uniqueIds.id) {
      await sendChangeAnswers(sendObj, this.state.uniqueIds.id);
    }
  };

  @action getQuizAnswers = async (paramId, history) => {
    try {
      const data = await getQuizAnswers(paramId);
      const oldData = getQuizAnswersAdapter(data);
      if (oldData.oldEmail) {
        oldData.pageName = 'payment';
        oldData.showSurvey = false;
      }

      this.state = {
        ...this.state,
        ...oldData,
        quizId: data.quiz_id,
        userPrice: data.price,
        uniqueIds: {
          code: data.code,
          id: data.id,
        },
      };

      return { ...oldData, quizId: data.quiz_id };
    } catch (error) {
      history.push(LangDynamicLink('404'));
    }
  };

  @action resetQuiz = () => {
    this.state = {
      loader: false,
      quizId: null,
      userPrice: null,
      showSurvey: true,
      pageName: 'loader',
      oldEmail: '',
      paymentVersion: 'version1',
      survey: {
        data: null,
        currentPageNo: null,
      },
      uniqueIds: {
        code: null,
        id: null,
      },
    };
  };

  constructor() {
    makeObservable(this);
  }
}

export default new QuizStore();
