import React, { Component } from 'react';
import RenderSvg from '../RenderSvg';

import spreadIcon from '../../assets/icons/spread-icon.svg';
import circle from '../../assets/icons/circle.svg';
import { translate } from '@/utils';
import { inject, observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { LangDynamicLink } from '@utils/LinkLangRoutes';

@inject('appStore')
@observer
class ReviewItem extends Component {
  render() {
    const { data, ln, appStore } = this.props;

    return (
      <>
        <div className="review-item">
          <div className="review-item__container">
            <div className="review-item__image-container">
              <img className="review-item__circle" src={circle} alt="" />
              <img
                className="review-item__avatar"
                src={data.image[ln] || data.image.default}
                alt=""
              />
            </div>
            <div className="review-item__author">{translate(data.authorKey)}</div>
            <div className="review-item__record">
              <span>{translate(data.progressKey)} </span>
              <span className="review-item__time">{translate(data.timeKey)}</span>
            </div>
            <div className="review-item__quote">“</div>
            <div className="review-item__text">
              {translate(data.textKey).replace(
                /{{project_name}}/g,
                appStore.app_info?.project_name || 'Vean',
              )}
            </div>
            <div className="review-item__spread">
              <RenderSvg path={spreadIcon} widthSize={14} heightSize={2} />
            </div>
            <button className="review-item__button orange-button-main">
              {translate('GET_STARTED')}
              <NavLink to={LangDynamicLink('quiz')} className="fixed-links__item-link" />
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default ReviewItem;
