export const PRICE_DATA = [
  '9.90',
  '10.90',
  '11.90',
  '12.90',
  '13.90',
  '14.90',
  '15.90',
  '16.90',
  '17.90',
  '18.90',
  '19.90',
  '20.90',
  '21.90',
  '22.90',
  '23.90',
  '24.90',
  '25.90',
];
