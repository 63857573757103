import React from 'react';
import { Spin, Icon } from 'antd';
import colorsVars from '../utils/colorsVars';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

const Spinner = ({ className = '', color = colorsVars.colorBright }) => (
  <div className={`text-center w-100 ${className}`}>
    <Spin indicator={antIcon} style={{ color }} />
  </div>
);

export default Spinner;
