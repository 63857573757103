import React, { useRef } from 'react';
import { translate } from '@/utils';
import breakpoints from '@utils/breakpoints';
import Slider from 'react-slick';
import RenderSvg from '@components/RenderSvg';
import sliderArrow from '@icons/slider-arrow.svg';
import colorsVars from '@utils/colorsVars';
import { observer } from 'mobx-react';
import { useStores } from '@/store';

const CheckoutReviewWithComment = () => {
  const appLang = localStorage.getItem('lang');
  const revSlider = useRef(null);
  const { app_info, ln } = useStores().appStore;
  const isDirectionRTL = ln === 'ar';
  const commonSliderSettings = {
    dots: true,
    centerMode: false,
    infinite: false,
    vertical: false,
    verticalSwiping: false,
    centerPadding: '20px',
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    arrows: false,
    autoplay: false,
    speed: 500,
    variableWidth: false,
    draggable: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: parseInt(breakpoints.xs, 10) + 1,
        settings: {
          dots: false,
          centerMode: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          variableWidth: true,
          autoplay: true,
        },
      },
    ],
  };
  const nextSlide = () => {
    revSlider?.current.slickNext();
  };

  const prevSlide = () => {
    revSlider?.current.slickPrev();
  };
  const reviewsList = {
    default: ['ava1.png', 'ava2.png', 'ava3.png', 'ava4.png'],
    ko: ['ava1ko.png', 'ava2ko.png', 'ava3ko.png', 'ava4ko.png'],
    ru: ['ava1ru.png', 'ava2ru.png', 'ava3ru.png', 'ava4ru.png'],
    es: ['ava1es.png', 'ava2es.png', 'ava3es.png', 'ava4es.png'],
    la: ['ava1es.png', 'ava2la.png', 'ava3es.png', 'ava4la.png'],
    it: ['ava1es.png', 'ava2es.png', 'ava3es.png', 'ava4es.png'],
    ro: ['ava1es.png', 'ava2ro.png', 'ava3es.png', 'ava4ro.png'],
    cs: ['ava1cs.png', 'ava2cs.png', 'ava3.png', 'ava4cs.png'],
    fr: ['ava1.png', 'ava2fr.png', 'ava3fr.png', 'ava4es.png'],
    ja: ['ava1ja.png', 'ava2ja.png', 'ava3ja.png', 'ava4ja.png'],
    bg: ['ava1.png', 'ava2.png', 'ava3.png', 'ava4.png'],
    pb: ['ava1pb.png', 'ava2pb.png', 'ava3pb.png', 'ava4pb.png'],
    de: ['ava1.png', 'ava2.png', 'ava3.png', 'ava4.png'],
    gr: ['ava1.png', 'ava2gr.png', 'ava3gr.png', 'ava4gr.png'],
    hr: ['ava1.png', 'ava2.png', 'ava3.png', 'ava4.png'],
    hu: ['ava1.png', 'ava2.png', 'ava3.png', 'ava4.png'],
    pt: ['ava1pt.png', 'ava2pt.png', 'ava3pt.png', 'ava4pt.png'],
    vi: ['ava1vi.png', 'ava2vi.png', 'ava3vi.png', 'ava4vi.png'],
    id: ['ava1id.png', 'ava2id.png', 'ava3id.png', 'ava4id.png'],
    ar: ['ava1ar.png', 'ava2ar.png', 'ava3ar.png', 'ava4ar.png'],
  };

  const languageCurrentReviews = reviewsList[appLang]?.length
    ? reviewsList[appLang]
    : reviewsList.default;
  const commonSliderSettingsLangRTL = {
    ...commonSliderSettings,
    initialSlide: languageCurrentReviews.length / 2,
  };

  const objSliderSettings = isDirectionRTL
    ? { ...commonSliderSettingsLangRTL }
    : { ...commonSliderSettings };

  const currentReviews = isDirectionRTL ? languageCurrentReviews.reverse() : languageCurrentReviews;
  return (
    <div className="checkout-reviews">
      <div onClick={prevSlide} className="checkout-reviews__arrow checkout-reviews__arrow_prev">
        <RenderSvg
          path={sliderArrow}
          widthSize={14}
          heightSize={26}
          color={colorsVars.colorMinor}
        />
      </div>
      <Slider ref={revSlider} {...objSliderSettings} className="common-section__slider">
        {currentReviews.map((review, i) => {
          return (
            <div key={i} className="checkout-reviews__item">
              <img src={`/images/avatars/${review}`} alt="" />
              <div className="checkout-reviews__text">
                {translate(`CHECKOUT__REVIEWS${i + 1}_TEXT`).replace(
                  /{{project_name}}/g,
                  app_info?.project_name || 'Vean',
                )}
              </div>
              <div className="checkout-reviews__name">
                {translate(`CHECKOUT__REVIEWS${i + 1}_NAME`)}
              </div>
            </div>
          );
        })}
      </Slider>
      <div onClick={nextSlide} className="checkout-reviews__arrow checkout-reviews__arrow_next">
        <RenderSvg
          path={sliderArrow}
          widthSize={14}
          heightSize={26}
          color={colorsVars.colorMinor}
        />
      </div>
    </div>
  );
};

export default observer(CheckoutReviewWithComment);
