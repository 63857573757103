import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive/src/Component';
import Slider from 'react-slick';
import { translate } from '@/utils';
import breakpoints from '@utils/breakpoints';
import colorsVars from '@utils/colorsVars';
import { getNews, getNewsList, getPublicNews, getPublicNewsList } from '@actions/news';
import NewsItem from '@components/News/NewsItem';
import RenderContent from '@components/RenderContent';
import Spinner from '@components/Spinner';
import NoResults from '@components/NoResults';
import RenderSvg from '@components/RenderSvg';

import angleRight from '@icons/angle_right.svg';
import RoutesLang from '@utils/RoutesLang';

class NewsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNews: null,
      newsList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setNews();
  }

  componentDidUpdate(prevState) {
    if (prevState.location.pathname !== this.props.location.pathname) {
      this.setNews();
    }
  }

  setNews = async () => {
    const { location, match, authorized } = this.props;
    if (location.state?.currentNews) {
      this.setState({ currentNews: location.state.currentNews, newsList: location.state.newsList });
    } else {
      this.setState({ loading: true });
      try {
        const listRes = authorized ? await getNewsList() : await getPublicNewsList();
        const currentNewsId = Number(match.params.newsId);
        const currentNewsRes = authorized
          ? await getNews(currentNewsId)
          : await getPublicNews(currentNewsId);
        this.setState({
          newsList: listRes.data,
          currentNews: currentNewsRes,
        });
      } catch (e) {
        this.setState({ currentNews: null, newsList: [] });
      }
      this.setState({ loading: false });
    }
  };

  renderNewsList() {
    const { currentNews, newsList } = this.state;
    return newsList.map((news, i) => {
      if (i < 6 && news.id !== currentNews.id) {
        return (
          <NewsItem
            news={news}
            notNameLimit
            newsList={newsList}
            key={news.id}
            setNews={this.setNews}
          />
        );
      }
      return null;
    });
  }

  render() {
    const { currentNews, loading } = this.state;
    const { authorized } = this.props;
    const newsSliderSettings = {
      dots: false,
      centerMode: false,
      infinite: false,
      vertical: false,
      verticalSwiping: false,
      centerPadding: '20px',
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      speed: 500,
      variableWidth: true,
      draggable: true,
      touchMove: true,
    };
    const DateBlog = {
      3: { date: '28.04.2021' },
      4: { date: '14.09.2021' },
      5: { date: '27.02.2022' },
      6: { date: '12.11.2022' },
      7: { date: '07.04.2023' },
      8: { date: '21.06.2023' },
      9: { date: '09.10.2023' },
      10: { date: '17.11.2023' },
    };

    return (
      <div className={`news-details ${authorized ? 'news-details_authorized' : ''}`}>
        <div className="container news-details__container">
          {loading ? (
            <Spinner />
          ) : !currentNews ? (
            <NoResults />
          ) : (
            <>
              <NavLink
                className="news-details__back"
                to={authorized ? '/blog/1' : RoutesLang.homeLang}
              >
                <RenderSvg
                  widthSize={8}
                  heightSize={14}
                  rotate={180}
                  color={colorsVars.colorAccent}
                  path={angleRight}
                />
                <span>{translate('BACK')}</span>
              </NavLink>
              <div className="news-details__inner">
                <div className="news-details__content">
                  <RenderContent
                    body={currentNews.body}
                    date={DateBlog[currentNews.id].date || ''}
                    title={currentNews.name}
                    all_content
                  />
                </div>
                <div className="news-details__list">
                  <MediaQuery minWidth={breakpoints['sm-plus']}>
                    <div className="news-details__list-container">{this.renderNewsList()}</div>
                  </MediaQuery>
                  <MediaQuery maxWidth={breakpoints.xs}>
                    <Slider
                      {...newsSliderSettings}
                      className="news-details__slider"
                      ref={(slider) => (this.slider = slider)}
                    >
                      {this.renderNewsList()}
                    </Slider>
                  </MediaQuery>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(NewsDetails);
