import React, { useEffect } from 'react';
import { useStores } from '@/store';
import { gaMirror } from '@utils/metrics';
import { translate } from '@/utils';
import ratesPdf from '../../assets/documents/rates.pdf';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  const { app_info } = useStores().appStore;
  useEffect(() => {
    gaMirror({
      event: 'policy_click',
      category: 'other',
      action: 'click',
      label: 'policy_click',
    });
  });
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('TERMS_CONTENT')
            .replace(/{{project_name}}/g, app_info.project_name || 'VEAN')
            .replace(/{{company_name}}/g, app_info.company_name || 'Subster inc')
            .replace(/{{support_email}}/g, app_info.support_email || 'support@vean.io')
            .replace(/{{support_site}}/g, app_info.support_site || 'veanhelp.com')
            .replace(/{{ratesPdf}}/g, ratesPdf),
        }}
      />
      <Helmet>
        <link rel="canonical" href="https://lunaraapp.com/en/privacy" />
      </Helmet>
    </>
  );
};
export default PrivacyPolicy;
