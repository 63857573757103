import React from 'react';
import RenderSvg from './RenderSvg';
import file_svg from '../assets/icons/file.svg';
import { addHostToPath } from '../utils';

const File = ({ file, show_size = true, className = '', addHost = true, maxWidth = 200 }) => {
  return (
    <div className={`content_file ${className}`}>
      <div className="d-flex align-items-center">
        <div className="content_file-icon mr-2" style={{ background: 'red' }}>
          <RenderSvg path={file_svg} color="#fff" size={20} />
        </div>
        <div className="align-self-center">
          <div className="content__file-name" style={{ maxWidth }}>
            <span className="text__overflow">
              <a
                href={addHost ? addHostToPath(file.path) : file.path}
                download={file.name}
                target="_blank"
                rel="noopener noreferrer"
                title={file.name}
                className="action__element"
              >
                {file.name}
              </a>
            </span>
          </div>
          {show_size ? (
            <div className="text__muted-12">{Math.floor(file.size / 1000)} кб</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default File;
