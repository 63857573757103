import { api } from '../utils';

export const COLORS = ['#FFDD00', '#FC2A52', '#01CB65', '#FF9533', '#7459FF'];
export const COLORS_1 = ['#D7BC0C', '#DA193E', '#0BAD5C', '#D27E31', '#634ED4'];
export const COLORS_2 = [
  'rgba(255, 221, 0, 0.64)',
  '#FC2A52',
  'rgba(3, 199, 102, 0.64)',
  'rgba(255, 149, 51, 0.64)',
  'rgba(116, 89, 255, 0.64)',
];

// eslint-disable-next-line no-unused-vars
export const getCoursesList = async (page = 1, done = null) => {
  try {
    return await api('/cards/list', { limit: 40, done }, 'GET');
  } catch (e) {
    throw e;
  }
};

export const getCourse = async (data) => {
  try {
    const res = await api('/cards', data, 'GET');
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const beginLearning = async (id) => {
  try {
    return await api('/cards/begin', { id }, 'GET');
  } catch (e) {
    throw e;
  }
};
export const finishedLearning = async (id, settings = null) => {
  settings = { ...settings, card_id: id };
  try {
    return await api('/cards/end', { id, settings }, 'POST');
  } catch (e) {
    throw e;
  }
};
export const addSettings = async (card_id, settings) => {
  try {
    return await api('/cards/settings', { card_id, settings }, 'POST');
  } catch (e) {
    throw e;
  }
};

export const getColor = (i) => {
  if (i >= COLORS.length) {
    i %= COLORS.length;
  }
  return COLORS[i];
};

export const getColorByType = (type, settings) => {
  if (type === 'end_section') {
    return COLORS[1];
  }
  if (type === 'test') {
    if (settings?.test === 'failed') {
      return COLORS[1];
    }
    return COLORS[3];
  }
  if (type === 'poll') {
    return COLORS[3];
  }
  if (type === 'checklist') {
    return COLORS[4];
  }
  if (type === 'material') {
    return COLORS[2];
  }
  if (type === 'task') {
    if (settings === 'check') {
      return COLORS[0];
    }
    if (settings === 'rework') {
      return COLORS[1];
    }
    if (settings === 'accepted') {
      return COLORS[2];
    }
    return COLORS[0];
  }
  if (type === 'section' || type === 'direction') {
    return COLORS[2];
  }
  return COLORS[0];
};

export const getColorBg = (type, settings) => {
  if (type === 'end_section') {
    return COLORS_1[1];
  }
  if (type === 'test') {
    if (settings?.test === 'failed') {
      return COLORS_1[1];
    }
    return COLORS_1[2];
  }
  if (type === 'poll') {
    return COLORS_1[3];
  }
  if (type === 'checklist') {
    return COLORS_1[4];
  }
  if (type === 'material') {
    return COLORS_1[2];
  }
  if (type === 'task') {
    return COLORS_1[0];
  }
  if (type === 'section' || type === 'direction') {
    return COLORS_1[2];
  }
  return COLORS_1[0];
};
export const getColorBorder = (type, settings) => {
  if (type === 'end_section') {
    return COLORS_2[1];
  }
  if (type === 'test') {
    if (settings?.test === 'failed') {
      return COLORS_2[1];
    }
    return COLORS_2[2];
  }
  if (type === 'poll') {
    return COLORS_2[3];
  }
  if (type === 'checklist') {
    return COLORS_2[4];
  }
  if (type === 'material') {
    return COLORS_2[2];
  }
  if (type === 'task') {
    return COLORS_2[0];
  }
  if (type === 'section' || type === 'direction') {
    return COLORS_2[2];
  }
  return COLORS_2[0];
};

export const getPercent = (all, process) => {
  if (all === 0 && process === 0) {
    return 100;
  }
  let percent = 0;
  if (process < 0) {
    return 0;
  }
  if (process) {
    percent = (100 * process) / all;
  }
  percent = Math.floor(percent);
  return percent > 100 ? 100 : percent;
};

export const separateToolsFromData = (obj = [], parent) => {
  let active_index = 0;
  const data = [];
  const tools = [];
  obj.map((item) => {
    if (item.type === 'tools') {
      tools.push(item);
    } else {
      data.push(item);
    }
    return null;
  });

  data.map((item, i) => {
    if (isCardDone(item) && active_index === i) {
      active_index = i + 1;
    }

    return null;
  });

  if (parent && (parent?.json?.type === 'direction' || parent?.parent === parent?.root)) {
    if (isCardDone(parent)) {
      data.push({ type: 'end_section', id: 'end_' });
    } else if (isTodayLessonsTimeLimit(parent)) {
      data.push({ type: 'end_section_today', id: 'end_today_' });
    }
  }

  return { data, active_index, tools };
};

export const isCardDone = (card) => {
  if (typeof card.progress === 'object') {
    return card.progress?.done === card.progress?.all;
  }
  if (typeof card.progress === 'number') {
    return Boolean(card.progress);
  }

  return false;
};

export const isTodayLessonsTimeLimit = (card) => {
  if (typeof card.progress === 'object') {
    return Boolean(card.progress.is_today_lessons_time_limit);
  }

  return false;
};

export const checkProgress = (progress) => {
  if (typeof progress === 'object') {
    if (progress?.done >= progress?.all) {
      return true;
    }
  } else if (progress) {
    return true;
  }
  return false;
};

export const separateLinkFromTools = (obj = []) => {
  const data = [];
  const links = [];
  obj.map((item) => (item.type === 'link' ? links.push(item) : data.push(item)));
  return { data, links };
};
