import React, { useCallback, useEffect, useState } from 'react';
import { translate, getPlatform } from '@/utils';
import { inject } from 'mobx-react';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/es/lib/isNumeric';

import Input from '@elements/Input';
import Spinner from '@components/Spinner';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import colorsVars from '@utils/colorsVars';
import useRouter from '@/hooks/useRouter';
import { gaMirror, ymMirror } from '@utils/metrics';
import angleDown from '@icons/angle_down.svg';
import RenderSvg from '@components/RenderSvg';
import { sendAmplitudeFirstLaunch } from '@utils/amplitude';
import { applicationPayment } from '@actions/checkout';
import PayPalBtn from '@components/Checkout/PayPalBtn';
import visa from '@icons/visa.svg';
import mastercard from '@icons/mastercard.svg';
import { useStores } from '@/store';
import PaymentBtns from '@components/Checkout/PaymentBtns';
import RoutesLang from '@utils/RoutesLang';

const PaymentForm = ({ refWrapper, allFields, fakeReg, currentTariff }) => {
  const stripe = useStripe();
  const elements = useElements();
  const router = useRouter();

  const { state } = useStores().quizStore;
  const { quizCheckoutPayment, checkoutPagePayment } = useStores().paymentStore;
  const { ln } = useStores().appStore;

  const [inputErrors, setInputErrors] = useState({
    name: false,
    email: false,
    phone: false,
  });
  const [emailRegisteredError, setEmailRegisteredError] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [quizHasEmailQuestion, setQuizHasEmailQuestion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [payBtnEnabled, setPayBtnEnabled] = useState(true);
  const isMobileApplication = localStorage.getItem('isMobileApplication');
  const emailIsValid = email
    ? Boolean(email.trim() && isEmail(email, [{ require_display_name: true }]))
    : false;
  const gaEventCategory = allFields ? 'checkout' : `quiz_checkout_${state.quizId}`;
  const onlyCardAbTest = localStorage.getItem('card');
  const onlyCardAndStripeButtonAbTest = localStorage.getItem('cardgpayapay');

  useEffect(() => {
    setQuizHasEmailQuestion(Boolean(localStorage.getItem('quizEmail')));
  }, []);

  const setEmailError = () => {
    setInputErrors((prev) => ({ ...prev, email: true }));
  };

  const handleChange = useCallback(
    (name, value) => {
      setInputErrors({ name: false, email: false, phone: false });
      setEmailRegisteredError(false);

      name === 'name' && setName(value);
      name === 'email' && setEmail(value);
      name === 'phone' && (isNumeric(value) || value === '') && setPhone(value);
    },
    [name, email, phone],
  );

  if (isMobileApplication) {
    window.validatePayment = async (transactionId) => {
      const data = {
        is_agree_research_policy: true,
        locale: ln,
        ps_subscription_id: String(transactionId),
      };
      const platform = getPlatform();
      if (platform === 'Android' || platform === 'apple') {
        data.payment_method_name = platform === 'apple' ? 'mobile_ios' : 'mobile_android';
      }
      try {
        setLoading(true);
        const { token, course_id, subscription_id } = await applicationPayment(
          state.uniqueIds.code,
          data,
        );
        const link = `${RoutesLang.verify}?token=${token}&price=""&subscriptionId=${subscription_id}&courseId=${course_id}`;
        router.push(link);
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    };
  }

  const successSubscription = (link) => {
    gaMirror({
      event: 'start_trial',
      category: gaEventCategory,
      action: 'click',
      label: 'start_trial',
    });
    ymMirror('successPayment');
    sendAmplitudeFirstLaunch(state.survey.data, allFields ? email : state.survey.data.email);
    router.push(link);
  };

  const failedSubscription = async (e) => {
    if (e.code === 422) {
      if (!allFields && quizHasEmailQuestion) {
        localStorage.removeItem('quizEmail');
        setQuizHasEmailQuestion(false);
      }
      setEmailError();
      setEmailRegisteredError(true);
    }
    ymMirror('failedPayment');
    gaMirror({
      event: 'failed_payment',
      category: gaEventCategory,
      action: 'payment',
      label: 'failed_payment',
    });
  };

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    if (!isMobileApplication) {
      emailIsValid || setEmailError();
      name.trim() || setInputErrors((prev) => ({ ...prev, name: true }));

      if (!name.trim()) {
        return false;
      }
      if ((allFields || !quizHasEmailQuestion) && !emailIsValid) {
        return false;
      }

      try {
        setLoading(true);

        const cardNumber = elements.getElement(CardNumberElement);
        const details = allFields ? { name, email, phone } : { name };
        if (!phone) {
          delete details.phone;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumber,
          billing_details: {
            ...details,
          },
        });

        if (error) {
          throw new Error(translate('INVALID_CARD'));
        }

        let response;

        if (!allFields) {
          response = await quizCheckoutPayment(paymentMethod.id, !quizHasEmailQuestion && email);
        } else {
          response = await checkoutPagePayment(paymentMethod.id, email, phone);
        }

        if (response.status === 'requires_action') {
          const authResponse = await stripe.confirmCardPayment(response.client_secret, {
            payment_method: paymentMethod.id,
          });

          if (authResponse.error) {
            throw new Error(translate('CARD_DECLINED'));
          }
        }

        successSubscription(response.link);
      } catch (e) {
        failedSubscription(e);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        window.open('webview://actionscreen=payment');
      } catch (e) {
        throw e;
      }
    }
  };

  const cardOptions = {
    iconStyle: 'solid',
    style: {
      base: {
        lineHeight: '24px',
        backgroundColor: colorsVars.colorLight,
        iconColor: colorsVars.colorAccent,
        color: colorsVars.colorMain,
        fontWeight: 400,
        fontFamily: 'SF-UI-Display',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: colorsVars.colorMain,
        },
      },
      invalid: {
        iconColor: colorsVars.colorAttention,
        color: colorsVars.colorAttention,
      },
    },
    hidePostalCode: true,
  };

  const currentCountry = localStorage.getItem('countryByIp');
  const paypalEnabled =
    currentCountry !== 'KO' && currentCountry !== 'KP' && currentCountry !== 'KR';

  const stripeElementEvent = (inputName) => {
    gaMirror({
      event: inputName,
      category: gaEventCategory,
      action: 'click',
      label: inputName,
    });
  };

  return (
    <div className={`payment-form ${fakeReg ? 'payment-form_fake' : ''}`} ref={refWrapper}>
      <form onSubmit={handleSubmit}>
        {!isMobileApplication && (
          <>
            {(allFields || !quizHasEmailQuestion) && (
              <>
                <div className="payment-form__input-container">
                  <Input
                    title={translate('EMAIL')}
                    showError={inputErrors.email}
                    placeholder="example@example.com"
                    onChange={(e) => handleChange('email', e)}
                    value={email}
                    style={['white']}
                  />
                  {emailRegisteredError && (
                    <div className="payment-form__email-error">
                      {translate('APP_EMAIL_REGISTERED')}
                    </div>
                  )}
                </div>
              </>
            )}
            {allFields && (
              <div className="payment-form__input-container">
                <Input
                  title={translate('PHONE_NUMBER')}
                  showError={inputErrors.phone}
                  onChange={(e) => handleChange('phone', e)}
                  value={phone}
                  style={['white']}
                />
              </div>
            )}
            {!isMobileApplication && !fakeReg && !onlyCardAbTest && (
              <div className="payment-form__buttons">
                <div className="payment-form__pay-btn payment-form__pay-btn_card">
                  <RenderSvg path={visa} widthSize={32} heightSize={22} />
                  <RenderSvg path={mastercard} widthSize={32} heightSize={22} />
                </div>
                {payBtnEnabled && !localStorage.getItem('abGpay') && (
                  <PaymentBtns
                    allFields={allFields}
                    setPayBtnEnabled={setPayBtnEnabled}
                    setQuizHasEmailQuestion={setQuizHasEmailQuestion}
                    currentTariff={currentTariff}
                    gaEventCategory={gaEventCategory}
                    btnWithForm
                  />
                )}
                {paypalEnabled && currentTariff.payPalId && !onlyCardAndStripeButtonAbTest && (
                  <div className="payment-form__pay-btn">
                    <PayPalBtn
                      readyForPayPal={emailIsValid}
                      allFields={allFields}
                      setEmailError={setEmailError}
                      setEmailRegisteredError={(status) => {
                        setEmailRegisteredError(status);
                      }}
                      quizCode={state.uniqueIds.code}
                      locale={ln}
                      tariff_id={currentTariff.payPalId}
                      priceAfterTrial={currentTariff.priceAfterTrial}
                      tariffCurrencyText={currentTariff.currencyCode}
                      email={email}
                      phone={phone}
                      gaEventCategory={gaEventCategory}
                      className="payment-form__hidden-btn"
                    />
                  </div>
                )}
              </div>
            )}
            <div className="payment-form__input-container">
              <div className="custom-input__title">{translate('CARD_DATA')}</div>
              <div className="payment-form__card">
                <CardNumberElement
                  onFocus={() => {
                    stripeElementEvent('input_card_number');
                  }}
                  options={cardOptions}
                />
              </div>
            </div>
            <div className="payment-form__input-container payment-form__input-container_column">
              <div className="payment-form__input-inner">
                <div className="custom-input__title">{translate('EXPIRATION_DATE')}</div>
                <div className="payment-form__card">
                  <CardExpiryElement
                    onFocus={() => {
                      stripeElementEvent('input_mm/yy');
                    }}
                    options={cardOptions}
                  />
                </div>
              </div>
              <div className="payment-form__input-inner">
                <div className="custom-input__title">CVV / CVC</div>
                <div className="payment-form__card">
                  <CardCvcElement
                    onFocus={() => {
                      stripeElementEvent('input_cvc');
                    }}
                    options={cardOptions}
                  />
                </div>
              </div>
            </div>
            <div className="payment-form__input-container payment-form__input-container_cap">
              <Input
                onFocus={() => {
                  stripeElementEvent('input_name');
                }}
                title={translate('NAME_CARD')}
                showError={inputErrors.name}
                onChange={(e) => handleChange('name', e)}
                value={name}
                style={['white']}
              />
            </div>
          </>
        )}
        <div className="payment-form__agree">
          <div
            onClick={() => {
              setTermsAccepted(!termsAccepted);
            }}
            className="payment-form__checkbox"
          >
            {termsAccepted && (
              <RenderSvg color={colorsVars.colorLight} path={angleDown} size={14} />
            )}
          </div>
          <div className="payment-form__agree-text">
            <span
              dangerouslySetInnerHTML={{
                __html: translate('POLICY_AGREE_TEXT')
                  .replace(
                    '{{privacy}}',
                    `<a href="/terms" target="_blank" className="py_privacy_link">
                              ${translate('TERMS')}</a>`,
                  )
                  .replace(
                    '{{terms}}',
                    `<a href="/privacy" target="_blank" className="py_privacy_link">
                              ${translate('PRIVACY')}</a>`,
                  ),
              }}
            />
          </div>
        </div>
        <button className="payment-form__button orange-button" disabled={loading || !termsAccepted}>
          {loading ? (
            <Spinner color="#ffffff" />
          ) : currentTariff.priceAfterTrial ? (
            translate('START_TRIAL_PAID')
          ) : (
            translate('START_TRIAL')
          )}
        </button>
      </form>
    </div>
  );
};

export default inject('quizStore')(PaymentForm);
