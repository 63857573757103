import React, { Component } from 'react';
import Slider from 'react-slick';
import breakpoints from '@utils/breakpoints';
import colorsVars from '@utils/colorsVars';
import MediaQuery from 'react-responsive/src/Component';
import RenderSvg from '../RenderSvg';

import sliderArrow from '@icons/slider-arrow.svg';
import angleRight from '@icons/angle_right.svg';
import { translate } from '@/utils';
import { inject } from 'mobx-react';

@inject('appStore')
class CommonSection extends Component {
  isDirectionRTL = this.props.appStore.ln === 'ar';
  constructor(props) {
    super(props);
    this.state = {
      // pageSlideIndexRTL: 0,
      currentSlideIndex: this.isDirectionRTL ? this.props.children.length - 1 : 0,
      itemsLimit: this.props.mobileItemLimit,
      flagFromRTL: true,
    };
  }
  appDataReverse = [];
  async componentDidUpdate() {
    if (this.state.flagFromRTL && this.props.children.length && this.isDirectionRTL) {
      this.appDataReverse = [...this.props.children.reverse()];
      this.setState({ flagFromRTL: false });
    }
  }
  showAllItems() {
    this.setState({ itemsLimit: this.props.children.length });
  }

  render() {
    const sliderChildren = this.isDirectionRTL ? this.appDataReverse : this.props.children;
    // const countPage = this.props.children.map((el, i) => i).reverse();
    const commonSliderSettings = {
      dots: false,
      centerMode: false,
      infinite: false,
      vertical: false,
      verticalSwiping: false,
      centerPadding: '20px',
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      speed: 500,
      variableWidth: true,
      draggable: true,
      touchMove: true,
      beforeChange: (currentSlide, nextSlide) => {
        this.setState({ currentSlideIndex: nextSlide });
      },
      responsive: [
        {
          breakpoint: parseInt(breakpoints.xs, 10) + 1,
          settings: 'unslick',
        },
      ],
    };
    const commonSliderSettingsLangRTL = {
      ...commonSliderSettings,
      initialSlide: this.props.children.length - 1,
      // afterChange: (currentSlide) => {
      //   this.setState({ pageSlideIndexRTL: countPage[currentSlide] });
      // },
    };
    const objSliderSettings = this.isDirectionRTL
      ? { ...commonSliderSettingsLangRTL }
      : { ...commonSliderSettings };

    return (
      <>
        <div className={`common-section ${this.props.classModifications}`}>
          <div className="common-section__container">
            {this.props.label && (
              <h2
                className="common-section__label"
                dangerouslySetInnerHTML={{ __html: this.props.label }}
              />
            )}
            {this.props.text && <div className="common-section__text">{this.props.text}</div>}
          </div>
          <MediaQuery minWidth={breakpoints['sm-plus']}>
            <div className="common-section__arrows-container">
              {this.slider && this.props.children?.length ? (
                <>
                  <div
                    onClick={() => {
                      this.slider.slickGoTo(this.state.currentSlideIndex - 1);
                    }}
                    disabled={this.state.currentSlideIndex === 0}
                    className="common-section__arrow common-section__arrow_prev"
                  >
                    <RenderSvg
                      path={sliderArrow}
                      widthSize={14}
                      heightSize={26}
                      color={colorsVars.colorAccent}
                    />
                  </div>
                  <div className="common-section__count">
                    {/* <span className="common-section__current-slide">
                      {this.isDirectionRTL
                        ? this.state.pageSlideIndexRTL + 1
                        : this.state.currentSlideIndex + 1}
                    </span>{' '}
                    <span className="common-section__total">
                      {' '}
                      {this.slider.props.children.length}
                      </span> */}{' '}
                  </div>
                  <div
                    onClick={() => {
                      this.slider.slickGoTo(this.state.currentSlideIndex + 1);
                    }}
                    disabled={
                      this.state.currentSlideIndex + 3 === this.slider.props.children.length
                    }
                    className="common-section__arrow common-section__arrow_next"
                  >
                    <RenderSvg
                      path={sliderArrow}
                      widthSize={14}
                      heightSize={26}
                      color={colorsVars.colorAccent}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </MediaQuery>
          {this.props.children?.length ? (
            <div
              className={`common-section__slider-container ${this.props.classNameFromSlider}`}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <Slider
                setCurrentSlide={this.setCurrentSlide}
                {...objSliderSettings}
                className="common-section__slider"
                ref={(slider) => (this.slider = slider)}
              >
                {sliderChildren.map((item, i) => {
                  if (
                    window.innerWidth >= parseInt(breakpoints['sm-plus'], 10) ||
                    i < this.state.itemsLimit
                  ) {
                    return item;
                  }
                  return null;
                })}
              </Slider>
              <MediaQuery maxWidth={breakpoints.xs}>
                {this.state.itemsLimit < this.props.children.length && (
                  <div onClick={() => this.showAllItems()} className="common-section__show-more">
                    <span>
                      {translate('SHOW_COUNT').replace(
                        '{{count}}',
                        this.props.children.length - this.state.itemsLimit,
                      )}
                    </span>
                    <RenderSvg
                      path={angleRight}
                      widthSize={8}
                      heightSize={14}
                      rotate={90}
                      color={colorsVars.colorAttention}
                    />
                  </div>
                )}
              </MediaQuery>
            </div>
          ) : (
            <div>{translate('NOTHING_FOUND')}</div>
          )}
        </div>
      </>
    );
  }
}

export default CommonSection;
