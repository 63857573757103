import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../Layout';
import { observer } from 'mobx-react';
import Spinner from '@components/Spinner';
import '../../assets/styles/quiz.less';
import { useStores } from '@/store';
import useRouter from '@/hooks/useRouter';
import { sendOrder, ymMirror } from '@/utils/metrics';
import queryString from 'query-string';

const Verify = observer(() => {
  const { query } = useRouter();
  const { resetQuiz } = useStores().quizStore;
  const { app_info } = useStores().appStore;

  useEffect(() => {
    if (query && query.token) {
      /*: TODO какой то баг с роутам */
      delete query.lang;
      ymMirror('lead');
      sendOrder(query);
      resetQuiz();
      const lang = localStorage.getItem('lang');
      localStorage.clear();
      window.location.href = `${app_info.cabinet_link}outer-verify?${queryString.stringify(
        query,
      )}&lang=${lang}`;
    }
  }, []);

  return (
    <Layout
      showBurger={false}
      showSignButtons={false}
      footer={false}
      user_is_authorized={false}
      layoutClassName="content-page"
    >
      <Spinner className="home-login__spinner" color="#f4955f" />
    </Layout>
  );
});

export const VerifyPage = withRouter(Verify);
