import React from 'react';
import { translate } from '@/utils';
import { useStores } from '@/store';
import { Helmet } from 'react-helmet';
import AppStore from '@store/AppStore';

const COF = () => {
  const { app_info } = useStores().appStore;

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: translate('COF_CONTENT')
            .replace(/{{project_name}}/g, app_info.project_name || 'VEAN')
            .replace(/{{company_name}}/g, app_info.company_name || 'Subster inc')
            .replace(/{{support_email}}/g, app_info.support_email || 'support@vean.io'),
        }}
      />
      <Helmet>
        <link rel="canonical" href="https://lunaraapp.com/en/cof" />
        <title>
          {translate('VEAN_META_TITLE_COF').replace(
            /{{project_name}}/g,
            AppStore.app_info?.project_name || 'Vean',
          )}
        </title>
        <meta
          name="description"
          content={translate('VEAN_META_DESCRIPTION_COF').replace(
            /{{project_name}}/g,
            AppStore.app_info?.project_name || 'Vean',
          )}
        />
      </Helmet>
    </>
  );
};

export default COF;
