export default {
  xxs: '350px',
  xs: '600px',
  sm: '992px',
  md: '1220px',
  lg: '1440px',
  'xs-plus': '351px',
  'sm-plus': '601px',
  'md-plus': '993px',
  'lg-plus': '1221px',
  'xlg-plus': '1441px',
};
