import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import colorsVars from '@utils/colorsVars';
import RenderSvg from '@components/RenderSvg';

import appleIcon from '@icons/download_ios_icon.svg';
import androidIcon from '@icons/download_android_icon.svg';
import facebookIcon from '@icons/social_fb_icon.svg';
import youtubeIcon from '@icons/social_youtube_icon.svg';
import twitterIcon from '@icons/social_twitter_icon.svg';
import instagramIcon from '@icons/social_insta_icon.svg';

@inject('commonStore', 'appStore')
@observer
class SocialItem extends Component {
  constructor(props) {
    super(props);

    const { commonStore, appStore } = props;

    this.state = {
      socials: {
        ios: {
          href: commonStore.iosAppLink,
          className: 'social-icon_apple',
          icon: appleIcon,
        },
        android: {
          href: commonStore.androidAppLink,
          className: 'social-icon_android',
          icon: androidIcon,
        },
        facebook: {
          href: appStore.app_info?.facebook_link,
          className: 'social-icon_fb',
          icon: facebookIcon,
        },
        youTube: {
          href: '/',
          className: 'social-icon_yt',
          icon: youtubeIcon,
        },
        twitter: {
          href: '/',
          className: 'social-icon_tw',
          icon: twitterIcon,
        },
        instagram: {
          href: appStore.app_info?.instagram_link,
          className: 'social-icon_inst',
          icon: instagramIcon,
        },
      },
    };
  }

  showAppMessage(e) {
    const { app, commonStore } = this.props;
    if (app) {
      commonStore.showAppMessage(e);
    }
  }

  render() {
    const iconData = this.state.socials[this.props.socialName];
    const { dark } = this.props;

    return (
      <>
        <a
          onClick={(e) => {
            this.showAppMessage(e);
          }}
          href={iconData.href}
          target="_blank"
          rel="noopener noreferrer"
          className={`social-icon ${iconData.className} ${dark ? 'social-icon_dark' : ''}`}
        >
          <RenderSvg color={dark && colorsVars.colorLight} path={iconData.icon} size={20} />
        </a>
      </>
    );
  }
}

export default SocialItem;
