import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import colorsVars from '../../utils/colorsVars';
import RenderSvg from '../RenderSvg';
import { getPlatform, translate } from '@/utils';

import gym from '@images/gym.png';
import ball from '@images/ball.png';
import heart from '@images/heart.png';
import angleRight from '@icons/angle_right.svg';
import { LangDynamicLink } from '@utils/LinkLangRoutes';

@inject('appStore')
@observer
class FixedLinks extends Component {
  render() {
    const { inputParameters } = this.props.appStore;
    const linksData = [
      {
        image: gym,
        labelKey: 'GET_FIT',
        textKey: 'FIND_THE_BEST_WAY',
      },
      {
        image: ball,
        labelKey: 'GET_RID',
        textKey: 'ENHANCE_ADJUST',
      },
      {
        image: heart,
        labelKey: 'GET_HEALTHIER',
        textKey: 'ELABORATE_HEALTHY',
      },
    ];

    const backDomain = `back_domain=${window.location.origin}`;
    return (
      <>
        <div
          className={`fixed-links 
        ${!this.props.firstSectionIsActive && !this.props.webview ? 'fixed-links_animated' : ''}  
        ${this.props.lastSectionIsActive ? 'fixed-links_last' : ''}`}
        >
          {linksData.map((item, i) => {
            return (
              <div to="/quiz" key={i} className="fixed-links__item">
                <div className="fixed-links__image-container">
                  <img src={item.image} alt="" className="fixed-links__image" />
                </div>
                <div className="fixed-links__inner">
                  <div className="fixed-links__label">{translate(item.labelKey)}</div>
                  <div className="fixed-links__text">{translate(item.textKey)}</div>
                </div>
                <RenderSvg
                  path={angleRight}
                  widthSize={8}
                  heightSize={14}
                  color={colorsVars.colorAttention}
                  className="fixed-links__arrow"
                />
                {this.props.webview ? (
                  <>
                    {getPlatform() === 'apple' ? (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={`ftp://159.223.2.23/route.html${
                          inputParameters ? `${inputParameters}&${backDomain}` : `?${backDomain}`
                        }`}
                        className="fixed-links__item-link"
                      />
                    ) : (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={`intent://${window.location.host}/quiz${inputParameters}#Intent;scheme=https;package=com.android.chrome;end`}
                        className="fixed-links__item-link"
                      />
                    )}
                  </>
                ) : (
                  <NavLink to={LangDynamicLink('quiz')} className="fixed-links__item-link" />
                )}
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default FixedLinks;
