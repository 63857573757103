import React, { useEffect, useState } from 'react';

const Timer = ({ initialMinutes = 0, initialSeconds = 0, className = '' }) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div className={`timer ${className}`}>
      <div className="timer__item">{minutes < 10 ? `0${minutes}` : minutes}</div>
      <div className="timer__dots">:</div>
      <div className="timer__item">{seconds < 10 ? `0${seconds}` : seconds}</div>
    </div>
  );
};

export default Timer;
