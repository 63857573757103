import { api } from '../utils';

export const generateUniqueId = async (data) => {
  try {
    const res = await api('/open_test', data);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const sendChangeAnswers = async (data, id) => {
  try {
    const res = await api(`/open_test/${id}`, data, 'PUT', false, '&debug=1');
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getQuizAnswers = async (id) => {
  try {
    const res = await api(`/open_test/${id}`, {}, 'GET');
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const quizCheckoutPayment = async (code, data) =>
  api(`/open_test/${code}/subscriptions/stripe`, data, 'POST');
