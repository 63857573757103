export const getQuizAnswersAdapter = (data) => {
  const oldData = {};
  if (!Array.isArray(data.answers)) {
    oldData.survey = {
      data: data.answers,
      currentPageNo: data.answers.currentPageNo,
    };
    oldData.paymentVersion = data.answers.paymentVersion;
    oldData.showSurvey = data.answers.showSurvey;
    oldData.pageName = data.answers.pageName;
    oldData.oldEmail = data.answers.oldEmail;
  }
  return oldData;
};
