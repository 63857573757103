import React, { Component } from 'react';
import Layout from '@components/Layout';
import NewsDetails from '@components/NewsDetails';

class NewsDetailsContainer extends Component {
  render() {
    return (
      <>
        <Layout user_is_authorized={this.props.authorized} with_container={false}>
          <NewsDetails authorized={this.props.authorized} />
        </Layout>
      </>
    );
  }
}

export default NewsDetailsContainer;
