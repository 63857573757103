import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';

import useRouter from '@/hooks/useRouter';

import Modal from '@elements/Modal';
import WrapperWithSpinner from './WrapperWithSpinner';
import LoginForm from './LoginForm';
import RestorePasswordForm from './RestorePasswordForm';
import SuccessMessage from './SuccessMessage';

const ContentResolver = inject('commonStore')(
  observer(({ commonStore }) => {
    const contentKeys = {
      login: 'loginForm',
      restore: 'restorePasswordForm',
      success: 'successMessage',
    };

    const [loading, setLoading] = useState(false);
    const [contentName, setContentName] = useState(contentKeys.login);

    const router = useRouter();

    useEffect(() => {
      const unsubscribeFromHistory = router.history.listen(() => {
        const { showLoginPopUp, closeLogInPopUp } = commonStore;
        showLoginPopUp && closeLogInPopUp();
      });

      return unsubscribeFromHistory;
    }, []);

    const closeModal = () => {
      commonStore.toggleLogInPopUp();
    };

    const getContent = () => {
      const defaultProps = {
        setLoading,
        contentKeys,
        setContentName,
        closeModal,
      };

      if (contentName === contentKeys.restore) {
        return <RestorePasswordForm {...defaultProps} />;
      }

      if (contentName === contentKeys.success) {
        return <SuccessMessage {...defaultProps} />;
      }

      return <LoginForm {...defaultProps} />;
    };

    return (
      <>
        <Modal close={closeModal} contentClass="home-login">
          <WrapperWithSpinner loading={loading}>{getContent()}</WrapperWithSpinner>
        </Modal>
      </>
    );
  }),
);

export default ContentResolver;
