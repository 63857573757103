import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Cookies from 'js-cookie';

import useRouter from '@/hooks/useRouter';

import colorsVars from '@utils/colorsVars';
import { toast, translate } from '@utils';

import RenderSvg from '@components/RenderSvg';
import signIn from '@icons/sign-in_icon.svg';
import userIcon from '@icons/user-icon.svg';
import lock from '@icons/lock.svg';

import Input from '@elements/Input';

import contentPropTypes from './contentPropTypes';
import { sendAmplitudeSessionStart } from '@utils/amplitude';
import { gaMirror } from '@utils/metrics';

const LoginForm = inject('userStore')(
  observer(({ userStore, setLoading, contentKeys, setContentName }) => {
    const router = useRouter();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [inputErrors, setInputErrors] = useState(() => ({
      email: false,
      password: false,
    }));

    const logIn = async () => {
      try {
        setLoading(true);
        const id = await userStore.logIn({
          email,
          password,
        });
        if (id) {
          Cookies.set('_GAUSERID', id, { expires: 365 });
        }
        sendAmplitudeSessionStart(email);

        router.push('/courses');
      } catch (e) {
        if (e.code === 404 || e.code === 402) {
          setInputErrors({
            email: true,
            password: true,
          });
        }
        gaMirror({
          event: 'sign_in_popup_enter_success',
          category: 'sign_in_popup',
          action: 'click',
          label: 'sign_in_popup_enter_success',
        });
        toast(translate(e.message));
      } finally {
        setLoading(false);
      }
    };

    const handleChange = useCallback(
      (name, value) => {
        setInputErrors({ email: false, password: false });

        name === 'password' && setPassword(value);
        name === 'email' && setEmail(value);
      },
      [email, password],
    );

    const handleSubmit = useCallback(
      (e) => {
        e.preventDefault();

        email.trim() || setInputErrors((prev) => ({ ...prev, email: true }));
        password.trim() || setInputErrors((prev) => ({ ...prev, password: true }));

        if (!email.trim() || !password.trim()) {
          return;
        }

        logIn();
      },
      [email, password],
    );

    const forgotPasswordHandleClick = () => {
      gaMirror({
        event: 'sign_in_popup_forgot_password',
        category: 'sign_in_popup',
        action: 'click',
        label: 'sign_in_popup_forgot_password',
      });
      setContentName(contentKeys.restore);
    };

    return (
      <>
        <div className="home-login__title">
          <RenderSvg path={signIn} size={28} />
          <span>{translate('SIGN_IN')}</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="home-login__inputs">
            <Input
              title={translate('EMAIL')}
              value={email}
              onChange={(e) => handleChange('email', e)}
              showError={inputErrors.email}
            />
            <Input
              title={translate('PASSWORD')}
              type="password"
              value={password}
              onChange={(e) => handleChange('password', e)}
              showError={inputErrors.password}
            />
            <button type="submit" className="orange-button button-with-arrow">
              {translate('ENTER')}
            </button>
          </div>
        </form>
        <div className="home-login__divider" />
        <div className="home-login__footer">
          <Link to="/quiz" className="grey-button button-with-left-icon">
            <RenderSvg path={userIcon} size={14} color={colorsVars.colorMinor} />
            {translate('CREATE_ACCOUNT')}
          </Link>
          <button
            type="button"
            className="dark-button button-with-left-icon"
            onClick={() => forgotPasswordHandleClick()}
          >
            <RenderSvg path={lock} size={14} />
            {translate('FORGOT_PASSWORD')}
          </button>
        </div>
      </>
    );
  }),
);

LoginForm.propTypes = contentPropTypes;

export default LoginForm;
