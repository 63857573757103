import React, { useEffect } from 'react';
import { useRecurly } from '@recurly/react-recurly';
import RenderSvg from '@components/RenderSvg';
import paypal from '@icons/paypal.svg';
import { useStores } from '@/store';

const RecurlyPayPalButton = ({
  recurlyPayment,
  email,
  emailIsValid,
  setEmailError,
  isCheckoutPage,
  quizHasEmailQuestion,
}) => {
  const { currentTariff } = useStores().paymentStore;
  const { app_info } = useStores().appStore;
  const recurly = useRecurly();
  const payPal = recurly.PayPal({
    display: {
      displayName: app_info?.project_name || 'Vean',
    },
  });

  useEffect(() => {
    payPal.on('token', async (token) => {
      if (!emailIsValid && isCheckoutPage) {
        setEmailError();
        return false;
      }
      await recurlyPayment(token.id, email);
    });

    payPal.on('error', (error) => {
      throw error;
    });
  }, [payPal]);

  const handleClick = () => {
    if (quizHasEmailQuestion || emailIsValid) {
      payPal.start();
    } else {
      setEmailError();
    }
  };

  return (
    <>
      {currentTariff.payPalId ? (
        <div className="recurly-pay-pal" onClick={handleClick}>
          <div className="recurly-pay-pal__btn recurly-pay-pal__btn_pay-pal">
            <RenderSvg path={paypal} widthSize={63} heightSize={20} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default RecurlyPayPalButton;
