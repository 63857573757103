import React from 'react';
import Layout from '@components/Layout';
import { Checkout } from '@components/Checkout';

const CheckoutContainer = () => {
  return (
    <Layout
      showBurger={false}
      showSignButtons={false}
      footer={false}
      user_is_authorized={false}
      layoutClassName="checkout-page"
    >
      <div className="checkout-page__container">
        <Checkout isCheckoutPage />
      </div>
    </Layout>
  );
};

export default CheckoutContainer;
