import React from 'react';
import PropTypes from 'prop-types';

import Spinner from '@components/Spinner';

function WrapperWithSpinner({ loading, children }) {
  return (
    <>
      {loading && <Spinner className="home-login__spinner" color="#f4955f" />}
      <div className={`home-login__container ${loading ? 'home-login__container_blur' : ''}`}>
        {children}
      </div>
    </>
  );
}

WrapperWithSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default WrapperWithSpinner;
