import { observable, action, makeObservable } from 'mobx';
import { getNotifList } from '../actions/notifications';

class NotificationStore {
  @observable notifications = null;

  @action get = async () => {
    try {
      this.notifications = await getNotifList();
    } catch (e) {
      throw e;
    }
  };

  constructor() {
    makeObservable(this);
  }
}

export default new NotificationStore();
