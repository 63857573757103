import React, { Component } from 'react';
import { addHostToPath, translate } from '../utils';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';

import File from './File';
import watch from '@icons/watch.svg';
import colorsVars from '@utils/colorsVars';
import RenderSvg from '@components/RenderSvg';

class RenderContent extends Component {
  state = {
    need_more: true,
    is_open: false,
  };

  toggleReedMore = () => {
    this.setState({ is_open: !this.state.is_open });
  };

  componentDidMount() {
    if ((this._task && this._task.scrollHeight <= 120) || this.props.all_content) {
      this.setState({ need_more: false });
    }
  }

  render() {
    const { courseAuthor, body, title, date, style = {}, type = '' } = this.props;
    if (!body && !title) {
      return null;
    }

    return (
      <div style={style} className="render-content">
        <div ref={(ref) => (this._task = ref)}>
          <div className="render-content__head">
            {type === 'material' ? (
              <>
                <div className="render-content__content-name">{translate('MATERIAL')}</div>
                {title && <div className="render-content__title">{title}</div>}
                {courseAuthor && (
                  <div className="render-content__creator">
                    {translate('BY')} <span>{courseAuthor}</span>
                  </div>
                )}
              </>
            ) : (
              <>
                {title && <div className="render-content__title">{title}</div>}
                {date && (
                  <div className="render-content__date">
                    <RenderSvg path={watch} size={16} color={colorsVars.colorAccent} />{' '}
                    <span>{date}</span>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="render-content__body">
            {body.map((item, i) => (
              <div key={i} className="render-content__body-inner">
                {item.type === 'heading' && (
                  <div className="render-content__sub-title">
                    <span>{item.text}</span>
                  </div>
                )}
                {item.type === 'link' && (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="render-content__link"
                  >
                    {item.link}
                  </a>
                )}
                {item.type === 'material' &&
                  item.content &&
                  item.content.map((content, j) => (
                    <div
                      key={`c${j}`}
                      className={`render-content__item  render-content__item_${content.type}`}
                    >
                      {content.type === 'text' && (
                        <div
                          key={`c${j}`}
                          dangerouslySetInnerHTML={{ __html: content.text }}
                          className="render-content__text"
                        />
                      )}
                      {/* Todo: нужно будет сделать проверку, чтобы если 2 картинки идут подряд складываться их в ряд */}
                      {content.type === 'image' && (
                        <div className="render-content__image">
                          <img src={addHostToPath(content.image)} alt="img" />
                        </div>
                      )}
                      {content.type === 'youtube' && (
                        <div className="render-content__youtube">
                          <YouTube
                            videoId={content.youtube_id}
                            opts={{
                              width: '100%',
                              playerVars: {
                                autoplay: 0,
                              },
                            }}
                          />
                        </div>
                      )}
                      {content.type === 'video' && (
                        <div className="render-content__video">
                          <ReactPlayer
                            controls
                            height="inherit"
                            width="100%"
                            url={content.file.path}
                          />
                        </div>
                      )}
                      {content.type === 'file' && (
                        <File file={content.file} addHost={false} maxWidth={400} />
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
        {this.state.need_more ? (
          <div className="read_more-btn" onClick={this.toggleReedMore}>
            {this.state.is_open ? translate('HIDE') : translate('READ_FULLY')}
          </div>
        ) : null}
      </div>
    );
  }
}

export default RenderContent;
