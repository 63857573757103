import React from 'react';
import ReactDOM from 'react-dom';
import { message } from 'antd';
import moment from 'moment';
import 'moment/locale/it';
import 'moment/locale/fr';
import 'moment/locale/ko';
import 'moment/locale/ru';
import 'moment/locale/ro';
import 'moment/locale/cs';
import 'moment/locale/es';
import 'moment/locale/ja';
import 'moment/locale/de';
import 'moment/locale/hr';
import 'moment/locale/hu';
import 'moment/locale/bg';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/sr';
import 'moment/locale/el';
import 'moment/locale/vi';
import 'moment/locale/id';
import 'moment/locale/nl';
import 'moment/locale/fi';
import 'moment/locale/et';
import 'moment/locale/sk';
import 'moment/locale/lv';
import 'moment/locale/lt';
import 'moment/locale/sv';
import 'moment/locale/sl';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/ar';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { configure } from 'mobx';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import './assets/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

configure({
  enforceActions: 'never',
  useProxies: 'never',
});

moment.locale('en');

message.config({
  duration: 2,
  maxCount: 1,
});

const ELEMENTS_OPTIONS = {
  fonts: [
    {
      family: 'Roboto',
      src: 'url(/fonts/Roboto-Refular.ttf)',
      weight: 400,
    },
  ],
  locale: 'en',
};

moment.defineLocale('pb', {
  parentLocale: 'pt',
  /* */
});
moment.defineLocale('no', {
  parentLocale: 'nb',
  /* */
});

moment.defineLocale('gr', {
  parentLocale: 'el',
  /* */
});

ReactDOM.render(
  <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
    <App />
  </Elements>,
  document.getElementById('root'),
);
serviceWorker.unregister();
