import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import colorsVars from '@utils/colorsVars';

import RenderSvg from '../RenderSvg';
import closeIcon from '../../assets/icons/close1.svg';

// TODO: add scroll lock
function Modal({ close, children, contentClass }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  return (
    <div className="custom-modal">
      <div className="custom-modal__overlay" onClick={close} />
      <div className={`custom-modal__content ${contentClass}`}>
        <button className="custom-modal__close" onClick={close}>
          <RenderSvg path={closeIcon} size={28} color={colorsVars.colorAccent} />
        </button>

        {children}
      </div>
    </div>
  );
}

Modal.defaultProps = {
  contentClass: '',
};

Modal.propTypes = {
  close: PropTypes.func.isRequired,
  contentClass: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Modal;
