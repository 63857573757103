import { observable, action, makeObservable } from 'mobx';
import { getTariffAction } from '@actions/tariffs';
import { tariffAdapter } from '@utils/adapters/tariffAdapter';
import AppStore from '@store/AppStore';
import QuizStore from '@store/QuizStore';
import { quizCheckoutPayment } from '@actions/quiz';
import { checkoutPagePayment } from '@actions/checkout';
import { recurlyPaymentCheckoutAction, recurlyPaymentQuizAction } from '@actions/payment';
import RoutesLang from '@utils/RoutesLang';
import { toast, translate } from '@/utils';

class PaymentStore {
  @observable currentTariff = {};

  @observable changePlansData = {
    id: '1.trial.2months.89',
    price: '89.99',
    paypal_price_id: 'P-3PP28756DJ339231JMEW5OXI',
    recurly_price_id: 'test-plan-dev',
    price_interval_value: 2,
    price_interval_type: 'month',
    trial_days: 7,
    trial_price: '0.99',
    currency_iso_code: 'USD',
    currency_sign: '$',
    country_iso_codes: ['RU'],
    changeTariffs: ['changePlan1', 'changePlan4', 'changePlan9', 'changePlan14'],
  };

  @observable withPresent =
    localStorage.getItem('present_mode') === 'blue' ||
    localStorage.getItem('present_mode') === 'orange';

  @observable changePlans = {
    changePlan1: {
      ...this.changePlansData,
    },
    changePlan4: {
      ...this.changePlansData,
      id: 'trial.4.2month',
      trial_price: '4',
      paypal_price_id: 'P-7PB24361H4776945UMGQKAIY',
    },
    changePlan9: {
      ...this.changePlansData,
      id: 'trial.9.2month',
      trial_price: '9',
      paypal_price_id: 'P-16L74300X8367981SMGQJ7PA',
    },
    changePlan14: {
      ...this.changePlansData,
      id: 'trial.14.2month',
      trial_price: '14.25',
      paypal_price_id: 'P-8RK98557MK408681TMGQKAWI',
      bestOffer: true,
    },
  };

  @action checkForPresent = () => {
    this.withPresent =
      localStorage.getItem('present_mode') === 'blue' ||
      localStorage.getItem('present_mode') === 'orange';
  };

  @action getTariff = async () => {
    const tag = localStorage.getItem('paymentTariff');
    const countryByIp = localStorage.getItem('countryByIp');

    if (this.changePlans[tag]) {
      this.currentTariff = tariffAdapter(this.changePlans[tag]);
      return this.currentTariff;
    }
    try {
      return await getTariffAction(tag, countryByIp).then(({ data }) => {
        this.currentTariff = tariffAdapter(data);
      });
    } catch (e) {
      throw e;
    }
  };

  @action changePaymentTariff = (tariff) => {
    this.currentTariff = tariffAdapter(this.changePlans[tariff]);
    localStorage.setItem('paymentTariff', tariff);
  };

  @action quizCheckoutPayment = async (paymentId, email) => {
    !this.withPresent && this.checkForPresent();
    try {
      const { token, stripe, course_id, subscription_id } = await quizCheckoutPayment(
        QuizStore.state.uniqueIds.code,
        {
          is_agree_research_policy: true,
          site: window.location.href.replace(window.location.search, ''),
          tariff_id: this.currentTariff.stripeId,
          locale: AppStore.ln,
          stripe_payment_method_id: paymentId,
          with_mobile_promo_code: this.withPresent,
          ...(email ? { email } : {}),
        },
      );
      const response = {};

      const { subscription } = stripe;
      const paymentIntent = subscription?.latest_invoice?.payment_intent;

      response.status = paymentIntent?.status;
      response.client_secret = paymentIntent?.client_secret;

      const price = (subscription?.plan?.amount || 0) / 100;
      const currency = subscription?.plan?.currency?.toUpperCase();

      response.link = `${RoutesLang.verify}?token=${token}&price=${price}&subscriptionId=${subscription_id}&courseId=${course_id}&currency=${currency}`;

      return response;
    } catch (e) {
      if (e.code === 422) {
        toast(translate('APP_EMAIL_REGISTERED'));
      }
      throw e;
    }
  };

  @action checkoutPagePayment = async (paymentId, email, phone) => {
    !this.withPresent && this.checkForPresent();
    const esubParam = localStorage.getItem('esub');
    try {
      const { token, stripe, course_id, subscription_id } = await checkoutPagePayment({
        email,
        phone,
        bundle_id: process.env.REACT_APP_BUNDLE_ID,
        site: window.location.href.replace(window.location.search, ''),
        tariff_id: this.currentTariff.stripeId,
        stripe_payment_method_id: paymentId,
        is_agree_research_policy: true,
        locale: AppStore.ln,
        with_mobile_promo_code: this.withPresent,
        ...(esubParam ? { esub: esubParam } : {}),
      });
      const response = {};

      const { subscription } = stripe;
      const paymentIntent = subscription?.latest_invoice?.payment_intent;

      response.status = paymentIntent?.status;
      response.client_secret = paymentIntent?.client_secret;

      const price = (subscription?.plan?.amount || 0) / 100;
      const currency = subscription?.plan?.currency?.toUpperCase();

      response.link = `${RoutesLang.verify}?token=${token}&price=${price}&subscriptionId=${subscription_id}&courseId=${course_id}&currency=${currency}`;

      return response;
    } catch (e) {
      if (e.code === 422) {
        toast(translate('APP_EMAIL_REGISTERED'));
      }
      throw e;
    }
  };

  @action recurlyPaymentAction = async (type, data) => {
    !this.withPresent && this.checkForPresent();
    const response = {};
    const price = this.currentTariff.priceAfterTrial;
    const currency = this.currentTariff.currencyCode;
    const esubParam = localStorage.getItem('esub');
    let subscriptionResponse;
    let recurlyData = {
      is_agree_research_policy: true,
      locale: AppStore.ln,
      plane_code: this.currentTariff.recurlyId,
      currency: this.currentTariff.currencyCode,
      billing_info_token_id: data.billingToken,
      with_mobile_promo_code: this.withPresent,
      ...(data.email ? { email: data.email } : {}),
    };

    if (type === 'checkout') {
      recurlyData = {
        ...recurlyData,
        phone: data.phone,
        site: window.location.href.replace(window.location.search, ''),
        bundle_id: process.env.REACT_APP_BUNDLE_ID,
        ...(esubParam ? { esub: esubParam } : {}),
      };
    }

    if (type === 'secure') {
      recurlyData = this.recurlyThreeDSecureData;
      recurlyData.three_ds_token.id = data.secureTokenId;
    }

    try {
      if (type === 'checkout' || this.recurlyThreeDSecureActionType === 'checkout') {
        subscriptionResponse = await recurlyPaymentCheckoutAction(recurlyData);
      } else {
        subscriptionResponse = await recurlyPaymentQuizAction(
          QuizStore.state.uniqueIds.code,
          recurlyData,
        );
      }
      response.link = `${RoutesLang.verify}?token=${subscriptionResponse.token}&price=${price}&subscriptionId=${subscriptionResponse.subscription_id}&courseId=${subscriptionResponse.course_id}&currency=${currency}`;
    } catch (err) {
      if (err.code === 424) {
        // 3ds secure error
        recurlyData.three_ds_token = {
          account_code: err.account_code,
        };

        this.recurlyThreeDSecureData = recurlyData;
        if (this.recurlyThreeDSecureActionType === 'checkout') {
          recurlyData.open_test_code = err.open_test_code;
        }
        this.recurlyThreeDSecureActionType = type;
      }
      if (err.code === 422) {
        toast(translate('APP_EMAIL_REGISTERED'));
      }
      throw err;
    }

    return response;
  };

  constructor() {
    makeObservable(this);
  }
}

export default new PaymentStore();
