import React from 'react';
import { withRouter } from 'react-router-dom';
import Layout from '../components/Layout';
import { translate } from '@/utils';

const NotFound = ({ history, header, user_is_authorized, footer }) => {
  return (
    <Layout user_is_authorized={user_is_authorized} header={header} footer={footer}>
      <div className="page-404">
        <div className="page-404__inner">
          <div className="page-404__error">404</div>
          <div className="page-404__error-shadow">404</div>
        </div>
        <div className="page-404__title">{translate('PAGE_NOT_FOUND')}</div>
        <button type="button" className="page-404__btn" onClick={() => history.push('/')}>
          {translate('BACK_TO_HOME')}
        </button>
      </div>
    </Layout>
  );
};

export default withRouter(NotFound);
