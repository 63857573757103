import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/store';
import moment from 'moment';
import { addHostToPath, translate } from '@/utils';
import { gaMirror, ymMirror } from '@utils/metrics';
import ScrollArrow from '../ScrollArrow';
import ResultVideo from '@components/Checkout/ResultVideo';
import CheckoutReviewBeforeAfter from '@components/Checkout/CheckoutReviewBeforeAfter';
import CheckoutReviewWithComment from '@components/Checkout/CheckoutReviewWithComment';
import Timer from '@components/Timer';
import RenderSvg from '@components/RenderSvg';

import guaranteed from '@images/guaranteed.png';
import guaranteedES from '@images/guaranteedES.png';
import guaranteedCS from '@images/guaranteedCS.png';
import guaranteedRO from '@images/guaranteedRO.png';
import guaranteedRU from '@images/guaranteedRU.png';
import guaranteedFR from '@images/guaranteedFR.png';
import guaranteedKO from '@images/guaranteedKO.png';
import guaranteedIT from '@images/guaranteedIT.png';
import guaranteedJA from '@images/guaranteedJA.png';
import guaranteedBG from '@images/guaranteedBG.png';
import guaranteedPB from '@images/guaranteedBR.png';
import guaranteedDE from '@images/guaranteedDE.png';
import guaranteedGR from '@images/guaranteedGR.png';
import guaranteedHR from '@images/guaranteedHR.png';
import guaranteedPL from '@images/guaranteedPL.png';
import guaranteedHU from '@images/guaranteedHU.png';
import guaranteedPT from '@images/guaranteedPT.png';
import guaranteedSR from '@images/guaranteedSR.png';
import guaranteedID from '@images/guaranteedID.png';
import guaranteedVI from '@images/guaranteedVI.png';
import guaranteedNL from '@images/guaranteedNL.png';
import guaranteedFI from '@images/guaranteedFI.png';
import guaranteedET from '@images/guaranteedET.png';
import guaranteedSK from '@images/guaranteedSK.png';
import guaranteedLV from '@images/guaranteedLV.png';
import guaranteedLT from '@images/guaranteedLT.png';
import guaranteedSV from '@images/guaranteedSV.png';
import guaranteedSL from '@images/guaranteedSL.png';
import guaranteedDA from '@images/guaranteedDA.png';
import guaranteedNO from '@images/guaranteedNO.png';

import iconSuccess from '@icons/icon-success.svg';
import iconLock from '@icons/icon-lock.svg';
import CheckoutPayment from '@components/Checkout/CheckoutPayment';
import PlanSection from '@components/Checkout/PlanSection';
import { priceAdapter } from '@utils/adapters/priceAdapter';
import Spinner from '@components/Spinner';
import colorsVars from '@utils/colorsVars';
import RecurlyPayment from '@components/Checkout/Recurly/RecurlyPayment';
import GiftSection from '@components/Checkout/GiftSection';
import logoIcon from '@icons/logo_icon.png';
import logoText from '@icons/logo_name.svg';

export const Checkout = observer(({ isCheckoutPage }) => {
  const [sendGaVisit, setSendGaVisit] = useState(true);
  const [checkoutLoading, setCheckoutLoading] = useState(true);
  const [showAllTariffInformation, setShowAllTariffInformation] = useState(true);
  const { state } = useStores().quizStore;
  const { currentTariff, getTariff } = useStores().paymentStore;
  const appStore = useStores().appStore;
  const presentMode = localStorage.getItem('present_mode');

  moment.locale(appStore.ln);
  const paymentType = localStorage.getItem('paymentType');

  useEffect(() => {
    if (!currentTariff.id) {
      initTariff();
    } else {
      setCheckoutLoading(false);
    }
  }, []);

  const initTariff = async () => {
    try {
      setCheckoutLoading(true);
      await getTariff();
      setCheckoutLoading(false);
    } catch (e) {
      throw e;
    }
  };
  const percentOff = () => {
    const percent = (
      ((currentTariff.oldPriceAfterTrial - currentTariff.priceAfterTrial) * 100) /
      currentTariff.oldPriceAfterTrial
    ).toFixed(0);
    return appStore.ln === 'ar' ? `%${percent}` : `${percent}%`;
  };

  const countryWithVideo = ['US', 'CA', 'GB'];
  const videoAvailable =
    !isCheckoutPage && countryWithVideo.includes(localStorage.getItem('countryByIp'));

  const gaEventCategory = isCheckoutPage ? 'checkout' : `quiz_checkout_${state.quizId}`;

  useEffect(() => {
    const wasCheckoutEvent = window.localStorage.getItem('checkoutEvent');
    const userWithUtm =
      localStorage.getItem('utm_source') ||
      localStorage.getItem('utm_medium') ||
      localStorage.getItem('utm_campaign') ||
      localStorage.getItem('utm_term') ||
      localStorage.getItem('utm_content');

    setShowAllTariffInformation(!userWithUtm);

    if (!wasCheckoutEvent) {
      ymMirror('checkoutView');
      window.localStorage.setItem('checkoutEvent', true);
    }
  }, []);

  useEffect(() => {
    document.body.classList.add('hide-lang');
    if (sendGaVisit) {
      setSendGaVisit(false);
      gaMirror({
        event: 'view_quiz_checkout',
        category: gaEventCategory,
        action: 'viewContent',
        label: 'view_quiz_checkout',
      });
    }

    return () => {
      document.body.classList.remove('hide-lang');
    };
  });

  const guaranteedImage = {
    default: guaranteed,
    ko: guaranteedKO,
    ru: guaranteedRU,
    it: guaranteedIT,
    fr: guaranteedFR,
    cs: guaranteedCS,
    ro: guaranteedRO,
    es: guaranteedES,
    la: guaranteedES,
    ja: guaranteedJA,
    bg: guaranteedBG,
    pb: guaranteedPB,
    de: guaranteedDE,
    gr: guaranteedGR,
    hr: guaranteedHR,
    pl: guaranteedPL,
    hu: guaranteedHU,
    pt: guaranteedPT,
    sr: guaranteedSR,
    id: guaranteedID,
    vi: guaranteedVI,
    nl: guaranteedNL,
    fi: guaranteedFI,
    et: guaranteedET,
    sk: guaranteedSK,
    lv: guaranteedLV,
    lt: guaranteedLT,
    sv: guaranteedSV,
    sl: guaranteedSL,
    da: guaranteedDA,
    no: guaranteedNO,
  };
  const currentGuaranteedImage = guaranteedImage[appStore.ln] || guaranteedImage.default;

  const subPeriodGeo = (period) => {
    if (appStore.ln === 'vi') {
      if (parseFloat(period) === 1) {
        return period.substr(1);
      }
      return period;
    }
    return period;
  };
  const percentOffGeo = () => {
    if (appStore.ln === 'vi' || appStore.ln === 'id') {
      return `${translate('PERSENT_OFF')} ${percentOff()}`;
    }
    return `${percentOff()} ${translate('PERSENT_OFF')}`;
  };
  return (
    <div className="checkout">
      {!checkoutLoading ? (
        <>
          <ScrollArrow />
          <div className="checkout__countdown">
            <div className="checkout__countdown-limit">
              <div className="checkout__title checkout__countdown-title">
                {translate('PLAN_RESERVED')}
              </div>
              <div className="checkout__title">{translate('TIME_REMAINING')}</div>
              <Timer initialMinutes={15} />
            </div>
          </div>
          {videoAvailable && <ResultVideo />}
          <CheckoutReviewBeforeAfter />
          <PlanSection hideButton isCheckoutPage={isCheckoutPage} />
          <CheckoutReviewWithComment />
          {(presentMode === 'blue' || presentMode === 'orange') && (
            <GiftSection presentMode={presentMode} />
          )}
          <div className="full-logo">
            <img
              className="full-logo__icon"
              src={addHostToPath(appStore.app_info?.logo) || logoIcon}
              alt=""
            />
            {!appStore.app_info?.logo ||
              (appStore.app_info?.project_name.toLowerCase() === 'vean' && (
                <RenderSvg
                  path={logoText}
                  widthSize={72}
                  heightSize={18}
                  className="full-logo__text"
                />
              ))}
          </div>

          <div
            className={`checkout__price  ${
              showAllTariffInformation ? 'checkout__price_first' : 'checkout__price_br-all'
            }`}
          >
            <div className="checkout__sub-title checkout__sub-title_price">
              <span>{translate('PRICE_TODAY')}</span>{' '}
              <span className="checkout__sub-title_location">{window.location.hostname}</span>
            </div>
            <div className="checkout__price-line">
              <span className="checkout__price-name">{currentTariff.trialText}</span>
              <span className="checkout__price-count checkout__price-count_free">
                {currentTariff.trialPrice === 0 ? (
                  translate('FREE')
                ) : (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: priceAdapter(
                          currentTariff.currency,
                          currentTariff.trialPrice,
                          currentTariff.currencyCode,
                        ),
                      }}
                    />
                    {currentTariff.oldTrialPrice && (
                      <span className="checkout__price-count checkout__price-count_old-trial">
                        {translate('OLD_PRICE')}{' '}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: priceAdapter(
                              currentTariff.currency,
                              currentTariff.oldTrialPrice,
                              currentTariff.currencyCode,
                            ),
                          }}
                        />
                      </span>
                    )}
                  </>
                )}
              </span>
            </div>
          </div>

          {showAllTariffInformation && (
            <div className="checkout__price">
              <div className="checkout__sub-title checkout__sub-title_price">
                {translate('PRICE_AFTER_TRIAL')}
              </div>
              <div className="checkout__price-line">
                <span className="checkout__price-name checkout__price-name_min">
                  {currentTariff.planText}
                </span>
                <span className="checkout__price-count">
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: priceAdapter(
                          currentTariff.currency,
                          currentTariff.priceAfterTrial,
                          currentTariff.currencyCode,
                        ),
                      }}
                    />
                    /{subPeriodGeo(currentTariff.subPeriod)}
                  </span>
                </span>
              </div>
              <div className="checkout__price-line">
                <span className="checkout__price-discount">{percentOffGeo()}</span>
                <span className="checkout__price_old-price">
                  {translate('OLD_PRICE')}{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: priceAdapter(
                        currentTariff.currency,
                        currentTariff.oldPriceAfterTrial,
                        currentTariff.currencyCode,
                      ),
                    }}
                  />
                </span>
              </div>
            </div>
          )}

          {paymentType === 'recurly' && currentTariff.recurlyId ? (
            <RecurlyPayment currentTariff={currentTariff} isCheckoutPage={isCheckoutPage} />
          ) : (
            <CheckoutPayment
              currentTariff={currentTariff}
              gaEventCategory={gaEventCategory}
              isCheckoutPage={isCheckoutPage}
            />
          )}

          <div className="checkout__guaranteed">
            <div className="checkout__guaranteed-item">
              <div
                className="checkout__guaranteed-info"
                dangerouslySetInnerHTML={{
                  __html: currentTariff.noteText
                    .replace(
                      /{{price}}/g,
                      priceAdapter(
                        currentTariff.currency,
                        currentTariff.priceAfterTrial,
                        currentTariff.currencyCode,
                      ),
                    )

                    .replace(/{{dateDone}}/g, currentTariff.firstPayDate)
                    .replace(
                      /{{weekPrice}}/g,
                      priceAdapter(
                        currentTariff.currency,
                        currentTariff.weekPrice,
                        currentTariff.currencyCode,
                      ),
                    )
                    .replace('{{trial_period}}', currentTariff.trialText)
                    .replace(/{{months_count}}/g, currentTariff.monthsCount || '')
                    .replace(/{{project_name}}/g, appStore.app_info?.project_name || 'Vean'),
                }}
              />

              <div className="checkout__guaranteed-img">
                <img src={currentGuaranteedImage} alt="" />
              </div>
            </div>
            <div className="checkout__guaranteed-line">
              <div className="checkout__guaranteed-plus">
                <RenderSvg size={27} path={iconSuccess} />
                <span>{translate('NO_COMMITMENT')}</span>
              </div>
              <div className="checkout__guaranteed-plus">
                <RenderSvg size={27} path={iconLock} /> <span>{translate('PROTECTED_DATA')}</span>
              </div>
            </div>
          </div>
          <div className="checkout__note">
            <div className="checkout__note-text">
              <b>{translate('WORLDWIDE')}</b>:{' '}
              {appStore.appContacts[0]?.value || translate('ADDRESS1')} <br />
            </div>
          </div>
        </>
      ) : (
        <div>
          <Spinner color={colorsVars.colorBright} />
        </div>
      )}
    </div>
  );
});
