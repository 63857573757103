import React from 'react';
import { Link } from 'react-router-dom';
import checkbox from '../../assets/icons/checkbox.svg';
import RenderSvg from '../RenderSvg';
import { translate } from '@/utils';
import { LangDynamicLink } from '@utils/LinkLangRoutes';

export const CookieModal = ({ onClick }) => (
  <div className="cookie-modal__container">
    <div>
      <p className="cookie-modal__title">{translate('WEBSITE_USES_COOKIES')}</p>
      <p className="cookie-modal__text">{translate('WE_USE_COOKIES')}</p>
    </div>
    <div className="cookie-modal__btns-box">
      <button
        className="orange-button button-with-left-icon cookie-modal__good-btn"
        onClick={onClick}
      >
        <RenderSvg path={checkbox} />
        {translate('SOUND_GOOD')}
      </button>
      <Link
        to={LangDynamicLink('privacy')}
        className="grey-button cookie-modal__more-btn"
        onClick={onClick}
      >
        {translate('TELL_ME_MORE')}
      </Link>
    </div>
  </div>
);
