import React from 'react';
import { translate } from '@/utils';
import { useStores } from '@/store';

const TariffSection = () => {
  const { app_info } = useStores().appStore;
  return (
    <div className="tariff-section">
      <div className="tariff-section__container">
        <h2
          className="tariff-section__label"
          dangerouslySetInnerHTML={{ __html: translate('TARIFF_SECTION_LABEL') }}
        />

        <div
          className="tariff-section__text-container"
          dangerouslySetInnerHTML={{
            __html: translate('TARIFF_SECTION_CONTENT')
              .replace(/{{project_name}}/g, app_info.project_name || 'VEAN')
              .replace(/{{company_name}}/g, app_info.company_name || 'Subster inc')
              .replace(/{{support_email}}/g, app_info.support_email || 'support@vean.io'),
          }}
        />
      </div>
    </div>
  );
};

export default TariffSection;
