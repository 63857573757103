import PropTypes from 'prop-types';

export default {
  setLoading: PropTypes.func.isRequired,
  contentKeys: PropTypes.shape({
    login: PropTypes.string,
    restore: PropTypes.string,
    success: PropTypes.string,
  }).isRequired,
  setContentName: PropTypes.func.isRequired,
};
