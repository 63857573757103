import { PayPalButton } from 'react-paypal-button-v2';
import React from 'react';
import { gaMirror, ymMirror } from '@utils/metrics';
import { paypalPayment, withoutQuizPaypalPayment } from '@actions/checkout';
import useRouter from '@/hooks/useRouter';
import RoutesLang from '@utils/RoutesLang';

const PayPalBtn = ({
  quizCode,
  locale,
  readyForPayPal,
  email,
  phone,
  setEmailError,
  setEmailRegisteredError,
  tariff_id,
  priceAfterTrial,
  tariffCurrencyText,
  allFields,
  gaEventCategory,
}) => {
  const router = useRouter();
  const withPresent = Boolean(
    localStorage.getItem('present_mode') === 'blue' ||
      localStorage.getItem('present_mode') === 'orange',
  );
  const DEV_ENV = process.env.REACT_APP_PAYPAL_DEV_PLAN;
  const quizHasEmailQuestion = localStorage.getItem('quizEmail');

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: DEV_ENV || tariff_id,
    });
  };

  const paypalOnError = () => {
    ymMirror('failedPaymentPayPal');
    gaMirror({
      event: 'failed_payment',
      category: gaEventCategory,
      action: 'payment',
      label: 'failed_payment',
    });
  };

  const paypalOnApprove = async (data) => {
    localStorage.setItem('paidByPayPal', 'true');
    const esubParam = localStorage.getItem('esub');
    const urlWitoutParams = window.location.href.replace(window.location.search, '');
    try {
      let response;
      if (allFields) {
        const requestDataWithoutQuiz = {
          email,
          phone,
          bundle_id: process.env.REACT_APP_BUNDLE_ID,
          tariff_id: DEV_ENV || tariff_id,
          site: urlWitoutParams,
          is_agree_research_policy: true,
          locale,
          ps_subscription_id: data.subscriptionID,
          with_mobile_promo_code: withPresent,
          ...(esubParam ? { esub: esubParam } : {}),
        };
        response = await withoutQuizPaypalPayment(requestDataWithoutQuiz);
      } else {
        const requestData = {
          is_agree_research_policy: true,
          locale,
          site: urlWitoutParams,
          ps_subscription_id: data.subscriptionID,
          with_mobile_promo_code: withPresent,
          ...(!quizHasEmailQuestion ? { email } : {}),
        };
        response = await paypalPayment(quizCode, requestData);
      }

      const link = `${RoutesLang.verify}?token=${response.token}&price=${priceAfterTrial}&subscriptionId=${response.subscription_id}&courseId=${response.course_id}&currency=${tariffCurrencyText}`;
      gaMirror({
        event: 'start_trial',
        category: gaEventCategory,
        action: 'click',
        label: 'start_trial',
      });
      ymMirror('successPaymentPayPal');
      router.push(link);
    } catch (e) {
      if (e.code === 422) {
        setEmailError();
        setEmailRegisteredError(true);
      }
      throw e;
    }
  };

  const handleEmailError = (e) => {
    e.preventDefault();
    setEmailError();
  };

  return (
    <div className="pay-pal">
      {!readyForPayPal && (
        <span
          onClick={(e) => {
            handleEmailError(e);
          }}
          className="pay-pal__blocker"
        />
      )}
      <PayPalButton
        createSubscription={(data, details) => paypalSubscribe(data, details)}
        onApprove={(data, details) => paypalOnApprove(data, details)}
        onError={paypalOnError}
        catchError={paypalOnError}
        options={{
          clientId: process.env.REACT_APP_PAYPAL_KEY,
          vault: true,
          disableFunding: 'credit',
        }}
        style={{
          layout: 'horizontal',
          tagline: false,
          color: 'silver',
          height: 40,
        }}
      />
    </div>
  );
};

export default PayPalBtn;
