import React from 'react';
import colorsVars from '@utils/colorsVars';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useStores } from '@/store';

const WeightChart = ({ weight, goal, idealWeight, courseDuration, weightUnit }) => {
  const { ln } = useStores().appStore;
  const isDirectionRTL = ln === 'ar';
  const userWantLoseWeight = weight > idealWeight;
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const categories = moment.monthsShort('-MMM-');

  const calculateChartData = () => {
    let remainingWeight = weight;
    const chartData = [];

    for (let i = 0; i <= courseDuration; i++) {
      switch (i) {
        case courseDuration: {
          remainingWeight = weight - goal;
          break;
        }
        case 0: {
          break;
        }
        case 1: {
          remainingWeight -= goal * 0.5;
          break;
        }
        case 2: {
          remainingWeight -= goal * 0.2;
          break;
        }
        case 3: {
          remainingWeight -= goal * 0.1;
          break;
        }
        default: {
          remainingWeight -= (goal * 0.2) / (courseDuration - 3);
        }
      }
      if ((currentMonth + i) % 11 === 0) {
        categories.push(...categories);
      }
      chartData.push([currentMonth + i, Number(remainingWeight.toFixed(0))]);
    }

    return chartData;
  };

  const chartOptions = {
    global: {
      useUTC: false,
    },
    chart: {
      type: 'areaspline',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: 0,
      height: 240,
    },
    title: false,
    subtitle: false,
    colors: [
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1,
        },
        stops: [
          [0, '#F34E4E'],
          [1, '#F8C476'],
        ],
      },
    ],
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      reversed: !!isDirectionRTL,
      categories,
      gridLineWidth: 1,
      gridZIndex: 10,
      lineWidth: 0,
      gridLineColor: 'rgba(206, 212, 228, 0.4)',
      zIndex: 1,
      min: currentMonth + 0.5,
      max: currentMonth + courseDuration - 0.5,
      alignTicks: false,
      tickmarkPlacement: 'on',
    },
    yAxis: {
      gridLineWidth: 0,
      lineWidth: 0,
      labels: {
        enabled: false,
      },
      title: {
        enabled: false,
      },
      max: userWantLoseWeight ? weight + 5 : weight + 5 - goal,
      min: userWantLoseWeight ? weight - 5 - goal : weight - 5,
      alignTicks: false,
    },
    series: [
      {
        data: calculateChartData(),
        marker: {
          lineWidth: 2,
          fillColor: colorsVars.colorMinor,
        },
      },
    ],
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: [
          {
            enabled: true,
            format: `{y} ${weightUnit}`,
            color: '#8991AE',
            className: 'graph-plot',
            padding: 10,
            states: {
              hover: {
                radiusPlus: 1,
              },
            },
          },
        ],
      },
    },
  };

  return (
    <div className="weight-chart">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default observer(WeightChart);
