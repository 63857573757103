import React from 'react';
import { withRouter } from 'react-router-dom';
import ErrorIndicator from '../ErrorIndicator';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, info) {
    this.setState({ hasError: error });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorIndicator error={this.state.error} />;
    }
    return this.props.children;
  }
}
export default withRouter(ErrorBoundary);
